/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import { Button, Icon } from 'antd';
import _ from 'lodash';
import { Button, Skeleton } from 'antd';
import JobIntel from '../../Components/JobIntel/JobIntel';
import BlsDataDisplay from '../../Components/JobIntel/BlsDataDisplay';
import MarketIntelligence from '../../Components/JobIntel/MarketIntelligence';
import * as JobActions from '../../Actions/JobActions';
import * as manualSearchActions from '../../Actions/ManualSearchActions';
import { getJobIntel, getJobIntelLocationData, getJobsById } from '../../Reducers/JobReducer';
import { getWhiteLabelInformation } from '../../Reducers/ConfigReducer';
import { fetchMarketIntelligence as _fetchMarketIntelligence } from '../../Actions/SalesDashBoardActions';
import { getGlobalInsightSupplyList } from '../../Reducers/SalesDashBoardReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import { getIsDefaultCriteriaFetchedFlag, getManualSearchCriteria } from '../../Reducers/ManualSearchReducer';
import { generatePdf } from '../../Utils/PdfGeneratorUtils';
import './JobIntel.scss';
import styles from '../../Components/GlobalInsight/InsightList/Insight.module.scss';
import jobMessage from '../../Components/JobForm/JobMessages';

export function compare(a, b) {
  if (a.CandidatesCount > b.CandidatesCount) {
    return -1;
  }
  if (a.CandidatesCount < b.CandidatesCount) {
    return 1;
  }
  return 0;
}

const mapStateToProps = (state, props) => {
  const { jobId } = props.match.params;
  return {
    jobsById: getJobsById(state),
    jobIntel: getJobIntel(state),
    jobIntelLocationData: getJobIntelLocationData(state),
    whiteLabelInfo: getWhiteLabelInformation(state),
    marketIntel: getGlobalInsightSupplyList(state),
    globalSupplyApiStatus: getApiStatus(state, 'globalSupplyApiStatus'),
    featureToggleList: getFeatureToggleList(state),
    unsavedCriteria: getManualSearchCriteria(state, { jobId, criteriaType: 'Unsaved' }),
    aryaGeneratedCriteria: getManualSearchCriteria(state, { jobId, criteriaType: 'AryaGenerated' }),
    fetchManualSearchDraftCriteriaApiStatus: getApiStatus(state, 'fetchManualSearchDraftCriteriaApiStatus'),
    fetchAryaGeneratedCriteriaApiStatus: getApiStatus(state, 'fetchAryaGeneratedCriteriaApiStatus'),
    isDefaultCriteriaFetchedFlag: getIsDefaultCriteriaFetchedFlag(state, { jobId }),
  };
};

const mapDispatchToProps = {
  fetchJobIntel: JobActions.fetchJobIntel,
  fetchJobIntelLocationData: JobActions.fetchJobIntelLocationData,
  fetchGlobalInsightSupplyList: _fetchMarketIntelligence,
  fetchManualSearchDraftCriteria: manualSearchActions.fetchManualSearchDraftCriteria,
  fetchAryaGeneratedIntel: manualSearchActions.fetchAryaGeneratedIntel,
  setIsDefaultCriteriaFetchedFlag: manualSearchActions.setIsDefaultCriteriaFetchedFlag,
};

class JobIntelContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  componentDidMount() {
    const {
      fetchJobIntel,
      fetchJobIntelLocationData,
      match,
      fetchManualSearchDraftCriteria,
      fetchAryaGeneratedIntel,
      isDefaultCriteriaFetchedFlag,
      setIsDefaultCriteriaFetchedFlag,
    } = this.props;
    const jobId = match.params.jobId ? parseInt(match.params.jobId, 10) : null;
    if (jobId) {
      fetchJobIntel(jobId);
      fetchJobIntelLocationData(jobId);
      if (!isDefaultCriteriaFetchedFlag) {
        fetchManualSearchDraftCriteria({ jobId });
        fetchAryaGeneratedIntel({ jobId, candidateContext: 'job' });
        setIsDefaultCriteriaFetchedFlag({ jobId, isDefaultCriteriaFetchedFlag: true });
      }
    }
  }

  filterData(intelData) {
    if (intelData === undefined) {
      return undefined;
    }

    const mustHaves = _.get(intelData, ['SkillSet', 'MustHaves'], []).map(mustHave => {
      return {
        name: mustHave.Name,
        size: 5,
      };
    });
    const relevant = _.get(intelData, ['SkillSet', 'Relevants'], []).map(relevantSkill => {
      return {
        name: relevantSkill.Name,
        size: 5,
      };
    });
    const niceToHaves = _.get(intelData, ['SkillSet', 'NiceToHaves'], []).map(niceToHave => {
      return {
        name: niceToHave.Name,
        size: 5,
      };
    });
    const keywordSetData = {
      mustHaves,
      relevant,
      niceToHaves,
    };
    const sortedCompanies = intelData.Companies ? intelData.Companies.sort(compare) : [];
    const companyNames = sortedCompanies.map(company => company.Name);
    const companyValues = sortedCompanies.map(company => company.CandidatesCount);

    const sortedIndustries = intelData.Industries ? intelData.Industries.sort(compare) : [];
    const industryNames = sortedIndustries.map(Industry => Industry.Name);
    const industryValues = sortedIndustries.map(Industry => Industry.CandidatesCount);

    const jobFunctionsData = {
      Name: 'Job Functions',
      children: intelData.JobFunctions
        ? intelData.JobFunctions.map(jobFunction => {
            return {
              ...jobFunction,
              children: jobFunction.ChildTitles,
            };
          })
        : undefined,
    };

    return {
      companies: {
        companyNames,
        companyValues,
      },
      industries: {
        industryNames,
        industryValues,
      },
      keywordSetData,
      jobFunctionsData,
    };
  }

  filterLocationData(jobIntelLocationData = []) {
    const jobLocationData = {};
    jobIntelLocationData.forEach(locationData => {
      jobLocationData[locationData.StateName] = locationData;
    });
    return jobLocationData;
  }

  getIsMarketIntelDataAvailable = ({ currentJobDetails }) => {
    const { fetchManualSearchDraftCriteriaApiStatus, fetchAryaGeneratedCriteriaApiStatus } = this.props;
    const x =
      currentJobDetails &&
      fetchManualSearchDraftCriteriaApiStatus &&
      fetchManualSearchDraftCriteriaApiStatus !== 'INPROGRESS' &&
      fetchAryaGeneratedCriteriaApiStatus &&
      fetchAryaGeneratedCriteriaApiStatus !== 'INPROGRESS';
    console.error({ markettest: x });
    return x;
  };

  getMarketIntel = () => {
    const {
      jobsById,
      match,
      marketIntel,
      globalSupplyApiStatus,
      fetchGlobalInsightSupplyList,
      unsavedCriteria,
      aryaGeneratedCriteria,
    } = this.props;
    const { jobId } = match.params;
    const currentJobDetails = jobsById?.[jobId] ?? {};
    return this.getIsMarketIntelDataAvailable({ currentJobDetails }) ? (
      <MarketIntelligence
        marketIntel={marketIntel}
        loading={globalSupplyApiStatus === 'INPROGRESS'}
        currentJobDetails={currentJobDetails}
        unsavedCriteria={unsavedCriteria}
        aryaGeneratedCriteria={aryaGeneratedCriteria}
        fetchGlobalInsightSupplyList={fetchGlobalInsightSupplyList}
      />
    ) : (
      <Skeleton loading paragraph={{ rows: 6 }} />
    );
  };

  getSubTitleOfPdf = currentJobDetails => {
    const { Client: client, Location: location, Country: country, Zipcode: zipcode } = currentJobDetails;
    let subTitle = '';
    if (client) subTitle += `${client}, `;
    if (location) subTitle += `${location}, `;
    if (zipcode) subTitle += `${zipcode}, `;
    if (country) subTitle += `${country}`;
    return subTitle;
  };

  handleMarketIntelDownloadFail = loading => {
    this.setState({ isLoading: loading });
  };

  initiatePdfGeneration = async (currentJobDetails, insights, exclusionClassNames) => {
    await generatePdf(
      currentJobDetails.JobTitle,
      this.getSubTitleOfPdf(currentJobDetails),
      insights,
      this.handleMarketIntelDownloadFail,
      exclusionClassNames
    );
    this.setState({ isLoading: false });
  };

  handleMarketIntelDownloadClick = (currentJobDetails, insights, exclusionClassNames) => {
    this.setState({ isLoading: true });
    this.initiatePdfGeneration(currentJobDetails, insights, exclusionClassNames);
  };

  render() {
    const {
      jobIntel,
      match,
      jobsById,
      whiteLabelInfo,
      jobIntelLocationData,
      featureToggleList,
      globalSupplyApiStatus,
      version,
    } = this.props;
    const { jobId } = match.params;
    const currentJobDetails = jobsById?.[jobId] ?? {};
    const insights = [
      'Bls-DataDisplay',
      'Supply-Industries',
      'Supply-Roles',
      'Supply-Companies',
      'Supply-Skills',
      'Supply-Experience',
      'Supply-Location',
      'Supply-Education',
      'Supply-Certifications',
    ];
    const exclusionClassNames = [styles.headerButton];
    const { isLoading } = this.state;

    return (
      <>
        {version !== 'ats' ? (
          <div className="Market-intel">
            <Button
              className="Market-intel-button"
              loading={isLoading}
              disabled={globalSupplyApiStatus === 'INPROGRESS'}
              type="primary"
              onClick={() => this.handleMarketIntelDownloadClick(currentJobDetails, insights, exclusionClassNames)}
            >
              <FormattedMessage {...jobMessage.downloadMarketIntelligenceLabel} />
            </Button>
          </div>
        ) : null}

        <div id="Bls-DataDisplay">
          <BlsDataDisplay blsData={jobIntel?.BlsData} />
        </div>
        {featureToggleList.MarketIntelligence.IsEnabled ? (
          this.getMarketIntel()
        ) : (
          <JobIntel
            intelData={this.filterData(jobIntel)}
            intelLocationData={this.filterLocationData(_.get(jobIntelLocationData, 'States', []))}
            countryCode={currentJobDetails.CountryCode}
            AppName={_.get(whiteLabelInfo, ['AppName'], 'Leoforce')}
          />
        )}
      </>
    );
  }
}

JobIntelContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({}),
  }).isRequired,
};

const JobIntelContainerWithoutRouter = connect(mapStateToProps, mapDispatchToProps)(JobIntelContainer);

export default withRouter(JobIntelContainerWithoutRouter);
export { JobIntelContainerWithoutRouter, JobIntelContainer };
