/* eslint-disable no-nested-ternary */
import { Badge, Form, Icon, Tabs } from 'antd';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import * as CandidateActionCreators from '../../Actions/ActionCreators/CandidateActions';
import * as CandidateActions from '../../Actions/CandidateActions';
import * as JobActions from '../../Actions/JobActions';
import * as JobCandidatesTabActions from '../../Actions/JobCandidatesTabActions';
import {
  fetchSubSegments,
  setSelectedSubsegment,
  setSubsegmentCreateModalStatus,
  setSubsegmentPaginationData,
  updateSubSegment,
} from '../../Actions/SubSegmentActions';
import { ScoutingAgentIcon } from '../../Icons/AryaIcons';
import SmartAgentCandidateIcon from '../../Icons/SmartAgentCandidateIcon';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import {
  getBulkShortlistedNotification,
  getCandidates,
  getFilterContextId,
  getSmartAgentCandidateCount,
  getSourcingNotification,
} from '../../Reducers/CandidateReducer';
import {
  getConfig,
  getMaskingConfig,
  getNavbarRenderFlag,
  getWhiteLabelInformation,
} from '../../Reducers/ConfigReducer';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import AryaRecommendedCandidatesTab from '../AryaRecommendedCandidatesTab/AryaRecommendedCandidatesTab';
import { getAdminFeatures } from '../../Reducers/UserReducer';
import {
  getActiveSourceName,
  getActiveTab,
  getAllFilterQueries,
  getCandidateFilter,
  getCurrentPage,
  getManualSearchActiveSource,
  getPageSize,
  getSelectedActiveCandidatesCount,
  getSelectedCandidates,
} from '../../Reducers/JobCandidatesTabReducer';
import { getCurrentUserViewedStatusFlag, getSourcingStats } from '../../Reducers/JobReducer';
import {
  getFetchedCandidatesCountBySource,
  getManualSearchAllTabDisplayedCandidateCount,
  getManualSearchCandidatesFetchApiSecondsElapsed,
  getManualSearchCandidatesFetchApiStatus,
} from '../../Reducers/ManualSearchCandidateReducer';
import { getLastScoutingAgentDeletedId, getScoutingAgentUpdateStats } from '../../Reducers/ScoutingAgentReducer';
import {
  getSubSegmentListofSegment,
  getSubsegmentPaginationData,
  getselectedSubsegment,
} from '../../Reducers/SubsegmentReducer';
import { getSourceDisplayName, getSourceName } from '../../Utils/SourceUtils';
import { getManualSearchModifiedSource } from '../../Utils/ManualSearchUtils';
import { getManualSearchPayload } from '../../Reducers/ManualSearchReducer';
import ManualSearchCandidatesTab from '../ManualSearchCandidatesTab/ManualSearchCandidatesTab';
import styles from './JobCandidatesTabMapper.module.scss';
import SubSegmentList from '../SubSegment/SubSegmentList';
import { getShouldNavigateToSubsegmentRedirectionTab } from '../../Utils/CandidateListUtils';
import { ManualSearchEmptyTabScreen } from '../ManualSearchV2/ManualSearchForm/ManualSearchFormEmptyScreen';
import messages from '../ManualSearchV2/ManualSearchMessages';
import { getCurrentUser, getCurrentUserDetails } from '../../Reducers/UserSessionReducer';

const { TabPane } = Tabs;

const mapStateToProps = (state, props) => ({
  candidatesById: getCandidates(state),
  candidateListStatus: getApiStatus(state, 'candidateListStatus'),
  candidateAggregationsApiStatus: getApiStatus(state, 'CandidateAggregationsApiStatus'),
  featureToggleList: getFeatureToggleList(state),
  maskingConfig: getMaskingConfig(state),
  activeTab: getActiveTab(state),
  filter: getCandidateFilter(state),
  currPage: getCurrentPage(state),
  pageSize: getPageSize(state),
  activeSourceName: getActiveSourceName(state),
  manualSearchActiveSource: getManualSearchActiveSource(state),
  lastScoutingAgentDeletedId: getLastScoutingAgentDeletedId(state),
  currentUser: getCurrentUser(state),
  currentUserDetails: getCurrentUserDetails(state),
  whiteLabelInfo: getWhiteLabelInformation(state),
  config: getConfig(state),
  currentSelectedAllCandidates: getSelectedCandidates(state, 'Recommended'),
  selectedActiveCandidateCount: getSelectedActiveCandidatesCount(state, 'Recommended'),
  manualSearchPayload: getManualSearchPayload(state, { jobId: props.jobId }),
  allTabTotalCandidatesCount: getManualSearchAllTabDisplayedCandidateCount(state, { jobId: props.jobId }),
  fetchedCandidatesCount: source => getFetchedCandidatesCountBySource(state, { jobId: props.jobId, source }),
  allFilterQueries: getAllFilterQueries(state),
  navbarRenderFlag: getNavbarRenderFlag(state),
  allTabCandidateFetchApiStatus: getApiStatus(state, 'allTabCandidatesFetchApi'),
  fetchSubSegmentApiStatus: getApiStatus(state, 'fetchSubSegmentApiStatus'),
  updateSubsegmentApiStatus: getApiStatus(state, 'updateSubsegmentApiStatus'),
  manualSearchCandidateFetchApiStatus: source =>
    getManualSearchCandidatesFetchApiStatus(state, { jobId: props.jobId, source }),
  manualSearchCandidatesFetchApiSecondsElapsed: source =>
    getManualSearchCandidatesFetchApiSecondsElapsed(state, {
      jobId: props.jobId,
      source,
    }),
  currentUserViewedStatusFlag: getCurrentUserViewedStatusFlag(state, props.jobId),
  filterContextId: getFilterContextId(state),
  subsegmentList: getSubSegmentListofSegment(state, props.jobId),
  subsegmentPaginationData: getSubsegmentPaginationData(state),
  selectedSubsegment: getselectedSubsegment(state),
  sourcingNotification: getSourcingNotification(state),
  bulkShortlistedNotification: getBulkShortlistedNotification(state),
  scoutingAgentDeletedNotification: getScoutingAgentUpdateStats(state, props.jobId),
  adminFeatures: getAdminFeatures(state),
  smartAgentCandidateCount: getSmartAgentCandidateCount(state, props.jobId),
});

const mapDispatchToProps = {
  setJobCandidateTabPagination: JobCandidatesTabActions.setJobCandidateTabPagination,
  setJobCandidateTabFilter: JobCandidatesTabActions.setJobCandidateTabFilter,
  setSelectedCandidates: JobCandidatesTabActions.setSelectedCandidates,
  setSelectedActiveCandidateCount: JobCandidatesTabActions.setSelectedActiveCandidateCount,
  setJobCandidateTabActiveSourceName: JobCandidatesTabActions.setJobCandidateTabActiveSourceName,
  setManualSearchActiveSource: JobCandidatesTabActions.setManualSearchActiveSource,
  resetJobCandidatesTabBulkSelection: JobCandidatesTabActions.resetJobCandidatesTabBulkSelection,
  setCandidateListType: CandidateActionCreators.setCandidateListType,
  fetchBulkCandidateViewStatus: CandidateActions.fetchBulkCandidateViewStatus,
  setCurrentUserCandidateViewedFlag: JobActions.setCurrentUserCandidateViewedFlag,
  fetchSubSegmentsAction: fetchSubSegments,
  updateSubSegmentAction: updateSubSegment,
  setSubsegmentModalStatus: setSubsegmentCreateModalStatus,
  setSubsegmentPagination: setSubsegmentPaginationData,
  setSelectedSubsegmentAction: setSelectedSubsegment,
  fetchJobSourcingStats: JobActions.fetchJobSourcingStats,
  getJobSharedUsers: JobActions.getJobSharedUsers,
  setJobCandidateTabActiveTab: JobCandidatesTabActions.setJobCandidateTabActiveTab,
  fetchSmartAgentCandidates: CandidateActions.fetchSmartAgentCandidates,
};

function JobCandidatesTabMapper(props) {
  const {
    candidatesById,
    candidateListStatus,
    candidateAggregationsApiStatus,
    featureToggleList,
    maskingConfig,
    activeTab,
    currPage,
    pageSize,
    currentUser,
    totalCandidate,
    sourcedCount,
    setJobCandidateTabFilter,
    filter,
    setSelectedCandidates,
    setSelectedActiveCandidateCount,
    currentSelectedAllCandidates,
    selectedActiveCandidateCount,
    activeSourceName,
    config,
    whiteLabelInfo,
    onPageChange,
    currentPipelineFilter,
    jobId,
    candidateContext = 'job',
    currentJobDetails,
    status,
    showIntelBanners,
    atsJobCandidateListProps,
    connectionStatus,
    version,
    defaultFilter,
    fetchManualSearchCandidates,
    fetchAryaRecommendedCandidates,
    setJobCandidateTabPagination,
    onStatusChange,
    scrollToRefineIntel,
    onCloseIdealCandidateIntelPopup,
    setJobCandidateTabActiveSourceName,
    manualSearchPayload,
    manualSearchActiveSource,
    setManualSearchActiveSource,
    resetJobCandidatesTabBulkSelection,
    history,
    publishedStatus,
    jobGuid,
    planName,
    fetchedCandidatesCount,
    allTabTotalCandidatesCount,
    allFilterQueries,
    setCandidateListType,
    navbarRenderFlag,
    allTabCandidateFetchApiStatus,
    manualSearchCandidateFetchApiStatus,
    isNonInternalPortalForSegmentEnabled,
    openSipCallWindowsApp,
    fetchBulkCandidateViewStatus,
    setCurrentUserCandidateViewedFlag,
    currentUserViewedStatusFlag,
    openSegmentAtsView,
    openJobViewInNewTabCallBack,
    onClickAddJobCallBack,
    isAryaRankedTab,
    smartRecruiterVersion,
    from,
    filterContextId,
    setFrom,
    sampleCandidateCount,
    scoutingAgentCandidateCount,
    lastScoutingAgentDeletedId,
    appliedBucketScoringStatus,
    isExactMatchSearch,
    candidatesRecommendationStatus,
    fetchSubSegmentsAction,
    fetchSubSegmentApiStatus,
    subsegmentList,
    setSubsegmentModalStatus,
    updateSubSegmentAction,
    usersById,
    updateSubsegmentApiStatus,
    subsegmentPaginationData,
    selectedSubsegment,
    setSubsegmentPagination,
    setSelectedSubsegmentAction,
    fetchManualSearchCandidateList,
    fetchJobSourcingStats,
    sourcingNotification,
    bulkShortlistedNotification,
    scoutingAgentDeletedNotification,
    currentUserDetails,
    getJobSharedUsers,
    setJobCandidateTabActiveTab,
    adminFeatures,
    intl,
    fetchSmartAgentCandidates,
    smartAgentCandidateCount,
    isPulseUser,
    botEnabledFromBulkMessageAtsCallback,
  } = props;
  const urlSearchParams = new URLSearchParams(history?.location?.search || null);
  const params = Object.fromEntries(urlSearchParams.entries());
  const rankIndex = params.index;
  const activeTabName = params.activeTab;

  React.useEffect(() => {
    if (jobId?.toString() === sourcingNotification?.jobId?.toString()) {
      fetchJobSourcingStats([jobId]);
    }
  }, [sourcingNotification?.time]);

  React.useEffect(() => {
    if (jobId?.toString() === bulkShortlistedNotification?.jobId?.toString()) {
      fetchJobSourcingStats([jobId]);
    }
  }, [bulkShortlistedNotification?.time]);

  React.useEffect(() => {
    fetchJobSourcingStats([jobId]);
    if (activeSourceName === 'scoutingAgent') {
      fetchAryaRecommendedCandidates({
        filter: defaultFilter,
        invokationContext: 'defaultLoad',
        activeKey: 'scoutingAgent',
      });
    }
  }, [scoutingAgentDeletedNotification]);

  React.useEffect(() => {
    if (
      getShouldNavigateToSubsegmentRedirectionTab(history?.location, featureToggleList)?.shouldNavigateToSubsegment &&
      version === 'ats'
    ) {
      setJobCandidateTabActiveTab({ activeTab: 'subsegment' });
    }
  }, []);

  const onTabChange = async (activeKey, pageNumber) => {
    if (activeKey === 'AryaRecommended' || activeKey === 'scoutingAgent') {
      const rankValue = version === 'ats' ? atsJobCandidateListProps.atsCandidateRankIndex : rankIndex;
      const updatedfrom = rankValue > -1 ? Math.floor(rankValue / pageSize) * pageSize : 0;
      const candidatesFetchPayload = { ...defaultFilter, From: updatedfrom ?? 0 };
      await setJobCandidateTabActiveSourceName({ activeSourceName: activeKey });
      fetchAryaRecommendedCandidates({ filter: candidatesFetchPayload, invokationContext: 'defaultLoad', activeKey });
      setCandidateListType('SourcedCandidateList');
      if (!isNaN(pageNumber)) setJobCandidateTabPagination({ currPage: pageNumber });
    } else {
      if (activeKey === 'smartAgentCandidates') {
        await setJobCandidateTabActiveSourceName({ activeSourceName: activeKey });
        await setManualSearchActiveSource({ manualSearchActiveSource: activeKey });
        await fetchSmartAgentCandidates({ from, size: pageSize, jobId });
      } else {
        const activeSource = sourcesSearched.find(source => getSourceName(source) === activeKey);
        const currentPortalQueries = manualSearchPayload?.PortalQueries?.[activeKey];
        const filterQueries = allFilterQueries[activeKey];
        const updatedPortalQueries = { ...currentPortalQueries, ...filterQueries };
        const newManualSearchPayload = {
          ...manualSearchPayload,
          Sources: [activeSource],
          PortalQueries: { [activeKey]: updatedPortalQueries },
          From: 0,
          Size: pageSize,
        };
        setManualSearchActiveSource({ manualSearchActiveSource: activeSource });
        fetchManualSearchCandidates({ manualSearchPayload: newManualSearchPayload });
      }
      setCandidateListType('ManualSearchCandidateList');
      setJobCandidateTabPagination({ currPage: 1 });
    }
    setJobCandidateTabActiveSourceName({ activeSourceName: activeKey });
    resetJobCandidatesTabBulkSelection();
  };

  function getDefaultPageNumber(defaultPageSize) {
    const candidateIndex = version === 'ats' ? rankIndex?.toString() : params?.index ?? 0;
    if (candidateIndex === '-1') {
      return 1;
    }
    return Math.floor(candidateIndex / defaultPageSize) + 1;
  }

  React.useEffect(() => {
    const pageNumber = getDefaultPageNumber(pageSize);
    if (activeTabName === 'scoutingAgent') onTabChange('scoutingAgent', pageNumber);
  }, [activeTabName]);

  let {
    CandidateManualSearch: { IsEnabled: isCandidateManualSearchEnabled },
  } = featureToggleList;
  isCandidateManualSearchEnabled = isCandidateManualSearchEnabled && !isPulseUser;
  const isNavbarHidden = version === 'ats' || !navbarRenderFlag;

  const candidateSearchMaxLimit = !isExactMatchSearch ? config?.MaxCandidateCountInAdvanceSearch : 10;
  const segmentCandidateSearchMaxLimit = !isExactMatchSearch ? 50000 : 10;
  const segmentMaximumSelectionLimit = !isExactMatchSearch ? config?.SegmentCandidateMaxLimitPerQuery : 10;

  const isCandidateCRMEnabled = featureToggleList.CandidateCRM.IsEnabled;
  const isSegmentCandidateSearchLimitFeatureEnabled = featureToggleList.SegmentCandidateSearchLimit.IsEnabled;
  const isAdvanceSearchV2Enabled = featureToggleList.AdvanceSearchV2.IsEnabled;
  let showAdvancedSearchV2 = isAdvanceSearchV2Enabled;
  if (smartRecruiterVersion) showAdvancedSearchV2 = smartRecruiterVersion === 'v2';
  const getAryaRecommendedTab = (
    <AryaRecommendedCandidatesTab
      candidatesById={candidatesById}
      totalCandidate={totalCandidate}
      candidateListStatus={candidateListStatus}
      candidateAggregationsApiStatus={candidateAggregationsApiStatus}
      featureToggleList={featureToggleList}
      currentPipelineFilter={currentPipelineFilter}
      jobId={jobId}
      candidateContext={candidateContext}
      currentJobDetails={currentJobDetails}
      maskingConfig={maskingConfig}
      activeTab={activeTab}
      status={status}
      currentUser={currentUser}
      currentUserDetails={currentUserDetails}
      showIntelBanners={showIntelBanners}
      atsJobCandidateListProps={atsJobCandidateListProps}
      connectionStatus={connectionStatus}
      currPage={currPage}
      defaultFilter={defaultFilter}
      pageSize={pageSize}
      version={version}
      onStatusChange={onStatusChange}
      fetchCandidates={fetchAryaRecommendedCandidates}
      filter={filter}
      onPageChange={onPageChange}
      scrollToRefineIntel={scrollToRefineIntel}
      onCloseIdealCandidateIntelPopup={onCloseIdealCandidateIntelPopup}
      setJobCandidateTabFilter={setJobCandidateTabFilter}
      setJobCandidateTabPagination={setJobCandidateTabPagination}
      setSelectedCandidates={setSelectedCandidates}
      setSelectedActiveCandidateCount={setSelectedActiveCandidateCount}
      currentSelectedAllCandidates={currentSelectedAllCandidates}
      selectedActiveCandidateCount={selectedActiveCandidateCount}
      history={history}
      publishedStatus={publishedStatus}
      jobGuid={jobGuid}
      planName={planName}
      bulkOptionsOffestTop={isNavbarHidden ? 0 : 64}
      openSipCallWindowsApp={openSipCallWindowsApp}
      isNonInternalPortalForSegmentEnabled={isNonInternalPortalForSegmentEnabled}
      fetchBulkCandidateViewStatus={fetchBulkCandidateViewStatus}
      setCurrentUserCandidateViewedFlag={setCurrentUserCandidateViewedFlag}
      currentUserViewedStatusFlag={currentUserViewedStatusFlag}
      openSegmentAtsView={openSegmentAtsView}
      openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
      onClickAddJobCallBack={onClickAddJobCallBack}
      isAryaRankedTab={isAryaRankedTab}
      smartRecruiterVersion={smartRecruiterVersion}
      activeSourceName={activeSourceName}
      from={from}
      filterContextId={filterContextId}
      setFrom={setFrom}
      sampleCandidateCount={sampleCandidateCount}
      appliedBucketScoringStatus={appliedBucketScoringStatus}
      candidatesRecommendationStatus={candidatesRecommendationStatus}
      location={history.location}
      adminFeatures={adminFeatures}
      botEnabledFromBulkMessageAtsCallback={botEnabledFromBulkMessageAtsCallback}
    />
  );

  const smartAgentCandidatesTab = (
    <ManualSearchCandidatesTab
      activeSource={manualSearchActiveSource}
      jobId={jobId}
      candidateContext={candidateContext}
      currentJobDetails={currentJobDetails}
      bulkOptionsOffestTop={isNavbarHidden ? 0 : 64}
      fetchManualSearchCandidates={fetchManualSearchCandidates}
      version={version}
      onPageChange={onPageChange}
      isSegmentCandidateSearchLimitFeatureEnabled={isSegmentCandidateSearchLimitFeatureEnabled}
      openSipCallWindowsApp={openSipCallWindowsApp}
      atsJobCandidateListProps={atsJobCandidateListProps}
      openSegmentAtsView={openSegmentAtsView}
      openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
      onClickAddJobCallBack={onClickAddJobCallBack}
      smartRecruiterVersion={smartRecruiterVersion}
      onStatusChange={onStatusChange}
      activeTab={activeTab}
      isExactMatchSearch={isExactMatchSearch}
      candidatesRecommendationStatus={candidatesRecommendationStatus}
      maskingConfig={maskingConfig}
      botEnabledFromBulkMessageAtsCallback={botEnabledFromBulkMessageAtsCallback}
    />
  );

  const aryaRecommededComponentList = ['sourced', 'subsegment'];

  if (
    (!isCandidateManualSearchEnabled && candidateContext === 'job') ||
    !aryaRecommededComponentList.includes(activeTab?.toLowerCase())
  ) {
    return getAryaRecommendedTab;
  }

  const sourcesSearched = manualSearchPayload?.Sources ?? [];
  const AppName = _.get(whiteLabelInfo, ['AppName'], 'Leoforce');

  const getTotalCandidateCount = sourceName => {
    return sourceName === 'All' ? allTabTotalCandidatesCount : fetchedCandidatesCount(sourceName);
  };

  const getTotalCandidateDisplayCount = sourceName => {
    const totalCandidateCount = getTotalCandidateCount(sourceName) || 0;
    const getDisplayCount = limit => {
      const displayedCount = Math.min(totalCandidateCount, limit);
      return displayedCount === limit && displayedCount !== totalCandidateCount ? `${limit}+` : `${displayedCount}`;
    };

    if (isSegmentCandidateSearchLimitFeatureEnabled && candidateContext === 'segment') {
      return getDisplayCount(segmentCandidateSearchMaxLimit);
    }

    if (candidateContext === 'segment') {
      return getDisplayCount(segmentMaximumSelectionLimit);
    }

    return getDisplayCount(candidateSearchMaxLimit);
  };

  const getTabPane = source => {
    const revealActiveChannelSourceName = featureToggleList.RevealPortalsUnderGroup.IsEnabled;
    const {
      MaskActiveAsInternal: { IsEnabled: isMaskActiveAsInternalEnabled },
    } = featureToggleList;
    const sourceName = getSourceName(source);
    const totalCandidateDisplayCount = getTotalCandidateDisplayCount(sourceName);
    const isCandidateFetchRequestInProgress =
      sourceName === 'All'
        ? allTabCandidateFetchApiStatus === 'INPROGRESS'
        : manualSearchCandidateFetchApiStatus(sourceName) === 'INPROGRESS';

    const sourceDisplayName = getSourceDisplayName(
      source,
      config,
      config.ShowVaultName,
      { Name: 'quickSearchCandidateTabs' },
      whiteLabelInfo,
      revealActiveChannelSourceName,
      isMaskActiveAsInternalEnabled
    );
    const getTab = () => {
      if (!isCandidateFetchRequestInProgress)
        return (
          <div>{`${
            isMaskActiveAsInternalEnabled && sourceDisplayName === 'Active' ? 'Internal' : sourceDisplayName
          } (${totalCandidateDisplayCount})`}</div>
        );
      return (
        <div className={styles.loadingIcon}>
          {isMaskActiveAsInternalEnabled && sourceDisplayName === 'Active' ? 'Internal' : sourceDisplayName}{' '}
          <Icon type="loading" />
        </div>
      );
    };
    return (
      <TabPane tab={getTab()} key={sourceName}>
        <ManualSearchCandidatesTab
          activeSource={manualSearchActiveSource}
          jobId={jobId}
          currentJobDetails={currentJobDetails}
          atsJobCandidateListProps={atsJobCandidateListProps}
          bulkOptionsOffestTop={isNavbarHidden ? 0 : 64}
          fetchManualSearchCandidates={fetchManualSearchCandidates}
          candidateContext={candidateContext}
          allTabCandidateFetchApiStatus={allTabCandidateFetchApiStatus}
          version={version}
          onPageChange={onPageChange}
          isSegmentCandidateSearchLimitFeatureEnabled={isSegmentCandidateSearchLimitFeatureEnabled}
          isNonInternalPortalForSegmentEnabled={isNonInternalPortalForSegmentEnabled}
          openSipCallWindowsApp={openSipCallWindowsApp}
          openSegmentAtsView={openSegmentAtsView}
          openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
          onClickAddJobCallBack={onClickAddJobCallBack}
          smartRecruiterVersion={smartRecruiterVersion}
          showAdvancedSearchV2={showAdvancedSearchV2}
          onStatusChange={onStatusChange}
          activeTab={activeTab}
          isExactMatchSearch={isExactMatchSearch}
          maskingConfig={maskingConfig}
          fetchManualSearchCandidateList={fetchManualSearchCandidateList}
          botEnabledFromBulkMessageAtsCallback={botEnabledFromBulkMessageAtsCallback}
        />
      </TabPane>
    );
  };

  const totalCandidateDisplayCount = getTotalCandidateDisplayCount(activeSourceName);

  const isScoutingAgentEnabled = _.get(featureToggleList, ['ScoutingAgent', 'IsEnabled'], false);
  const isSmartAgentCandidateEnabled = _.get(featureToggleList, ['SmartAgentCandidate', 'IsEnabled'], false);
  const showScoutingAgentTab =
    isScoutingAgentEnabled && scoutingAgentCandidateCount > 0 && candidateContext !== 'segment';
  const searchListTab = () => {
    const tabName = intl.formatMessage({ ...messages.searchResultsLabel });
    const updatedSources = getManualSearchModifiedSource(candidateContext, isNonInternalPortalForSegmentEnabled);
    const activeSourceKey = getSourceName(updatedSources);
    return (
      <div className={styles.cadidateSearchTab}>
        <Tabs
          activeKey={activeSourceName}
          onChange={onTabChange}
          className={styles.candidatesTab}
          destroyInactiveTabPane
          animated={false}
        >
          <TabPane tab={`${tabName} (${totalCandidateDisplayCount})`} key={activeSourceKey}>
            <ManualSearchCandidatesTab
              activeSource={manualSearchActiveSource}
              jobId={jobId}
              candidateContext={candidateContext}
              currentJobDetails={currentJobDetails}
              bulkOptionsOffestTop={isNavbarHidden ? 0 : 64}
              fetchManualSearchCandidates={fetchManualSearchCandidates}
              version={version}
              onPageChange={onPageChange}
              isSegmentCandidateSearchLimitFeatureEnabled={isSegmentCandidateSearchLimitFeatureEnabled}
              openSipCallWindowsApp={openSipCallWindowsApp}
              atsJobCandidateListProps={atsJobCandidateListProps}
              openSegmentAtsView={openSegmentAtsView}
              openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
              onClickAddJobCallBack={onClickAddJobCallBack}
              smartRecruiterVersion={smartRecruiterVersion}
              onStatusChange={onStatusChange}
              activeTab={activeTab}
              isExactMatchSearch={isExactMatchSearch}
              candidatesRecommendationStatus={candidatesRecommendationStatus}
              maskingConfig={maskingConfig}
              botEnabledFromBulkMessageAtsCallback={botEnabledFromBulkMessageAtsCallback}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  };
  if (candidateContext === 'job' || (candidateContext === 'segment' && isCandidateCRMEnabled)) {
    if (
      activeTab?.toLowerCase() === 'sourced' &&
      candidateContext === 'segment' &&
      !isNonInternalPortalForSegmentEnabled
    ) {
      return searchListTab();
    }
  }
  const updatedsearchListTab = sourcesSearched.length > 0 ? null : searchListTab();

  const showManualSearchTabs = !(showAdvancedSearchV2 && sourcesSearched?.length === 0);

  const scoutingAgentTab = showScoutingAgentTab ? (
    <div
      onClick={() => {
        if (activeSourceName !== 'scoutingAgent') onTabChange('scoutingAgent');
      }}
      style={{ paddingBottom: showManualSearchTabs ? '11px' : null }}
      className={classNames(styles.extraTabContent, {
        [styles.extraTabActiveDiv]: activeSourceName === 'scoutingAgent',
      })}
    >
      <div className={styles.scoutingAgentWithCount}>
        <ScoutingAgentIcon
          width="24"
          height="24"
          style={{ fontSize: '24px', color: activeSourceName === 'scoutingAgent' ? '#13c26b' : '#83878C' }}
        />
        <Badge
          style={{ backgroundColor: '#13c26b' }}
          count={scoutingAgentCandidateCount}
          overflowCount={10000}
          showZero
        />
      </div>
    </div>
  ) : null;

  const smartCandidatesTab =
    featureToggleList.SmartAgentCandidate.IsEnabled && candidateContext === 'job' ? (
      <div
        onClick={() => {
          if (activeSourceName !== 'smartAgentCandidates') onTabChange('smartAgentCandidates');
        }}
        style={{ paddingBottom: showManualSearchTabs ? '11px' : null }}
        className={classNames(styles.extraTabContent, {
          [styles.extraTabActiveDiv]: activeSourceName === 'smartAgentCandidates',
        })}
      >
        <div className={styles.smartAgentWithCount}>
          <SmartAgentCandidateIcon
            width="20"
            height="20"
            style={{ fontSize: '20px', color: activeSourceName === 'smartAgentCandidates' ? '#13c26b' : '#83878C' }}
          />
          <div className={styles.smartAgentCandidateCountDiv}>{smartAgentCandidateCount || 0}</div>
        </div>
      </div>
    ) : null;

  const manualSearchTabs =
    candidateContext === 'job' || (candidateContext === 'segment' && isCandidateCRMEnabled) ? (
      <Tabs
        activeKey={activeSourceName}
        onChange={onTabChange}
        className={classNames(styles.candidatesTab, {
          [styles.scoutingAgentTabCandidatesTab]:
            !showManualSearchTabs && (isScoutingAgentEnabled || isSmartAgentCandidateEnabled),
        })}
        destroyInactiveTabPane
        animated={false}
        tabBarExtraContent={
          <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
            {smartCandidatesTab}
            {scoutingAgentTab}
          </div>
        }
      >
        {!showAdvancedSearchV2 ? (
          !(isNonInternalPortalForSegmentEnabled && candidateContext === 'segment') || candidateContext === 'job' ? (
            <TabPane tab={`${AppName} Recommended (${sourcedCount || 0})`} key="AryaRecommended" destroyInactiveTabPane>
              {getAryaRecommendedTab}
            </TabPane>
          ) : (
            updatedsearchListTab
          )
        ) : null}
        {sourcesSearched.map(source => getTabPane(source))}
      </Tabs>
    ) : null;

  return showManualSearchTabs || isScoutingAgentEnabled || isSmartAgentCandidateEnabled ? (
    <div className={styles.manualSearchTabs}>
      {activeTab !== 'subsegment' && manualSearchTabs}
      {activeSourceName === 'scoutingAgent' && getAryaRecommendedTab}
      {activeSourceName === 'smartAgentCandidates' ? smartAgentCandidatesTab : null}
      {activeTab === 'subsegment' && candidateContext === 'segment' && (
        <SubSegmentList
          history={history}
          segmentId={jobId}
          subsegmentList={subsegmentList}
          updateSubSegmentAction={updateSubSegmentAction}
          fetchSubSegmentApiStatus={fetchSubSegmentApiStatus}
          fetchSubSegments={fetchSubSegmentsAction}
          getAryaRecommendedTab={getAryaRecommendedTab}
          onStatusChange={onStatusChange}
          usersById={usersById}
          currentJobDetails={currentJobDetails}
          updateSubsegmentApiStatus={updateSubsegmentApiStatus}
          setSubsegmentModalStatus={setSubsegmentModalStatus}
          subsegmentPaginationData={subsegmentPaginationData}
          setSubsegmentPaginationData={setSubsegmentPagination}
          setSelectedSubsegment={setSelectedSubsegmentAction}
          selectedSubsegment={selectedSubsegment}
          featureToggleList={featureToggleList}
          location={history.location}
          getJobSharedUsers={getJobSharedUsers}
          version={version}
        />
      )}
      {!showManualSearchTabs &&
        (isScoutingAgentEnabled || isSmartAgentCandidateEnabled) &&
        activeSourceName !== 'scoutingAgent' &&
        activeTab !== 'subsegment' &&
        activeSourceName !== 'smartAgentCandidates' && <ManualSearchEmptyTabScreen />}
    </div>
  ) : (
    <ManualSearchEmptyTabScreen />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Form.create()(JobCandidatesTabMapper)));
export { JobCandidatesTabMapper as JobCandidatesTabMapperWihtoutStore };
