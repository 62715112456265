/* eslint-disable no-param-reassign */
import _ from 'lodash';
import { batch } from 'react-redux';
import FileSaver from 'file-saver';
import mime from 'mime';
import uuid from 'uuid/v4';
import PubSub from 'pubsub-js';
import { SETUP_SOCKET_CONNECTIONS, BROADCAST_CHANNEL_POST_MESSAGE } from '../PubSub/EventTypes';
import {
  pushCandidateToConnect,
  fetchConnectStatus,
  fetchBulkCallNotes,
  getContact,
  addCandidateContactInfo,
  fetchConversationStats,
  fetchSubscriptionStats,
  searchConversations,
  fetchBulkUnReadConversationsCount,
} from './ConnectActions';
import { setNotification } from './ActionCreators/ConnectActions';
import { getMergedCandidateDetails, fetchCandidateNotes } from './CandidateConnectActions';
import * as candidateRepo from '../Repository/CandidateRepository';
import fetchJobSourcingStats from './SourcingStatsActions';
import * as quickSearchRepository from '../Repository/QuickSearchRepository';
import * as manualSearchRepository from '../Repository/ManualSearchRepository';
import * as candidateV2Repo from '../Repository/CandidateV2Repository';
import * as connectRepo from '../Repository/ConnectRepository';
import * as tagRepository from '../Repository/TagRepository';
import * as noteRepository from '../Repository/NoteRepository';
import {
  getSourceDisplayName,
  getSourceName,
  getCandidateSource,
  getCandidateCountBySources,
  getCandidateSourceName,
  mergeCandidateData,
  getCandidateApplicationInfo,
} from '../Utils/SourceUtils';
import { mapErrorCodes } from '../Utils/CandidateApiErrorResponseMapper';
import {
  mapCandidateListApiErrorCodes,
  mapBulkPublishCandidatesApiErrorCode,
} from '../Utils/CandidateListApiErrorResponseMapper';
import { setConversationPendingMessages } from './MessageConversationActions';
import { getConfig, getMaskingConfig } from '../Reducers/ConfigReducer';
import { getJobDetails, getSubscriptionStats } from '../Reducers/JobReducer';
import {
  getCandidatesAggregations,
  getCandidateListType,
  getCandidateRecommendationSource,
} from '../Reducers/CandidateReducer';
import { isValidContact } from '../Utils/ConnectUtils';
import { getFilteredStatusesArray, getDefaultStatsFilter, getAggregatedPersonIds } from '../Utils/EmailStatsUtils';
import {
  setFetchSendMessageApiStatus,
  setCandidates,
  setCandidateAllDetails,
  setCandidateAllDetailsError,
  setCandidateCount,
  setDilatedCandidateCount,
  setCandidateListApiStatus,
  setCandidateListError,
  setFetchContactStatus,
  setCandidateViewStatus,
  setCandidateStatus,
  setCandidateRejectFlag,
  updateUnlockCandidate,
  setBulkCallNotesUsingCandidateIds,
  setBulkCandidateNotes,
  setCandidateAggregations,
  setCandidateIntel,
  setCandidateIntelApiStatus,
  setFetchTryNowCanidateApiStatus,
  setBulkFetchContactStatus,
  setFetchBulkContactsApiStatus,
  setCandidateSpecificationsApiStatus,
  setFetchTryNowCanidateDetailsApiStatus,
  fetchCandidateSpecificationsData,
  setBulkShortlistApiStatus,
  setCandidateAllDetailsApiStatus,
  setConnectStatusApiStatus,
  clearCandidatesExtraInfo as _clearCandidatesExtraInfo,
  setCandidateBookmarkApiStatus,
  setCandidateFavouriteStatus,
  setCandidateJobGlobalTags,
  setFetchCandidateJobGlobalTagsApiStatus,
  setFetchCandidateGlobalTagsApiStatus,
  setCandidateTags,
  createCandidateTag as _createCandidateTag,
  createCandidateJobGlobalTag,
  removeCandidateTag,
  removeCandidateJobGlobalTag,
  setCandidateActivityLogs,
  setInitialFetchActivityLogsApiStatus,
  setFetchCandidateMatchingJobsApiStatus,
  setCandidateMatchingJobs,
  setCandidateRecommendationSource,
  setFilterContextId,
  setCandidateDownloadedDetails,
  resetCandidateJobs as _resetCandidateJobs,
  setBulkCandidateViewStatus,
  setCandidateViewedApiStatus,
  setCandidateViewStatusV2,
  setCandidateAggregationsApiStatus,
  setCandidateMatchingJobsSearchTerm,
  setCandidateJobMatchingJobFilters,
  setSampleCandidateCount,
  setScoringStatus,
  setCandidateNotesCount,
  setFavouriteCandidateApiStatus,
  setCandidatePublishApiStatus,
  setRemoveFavouriteCandidateApiStatus,
  setSmartAgentsCandidateCount,
} from './ActionCreators/CandidateActions';
import { setApiStatus, setCandidateDownloadResumeApiSatus } from './ApiStatusActions';
import { setSegmentBulkRecommendErrorMessage } from './JobCandidatesTabActions';
import {
  setCandidatesCountBySource,
  setManualSearchNextPaginatedQueryBySources,
  setCandidatesBySource,
  setCandidatesFetchedCountBySource,
  setAllTabAggregationFilters,
} from './ActionCreators/ManualSearchCandidateActionCreators';
import { addMustHavesToJob, setJobDetails, updateFavouriteAggregation } from './ActionCreators/JobActionCreator';
import { getFeatureToggleList } from '../Reducers/FeatureToggleReducer.ts';
import { fetchJobDilatedIntel } from './DilatedIntelActions';
import _store from '../store';
import {
  addCandidateNote,
  addCandidateNoteTags,
  clearCandidateNotes,
  removeCandidateNote,
  removeTagFromCandidateNote,
  setCandidateNoteCreateApiStatus,
  setPopupNoteCreateApiStatus,
  setCandidateNoteDeleteApiStatus,
  setCandidateNoteFetchApiStatus,
  setCandidateNotes,
  updateCandidateNote as _updateCandidateNote,
} from './ActionCreators/NoteActionCreator';
import {
  clearCandidateSuggestedTags,
  fetchSuggestedTagsApiStatus,
  setCandidateSuggestedTags,
  setTagDeleteApiStatus,
} from './ActionCreators/TagActionCreator';
import { getGroupHeadByCandidateId } from '../Utils/DeDuplicationUtils';
import { groupDuplicatesBySource } from './ManualSearchCandidateActions';
import {
  allowedDeDuplicationSources,
  getFilteredAllTabCandidates,
  getAllTabCandidatesAggregation,
} from '../Utils/CandidateListUtils';
import { extractNumberFromText } from '../Utils/TextUtils';
import { isExactMatchSearch } from '../Utils/ManualSearchUtils';
import { fetchBulkSmartAgentStats } from './ManualSearchActions';
import { fetchJobUsageAvailableBudget } from './JobUsageBudgetActions';

function getErrorMessageForCandidateNotFound(errorData) {
  return {
    ...errorData,
    Message: 'Profile information is not available',
    ErrorDescription:
      'This may be because the profile is private, or the candidate has removed their profile from this portal',
  };
}

function getErrorMessageForCredentialsNotFound(errorData, displayName) {
  return {
    ...errorData,
    Message: `${displayName} is Disconnected!`,
    ErrorDescription: `Your ${displayName} account is currently signed out from Leoforce due to either expired or invalid credentials. To see candidates from this source, please sign back with your valid credentials in Application Settings.`,
    showMessageAsWarning: true,
    showSettingsLink: true,
  };
}

function resetCandidates() {
  return dispatch => {
    dispatch(setCandidates([]));
    dispatch(setCandidateCount());
    dispatch(setDilatedCandidateCount());
  };
}

function setCandidateListData(candidateListData, count) {
  return dispatch => {
    dispatch(setCandidates(candidateListData));
    dispatch(setCandidateCount(count));
  };
}

const getAllCandidateNotes = ({ jobId, candidateId }) => {
  return dispatch => {
    dispatch(clearCandidateNotes(candidateId));
    dispatch(setCandidateNoteFetchApiStatus('INPROGRESS'));
    noteRepository
      .getNotes({ jobId, candidateId })
      .then(response => {
        const payload = response.data;
        dispatch(setCandidateNotes({ candidateId, notes: payload.Notes }));
        dispatch(setCandidateNoteFetchApiStatus('COMPLETED'));
      })
      .catch(error => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: error,
            timeStamp: new Date(),
          },
        });
        dispatch(setCandidateNoteFetchApiStatus('FAILED'));
      });
  };
};

export const undoApplyFromPortal = (jobId, candidateId) => {
  return async dispatch => {
    try {
      await candidateRepo.undoApplyFromPortal(jobId, candidateId);
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'jobApplicationWithdrawnSuccessfully',
        })
      );
    } catch {
      dispatch(
        setNotification('ERROR', {
          messageId: 'oopsSomethingJustWentWrong',
        })
      );
    }
  };
};

function fetchTagsForCandidate(filter) {
  return dispatch => {
    dispatch(fetchSuggestedTagsApiStatus('INPROGRESS'));
    if (filter.from === 0) dispatch(clearCandidateSuggestedTags());
    if (filter.searchTerm?.length === 0) {
      dispatch(clearCandidateSuggestedTags());
      return;
    }
    tagRepository
      .fetchSuggestedTagsForCandidate(filter)
      .then(response => {
        const payload = response.data;
        dispatch(setCandidateSuggestedTags(payload));
        dispatch(fetchSuggestedTagsApiStatus('COMPLETED'));
      })
      .catch(() => {
        dispatch(clearCandidateSuggestedTags());
        dispatch({
          type: 'SET_ERROR',
          payload: {
            code: 'TAGS_FETCH_FAILED',
            timeStamp: new Date(),
          },
        });
        dispatch(fetchSuggestedTagsApiStatus('FAILED'));
      });
  };
}

const deleteCandidateNote = ({ candidateId, noteId, candidateNotesCount, vaultName }) => {
  return dispatch => {
    dispatch(setCandidateNoteDeleteApiStatus({ [noteId]: 'INPROGRESS' }));
    noteRepository
      .deleteNote({ noteId, vaultName })
      .then(() => {
        dispatch(removeCandidateNote({ noteId, candidateId }));
        dispatch(setCandidateNoteDeleteApiStatus({ [noteId]: 'COMPLETED' }));
        dispatch(
          setNotification('SUCCESS', {
            messageId: 'noteDeletedSuccessfully',
          })
        );
        dispatch(setCandidateNotesCount(candidateId, candidateNotesCount - 1));
      })
      .catch(error => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: error,
            timeStamp: new Date(),
          },
        });
        dispatch(setCandidateNoteDeleteApiStatus({ [noteId]: 'FAILED' }));
        dispatch(
          setNotification('ERROR', {
            messageId: 'oopsSomethingJustWentWrong',
          })
        );
      });
  };
};

const updateCandidateNote = ({ candidateId, noteId, note, vaultName }) => {
  return async dispatch => {
    dispatch(setCandidateNoteCreateApiStatus('INPROGRESS'));
    try {
      const response = await noteRepository.updateNote({ noteId, payload: note, vaultName });
      dispatch(_updateCandidateNote({ candidateId, note: response.data }));
      dispatch(setCandidateNoteCreateApiStatus('COMPLETED'));
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'noteUpdatedSuccessfully',
        })
      );
      return { isSuccess: true };
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          message: error,
          timeStamp: new Date(),
        },
      });
      dispatch(
        setNotification('ERROR', {
          messageId: 'oopsSomethingJustWentWrong',
        })
      );
      dispatch(setCandidateNoteCreateApiStatus('FAILED'));
      return { isSuccess: false };
    }
  };
};

function addTagForCandidateNote({ candidateId, noteId, tag }) {
  return dispatch => {
    tagRepository
      .createNoteTags({ noteId, tags: [tag] })
      .then(response => {
        dispatch(addCandidateNoteTags({ candidateId, noteId, tags: response.data.Tags }));
        dispatch(
          setNotification('SUCCESS', {
            messageId: 'tagForNoteAddedSuccessfully',
          })
        );
      })
      .catch(error => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: error,
            timeStamp: new Date(),
          },
        });
        dispatch(
          setNotification('ERROR', {
            messageId: 'oopsSomethingJustWentWrong',
          })
        );
      });
  };
}

function fetchCandidateActivityLogs({ candidateId, from, size }) {
  return async dispatch => {
    if (from === 0) {
      dispatch(setInitialFetchActivityLogsApiStatus('INPROGRESS'));
    }
    try {
      const response = await candidateRepo.fetchCandidateActivityLogs({ candidateId, from, size });
      dispatch(setCandidateActivityLogs({ candidateId, activityLogs: response.data }));
      if (from === 0) {
        dispatch(setInitialFetchActivityLogsApiStatus('COMPLETED'));
      }
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          message: error,
          timeStamp: new Date(),
        },
      });
      if (from === 0) {
        dispatch(setInitialFetchActivityLogsApiStatus('FAILED'));
      }
    }
  };
}

function deleteTagFromCandidateNote({ tag, candidateId, noteId }) {
  return async dispatch => {
    dispatch(setTagDeleteApiStatus({ [tag?.AssociationId]: 'INPROGRESS' }));
    try {
      await tagRepository.deleteTag({ tagAssociationId: tag?.AssociationId });
      dispatch(removeTagFromCandidateNote({ candidateId, noteId, tagAssociationId: tag?.AssociationId }));
      dispatch(setTagDeleteApiStatus({ [tag?.AssociationId]: 'COMPLETED' }));
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'tagDeletedSuccessfully',
        })
      );
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          message: err,
          timeStamp: new Date(),
        },
      });
      dispatch(setTagDeleteApiStatus({ [tag?.AssociationId]: 'FAILED' }));
      dispatch(
        setNotification('ERROR', {
          messageId: 'oopsSomethingJustWentWrong',
        })
      );
    }
  };
}

function updateCandidateBookmarkStatus({ jobId, candidateId, isFavourite }) {
  return async dispatch => {
    dispatch(setCandidateBookmarkApiStatus({ [candidateId]: 'INPROGRESS' }));
    try {
      await candidateRepo.updateCandidateBookmarkStatus({
        jobId,
        candidateIds: [candidateId],
        isFavourite,
      });
      dispatch(setCandidateFavouriteStatus({ candidateId, isFavourite }));
      dispatch(updateFavouriteAggregation({ candidateId, isFavourite, jobId }));
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'candidateBookmarkUpdatedSuccessfully',
        })
      );
      dispatch(setCandidateBookmarkApiStatus({ [candidateId]: 'COMPLETED' }));
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          message: error,
          timeStamp: new Date(),
        },
      });
      dispatch(setCandidateBookmarkApiStatus({ [candidateId]: 'FAILED' }));
      dispatch(
        setNotification('ERROR', {
          messageId: 'oopsSomethingJustWentWrong',
        })
      );
    }
  };
}

function fetchBulkCandidateNotes(filter) {
  return dispatch => {
    const notesFilter = {
      CandidateIds: _.get(filter, 'candidateIds', []),
      From: _.get(filter, 'from', 0),
      Size: _.get(filter, 'size', 0),
    };
    if (notesFilter.CandidateIds.length === 0) {
      return Promise.resolve();
    }
    dispatch({
      type: 'SET_FETCH_BULK_CANDIDATE_NOTES_API_STATUS',
      payload: {
        status: 'INPROGRESS',
      },
    });
    dispatch({
      type: 'SET_CANDIDATES_NOTES_FETCH_API_STATUS',
      payload: {
        candidateIds: _.get(filter, 'candidateIds', []),
        status: 'INPROGRESS',
      },
    });
    return candidateRepo
      .fetchBulkCandidateNotes(filter.jobId, notesFilter)
      .then(response => {
        const candidateIds = _.get(filter, 'candidateIds', []);
        const notesByCandidateId = _.get(response, 'data', {});
        dispatch(setBulkCandidateNotes(candidateIds, notesByCandidateId));
        dispatch({
          type: 'SET_CANDIDATES_NOTES_FETCH_API_STATUS',
          payload: {
            candidateIds: _.get(filter, 'candidateIds', []),
            status: 'COMPLETED',
          },
        });
        dispatch({
          type: 'SET_FETCH_BULK_CANDIDATE_NOTES_API_STATUS',
          payload: {
            status: 'COMPLETED',
          },
        });
      })
      .catch(error => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: error,
            timeStamp: new Date(),
          },
        });
        dispatch({
          type: 'SET_CANDIDATES_NOTES_FETCH_API_STATUS',
          payload: {
            candidateIds: _.get(filter, 'candidateIds', []),
            status: 'FAILED',
          },
        });
        dispatch({
          type: 'SET_FETCH_BULK_CANDIDATE_NOTES_API_STATUS',
          payload: {
            status: 'FAILED',
          },
        });
        throw error;
      });
  };
}

const resetCandidateJobs = () => {
  return _resetCandidateJobs();
};

function fetchBulkCandidateAllNotes(bulkCandidateNotesFilter, bulkCallNotesFilter, nonContactedCandidateIds = []) {
  return dispatch => {
    const isBulkFetch = bulkCandidateNotesFilter.candidateIds.length > 1;
    if (isBulkFetch) {
      dispatch({
        type: 'SET_FETCH_BULK_CANDIDATE_ALL_NOTES_API_STATUS',
        payload: {
          status: 'INPROGRESS',
        },
      });
    }
    Promise.all([
      dispatch(fetchBulkCandidateNotes(bulkCandidateNotesFilter)),
      dispatch(fetchBulkCallNotes(bulkCallNotesFilter)),
    ])
      .then(() => {
        dispatch(setBulkCallNotesUsingCandidateIds(nonContactedCandidateIds));
        if (isBulkFetch) {
          dispatch({
            type: 'SET_FETCH_BULK_CANDIDATE_ALL_NOTES_API_STATUS',
            payload: {
              status: 'COMPLETED',
            },
          });
        }
      })
      .catch(() => {
        if (isBulkFetch) {
          dispatch({
            type: 'SET_FETCH_BULK_CANDIDATE_ALL_NOTES_API_STATUS',
            payload: {
              status: 'FAILED',
            },
          });
        }
      });
  };
}

function fetchCandidateSourceCount({ jobId, jobGuid, statuses, connectionStatus, applied }) {
  return dispatch => {
    candidateRepo
      .candidateAggregationFilters({
        jobId,
        jobGuid,
        Statuses: statuses,
        ConnectionStatus: connectionStatus,
        Applied: applied,
      })
      .then(response => {
        const candidateCountBySource = getCandidateCountBySources(
          _.get(response, ['data', 'Aggregations', 'Source'], [])
        );
        dispatch({
          type: 'SET_CANDIDATE_COUNT_BY_SOURCE',
          payload: {
            candidateCountBySource,
            jobId,
          },
        });
      })
      .catch(error => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: error,
            timeStamp: new Date(),
          },
        });
      });
  };
}

async function fetchConversationIds(filter, candidateContext = 'job') {
  if (filter.EmailStats && filter.EmailStats.length > 0 && candidateContext === 'job') {
    const conversationFilter = {
      RefId: filter.jobGuid,
      EmailStatuses: getFilteredStatusesArray(filter.EmailStats, 'Email'),
      MessageStatuses: getFilteredStatusesArray(filter.EmailStats, 'Message'),
      From: 0,
      Size: -1,
    };
    const response = await connectRepo.fetchConversationIds(conversationFilter);
    return _.get(response, ['data', 'ConversationIds'], []);
  }
  return [];
}

function fetchContactedCandidates(filter, type) {
  return async (dispatch, getState) => {
    let convId = '';
    dispatch(setCandidateListApiStatus('INPROGRESS'));
    if (type !== 'ON_LOAD') dispatch(resetCandidates());
    if (filter.conversationId) {
      convId = filter.conversationId;
      // eslint-disable-next-line no-param-reassign
      delete filter.conversationId;
    }
    const filters = {
      ...filter,
      SortBy: 'ConversationTime',
      ConnectionStatus: 'Contacted',
    };
    // Todo : ConversationIds not used anywhere
    filters.ConversationIds = await fetchConversationIds(filter);
    if (!filters.ConversationIds && convId) {
      filters.ConversationIds = [convId];
    }
    delete filters.EmailStats;
    const isAdvancedRejectReasonsEnabled = true;
    try {
      const store = getState();
      const featureToggleList = getFeatureToggleList(store);
      const isShowAllClientCandidateStatus = featureToggleList.ShowAllClientCandidateStatus.IsEnabled;
      const isMessageFiltersAllowed = featureToggleList.SMSFiltersForJob.IsEnabled;
      const defaultStatsFilter = getDefaultStatsFilter(isMessageFiltersAllowed);
      if (isShowAllClientCandidateStatus) filters.ShowAllClientCandidateStatus = true;
      const subscriptionStats = getSubscriptionStats(store);
      const subscriptionStatuses = getFilteredStatusesArray(filter.EmailStats, 'SubscriptionStats');
      filters.personIds = getAggregatedPersonIds(subscriptionStats, subscriptionStatuses);
      const response = await candidateRepo.candidateSearch(filters, isAdvancedRejectReasonsEnabled, false);
      const filterResponse = await candidateRepo.candidateAggregationFilters(filters, false);
      let Candidates = response.data.Candidates.map(c => {
        return {
          ...c.Metadata,
          ...c.Recommended,
          ...(c.ApplicationInfo ? getCandidateApplicationInfo(c.ApplicationInfo) : {}),
        };
      });
      const currentPageConversationIds = Candidates.map(candidate => candidate.ConversationId);
      const currentPagePersonIds = Candidates.map(candidate => candidate.PersonId);
      if (type === 'ON_LOAD') {
        const exisistingCandidates = Object.values(_.get(getState(), ['CandidateReducer', 'ById'], {}));
        Candidates = (exisistingCandidates || []).concat(Candidates);
      }
      const connectCandidate = Candidates.filter(candidate => candidate.PersonId !== undefined);
      const connectPersonIds = connectCandidate.map(candidate => candidate.PersonId);
      const aggregatedPersonIds = filterResponse.data.ConnectDetails.map(
        connectDetail => connectDetail.ConnectPersonId
      );
      const connectConversationIds = filterResponse.data.ConnectDetails.filter(
        connectDetail => connectDetail.ConversationId !== undefined
      ).map(connectDetail => connectDetail.ConversationId);

      batch(() => {
        dispatch({
          type: 'SET_CONNECT_STATUS_API_STATUS',
          payload: {
            status: null,
          },
        });
        dispatch(setCandidates(Candidates));
        dispatch({
          type: 'SET_CANDIDATE_IDS',
          payload: {
            ids: filterResponse.data.CandidateIds,
            jobId: filter.jobId,
          },
        });
        dispatch({
          type: 'SET_CANDIDATE_AGGS',
          payload: {
            aggs: filterResponse.data.Aggregations,
            jobId: filter.jobId,
          },
        });
        dispatch(setCandidateCount(filterResponse.data.Total));
        dispatch(setDilatedCandidateCount(filterResponse.data.DilatedTotal));
        dispatch({
          type: 'SET_CANDIDATE_CONNECT_DETAILS',
          payload: {
            connectDetails: filterResponse.data.ConnectDetails,
          },
        });
      });
      dispatch(fetchConversationStats(connectConversationIds, filter.jobId, defaultStatsFilter));
      dispatch(fetchSubscriptionStats(aggregatedPersonIds, filter.jobId, filter.jobGuid));
      dispatch(
        searchConversations({
          RefId: filter.jobGuid,
          PersonIds: currentPagePersonIds,
          Status: 'contacted',
        })
      );
      dispatch(
        fetchBulkUnReadConversationsCount({
          ConversationIds: currentPageConversationIds,
        })
      );
      dispatch(setCandidateListApiStatus('COMPLETED'));
      dispatch({
        type: 'SET_CONNECT_STATUS_API_STATUS',
        payload: {
          status: 'INPROGRESS',
        },
      });
      // TODO : Unnecessary API Calls onLoad
      if (connectPersonIds.length) {
        const connectStatusResponse = await connectRepo.fetchConnectStatuses(filter.jobGuid, connectPersonIds);
        dispatch({
          type: 'SET_CANDIDATE_CONNECT_INFO',
          payload: {
            connectInfo: {
              ...connectStatusResponse.data.ConnectStatuses,
            },
          },
        });

        dispatch({
          type: 'SET_CONNECT_STATUS_API_STATUS',
          payload: {
            status: 'COMPLETED',
          },
        });
      } else {
        // Todo: remove else condition
        dispatch({
          type: 'SET_CONNECT_STATUS_API_STATUS',
          payload: {
            status: 'COMPLETED',
          },
        });
      }
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          message: error,
          timeStamp: new Date(),
        },
      });
    }
  };
}

function setFetchCandidatesFlag(flag) {
  return {
    type: 'SET_FETCH_CANDIDATES_FLAG',
    payload: flag,
  };
}

export const setCandidateCountBySource = ({ candidateCountBySource, jobId }) => {
  return {
    type: 'SET_CANDIDATE_COUNT_BY_SOURCE',
    payload: {
      candidateCountBySource,
      jobId,
    },
  };
};

function setBulkContactStatus(CandidateIds) {
  return async dispatch => {
    const fetchBulkContactStatusResponse = await candidateRepo.fetchBulkEventStatus(CandidateIds, 'FetchContact');
    if (fetchBulkContactStatusResponse?.data) {
      const completedCandidateIds = [];
      const inProgressCandidateIds = [];
      CandidateIds.forEach(candidateId => {
        if (fetchBulkContactStatusResponse.data[candidateId] === 'InProgress') {
          inProgressCandidateIds.push(candidateId);
        }
        if (fetchBulkContactStatusResponse.data[candidateId] === 'Completed') {
          completedCandidateIds.push(candidateId);
        }
      });
      if (inProgressCandidateIds.length) {
        dispatch(setBulkFetchContactStatus(inProgressCandidateIds, 'InProgress'));
      }
      if (completedCandidateIds.length) {
        dispatch(setBulkFetchContactStatus(completedCandidateIds, 'Completed'));
      }
    }
  };
}

function fetchCandidateListNotes({ candidates, jobId }) {
  return dispatch => {
    const conversationIds = [];
    const candidateIdsByConversationId = {};
    const nonContactedCandidateIds = [];
    const candidateIds = [];
    candidates.forEach(candidate => {
      if (candidate.ConversationId) {
        candidateIdsByConversationId[candidate.ConversationId] = candidate.Id;
        conversationIds.push(candidate.ConversationId);
      } else {
        nonContactedCandidateIds.push(candidate.Id);
      }
      if (candidate.Id) candidateIds.push(candidate.Id);
    });

    const bulkCandidateNotesFilter = {
      jobId,
      candidateIds,
      from: 0,
      size: 0,
    };
    const bulkCallNotesFilter = {
      candidateIdsByConversationId,
      conversationIds,
      from: 0,
      size: 0,
    };
    dispatch(fetchBulkCandidateAllNotes(bulkCandidateNotesFilter, bulkCallNotesFilter, nonContactedCandidateIds));
  };
}

function fetchCandidateListConnectInfo({ jobGuid, connectPersonIds }) {
  return async dispatch => {
    const searchConversationsPayload = {
      RefId: jobGuid,
      PersonIds: connectPersonIds,
      Status: 'contacted',
    };
    dispatch(searchConversations(searchConversationsPayload));
    const connectStatusResponse = await connectRepo.fetchConnectStatuses(jobGuid, connectPersonIds);
    dispatch({
      type: 'SET_CANDIDATE_CONNECT_INFO',
      payload: {
        connectInfo: {
          ...connectStatusResponse.data.ConnectStatuses,
        },
      },
    });
  };
}

export function getManualSearchAllTabCandidates({ manualCriteria, jobId, store }) {
  const currentJobCandidates = store.ManualSearchCandidateReducer.ByJobId[jobId];
  const allTabCandidates = getFilteredAllTabCandidates({ store, jobId });
  const allTabTotalCandidatesCount = currentJobCandidates.AllTabTotalCandidatesCount;
  const candidatesById = currentJobCandidates.CandidatesById;
  const minIndex = manualCriteria.From;
  const maxIndex = manualCriteria.From + manualCriteria.Size;
  const currentAllTabCandidates = allTabCandidates.slice(minIndex, maxIndex);
  const candidates = currentAllTabCandidates.map(x => {
    return { ...candidatesById[x.Id], IsQuickSearchCandidate: true };
  });
  const allTabDuplicatesInfo = store?.ManualSearchCandidateReducer?.ByJobId?.[jobId]?.CandidateGroups;
  const groupHeads = candidates.map(candidate => {
    return getGroupHeadByCandidateId(allTabDuplicatesInfo, candidate.Id);
  });
  const uniqueGroupHeads = _.uniq(groupHeads);
  const groupHeadCandidates = uniqueGroupHeads.map((Id, index) => {
    return { ...candidatesById[Id], IsQuickSearchCandidate: true, candidateIndex: index };
  });
  const allCandidates = [...groupHeadCandidates, ...candidates];
  return { candidates: _.uniqBy(allCandidates, 'Id'), candidateCount: allTabTotalCandidatesCount };
}

const getManualSearchFetchedCandidateList = ({ currentSourcedCandidates, manualCriteria, jobId }) => {
  manualSearchRepository.fetchManualSearchCandidates({
    criteria: manualCriteria,
    from: manualCriteria.From, // invoking this method just to cancel the prev request,if any request is pending(this request does not make any call,jusr cancels the previous one)
    size: manualCriteria.Size,
    jobId,
    cancelPrev: true,
  });
  const maxIndex = manualCriteria.From + manualCriteria.Size;
  const allCandidates = currentSourcedCandidates.CandidatesIndexVsId;
  const allCurrentSourceCandidates = Object.keys(allCandidates).map(candidateId => {
    return allCandidates[candidateId];
  });
  const candidatesById = currentSourcedCandidates.CandidatesById;
  const candidateAggregations = currentSourcedCandidates.CandidateAggregations;
  const minIndex = manualCriteria.From;
  const currentActiveSourceCandidates = allCurrentSourceCandidates.filter(
    item => item.Index >= minIndex && item.Index < maxIndex
  );
  const candidates = currentActiveSourceCandidates.map(candidate => {
    return { ...candidatesById[candidate.Id], IsQuickSearchCandidate: true };
  });
  return { candidates, candidateAggregations };
};

const fetchManualSearchCandidatesForSource = async ({ manualCriteria, jobId }) => {
  const response = await manualSearchRepository.fetchManualSearchCandidates({
    criteria: manualCriteria,
    from: manualCriteria.From,
    size: manualCriteria.Size,
    jobId,
    abortIfDuplicated: true,
  });
  const responseData = _.get(response, ['data', 0], {});
  const candidateAggregations = responseData.Aggregations;
  const sourcedCandidates = responseData.Candidates || [];
  const candidateCount = responseData.Total || 0;
  const nextPaginatedQuery = responseData.NextPaginatedQuery;
  const candidateData = mergeCandidateData({ candidatesObject: sourcedCandidates, isQuickSearchCandidate: true });
  return { candidateData, candidateCount, candidateAggregations, nextPaginatedQuery, shouldSetCandidateCount: false };
};

const isAllCandidateIndicesIncluded = (fromIndex, toIndex, currentSourcedCandidatesIndices) => {
  let allIndicesInRangeIncluded = true;
  for (let i = fromIndex; i <= toIndex; i += 1) {
    if (!currentSourcedCandidatesIndices.includes(i)) {
      allIndicesInRangeIncluded = false;
      break;
    }
  }
  return allIndicesInRangeIncluded;
};

const tryFetchManualSearchCandidatesForSource = async ({
  manualCriteria,
  jobId,
  store,
  fetchRemoteCandidates,
  dispatch,
}) => {
  const source = getSourceName(manualCriteria.Sources[0]);
  const maxIndex = manualCriteria.From + manualCriteria.Size;
  const currentSourcedCandidates = store.ManualSearchCandidateReducer?.ByJobId[jobId]?.BySource?.[source];
  const currentSourcedNextPaginatedQuery =
    store.ManualSearchCandidateReducer?.ByJobId[jobId]?.BySource?.[source]?.PageMapping;
  const currentSourcedCandidatesCount = currentSourcedCandidates?.TotalCount;
  const fetchedCandidatesCount = currentSourcedCandidates?.FetchedCount;
  const currentSourcedCandidatesIndices = Object.values(currentSourcedCandidates.CandidatesIndexVsId).map(
    item => item.Index
  );

  const maxCandidatesIndexToCheck = Math.min(currentSourcedCandidatesCount, maxIndex); // to handle the case where,sourced candidates count is less than max index, in that case it won't find the max index in candidate indices
  const isNewCandidateSearchRequired = !isAllCandidateIndicesIncluded(
    manualCriteria.From,
    maxCandidatesIndexToCheck - 1,
    currentSourcedCandidatesIndices
  );

  const featureToggleList = getFeatureToggleList(store);
  const isShowAllClientCandidateStatus = featureToggleList.ShowAllClientCandidateStatus.IsEnabled;
  if (isShowAllClientCandidateStatus) manualCriteria.ShowAllClientCandidateStatus = true;
  if (isNewCandidateSearchRequired || fetchRemoteCandidates) {
    const { candidateData, candidateCount, candidateAggregations, nextPaginatedQuery, shouldSetCandidateCount } =
      await fetchManualSearchCandidatesForSource({
        manualCriteria,
        jobId,
      });
    dispatch(
      setCandidatesBySource({
        source,
        jobId,
        candidates: candidateData,
        candidateAggregations,
        startIndex: manualCriteria.From ?? 0,
      })
    );
    dispatch(
      setCandidatesFetchedCountBySource({
        source,
        jobId,
        count: manualCriteria.From + candidateData.length - fetchedCandidatesCount,
        isMore: true,
      })
    );
    return {
      candidates: candidateData,
      candidateCount,
      candidateAggregations,
      nextPaginatedQuery,
      shouldSetCandidateCount,
    };
  }
  const { candidates, candidateAggregations } = getManualSearchFetchedCandidateList({
    currentSourcedCandidates,
    manualCriteria,
    jobId,
  });
  return {
    candidates,
    candidateCount: currentSourcedCandidatesCount,
    candidateAggregations,
    nextPaginatedQuery: currentSourcedNextPaginatedQuery,
  };
};

async function tryFetchManualSearchCandidates({
  manualCriteria,
  jobId,
  store,
  fetchRemoteCandidates,
  dispatch,
  smartCandidateAgent,
}) {
  const sourceName = getSourceName(manualCriteria.Sources[0]);
  if (sourceName === 'All') {
    const { candidates, candidateCount } = getManualSearchAllTabCandidates({ manualCriteria, jobId, store });
    return { candidates, candidateCount };
  }
  const { candidates, candidateCount, candidateAggregations, nextPaginatedQuery, shouldSetCandidateCount } =
    await tryFetchManualSearchCandidatesForSource({
      manualCriteria,
      jobId,
      store,
      fetchRemoteCandidates,
      dispatch,
      smartCandidateAgent,
    });
  return { candidates, candidateCount, candidateAggregations, nextPaginatedQuery, shouldSetCandidateCount };
}

function fetchCandidateJobGlobalTags({ jobId, candidateIds }) {
  return async dispatch => {
    dispatch(setFetchCandidateJobGlobalTagsApiStatus('INPROGRESS'));
    try {
      if (candidateIds.length > 0) {
        const response = await candidateRepo.fetchCandidateTags({ jobId, candidateIds, getGlobalTags: true });
        dispatch(setCandidateJobGlobalTags({ jobId, tags: response.data, candidateIds }));
      }
      dispatch(setFetchCandidateJobGlobalTagsApiStatus('COMPLETED'));
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          message: err,
          timeStamp: new Date(),
        },
      });
      dispatch(setFetchCandidateJobGlobalTagsApiStatus('FAILED'));
    }
  };
}

export const fetchBulkCandidateViewStatus = (
  candidateIds,
  jobGuid,
  showOnlyCurrentUserViewedStatus,
  isSingleCandidateViewFetchCall,
  showLoader = true
) => {
  return async dispatch => {
    if (!isSingleCandidateViewFetchCall && showLoader) dispatch(setCandidateViewedApiStatus('INPROGRESS'));
    try {
      if (candidateIds.length > 0) {
        const filter = {
          CandidateIds: candidateIds,
          JobGuid: jobGuid,
          showOnlyCurrentUserViewedStatus,
        };
        const response = await candidateRepo.fetchCandidateLastViewedStatus(filter);
        const viewStatusByCandidateId = _.get(response, ['data', 'CandidateViewStatus'], {});
        if (isSingleCandidateViewFetchCall)
          dispatch(setCandidateViewStatusV2(candidateIds[0], viewStatusByCandidateId));
        else dispatch(setBulkCandidateViewStatus(candidateIds, viewStatusByCandidateId));
      }
      if (!isSingleCandidateViewFetchCall && showLoader) dispatch(setCandidateViewedApiStatus('COMPLETED'));
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          message: err,
          timeStamp: new Date(),
        },
      });
      if (!isSingleCandidateViewFetchCall && showLoader) dispatch(setCandidateViewedApiStatus('FAILED'));
    }
  };
};

function fetchManualSearchCandidateList({
  manualCriteria,
  jobId,
  jobGuid,
  isBulkNotesFetch,
  atsFetchBulkNotes,
  atsJobId,
  isBulkTagFetch = false,
  candidateContext = 'job',
  isAllTabCandidateFetchRequestAllowed,
  fetchRemoteDataOnly,
  setPrevCandidateCallback,
}) {
  return async (dispatch, getState) => {
    const store = getState();
    const source = getSourceName(manualCriteria.Sources[0]);
    const isPortalQueryActive = !_.isEmpty(manualCriteria?.PortalQueries?.[source]);
    const fetchRemoteCandidates = fetchRemoteDataOnly || isPortalQueryActive;
    const featureToggleList = getFeatureToggleList(store);
    const {
      NotesIndicator: { IsEnabled: isNotesIndicatorEnabled },
    } = featureToggleList;

    try {
      batch(() => {
        dispatch(setCandidateListError());
        dispatch(setCandidateListApiStatus('INPROGRESS'));
        dispatch(setConnectStatusApiStatus('INPROGRESS'));
        dispatch(setCandidates([]));
        dispatch(setCandidateCount(0));
        dispatch(setCandidateRecommendationSource('AdvanceSearch'));
      });
      await new Promise(resolve => setTimeout(resolve, 0));
      const {
        candidates,
        candidateCount,
        candidateAggregations,
        nextPaginatedQuery,
        shouldSetCandidateCount = true,
      } = await tryFetchManualSearchCandidates({
        manualCriteria,
        jobId,
        store,
        candidateContext,
        isAllTabCandidateFetchRequestAllowed,
        fetchRemoteCandidates,
        dispatch,
      });

      if (setPrevCandidateCallback) setPrevCandidateCallback(candidates);
      const sourceName = getSourceName(manualCriteria.Sources[0]);
      const candidateListType = getCandidateListType(getState());
      if (candidateListType?.toLowerCase() !== 'manualsearchcandidatelist') {
        return;
      }
      const nextFrom = nextPaginatedQuery?.From;
      const currentPage = store.JobCandidatesTabReducer.currPage;
      if (isExactMatchSearch(manualCriteria) && nextFrom)
        dispatch(
          setManualSearchNextPaginatedQueryBySources({
            jobId,
            source,
            nextFrom,
            currentPage,
          })
        );
      batch(() => {
        if (candidates.length && manualCriteria.page) {
          dispatch({
            type: 'SET_LATEST_CANDIDATE_SEARCH_SUCCESS_PAGE',
            payload: { source, jobId, page: manualCriteria?.page },
          });
        }
        const existingCandidateAggregations = getCandidatesAggregations(store);
        if (!_.isEmpty(existingCandidateAggregations) && _.isEmpty(candidateAggregations)) {
          dispatch(setCandidateAggregations(undefined));
        }
        if (candidateAggregations) {
          dispatch(setCandidateAggregations(candidateAggregations));
        }
        const isDeDuplicationAllowedForSource = allowedDeDuplicationSources
          .filter(activeSource => activeSource !== 'All')
          .includes(sourceName);
        if (isDeDuplicationAllowedForSource) {
          dispatch(groupDuplicatesBySource({ candidates, jobId, source, shouldSetCandidates: true }));
        } else dispatch(setCandidates(candidates));
        const candidateIds = candidates.map(candidate => candidate.Id).filter(candidateId => candidateId);
        dispatch(fetchBulkCandidateViewStatus(candidateIds, jobGuid, false));
        if (shouldSetCandidateCount);
        dispatch(setCandidateCount(candidateCount));
        dispatch(setCandidateListApiStatus('COMPLETED'));
      });
      const connectPersonIds = candidates
        .filter(candidate => candidate.PersonId !== undefined)
        .map(candidate => candidate.PersonId);
      if (isNotesIndicatorEnabled && isBulkNotesFetch) {
        dispatch(fetchCandidateListNotes({ candidates, jobId }));
      } else if (!isBulkNotesFetch && atsFetchBulkNotes) {
        dispatch(atsFetchBulkNotes(jobId, atsJobId, candidates));
      }
      if (isBulkTagFetch) {
        const candidateIds = candidates.map(candidate => candidate.Id).filter(candidateId => candidateId);
        dispatch(fetchCandidateJobGlobalTags({ jobId, candidateIds }));
      }
      if (connectPersonIds.length) {
        await dispatch(fetchCandidateListConnectInfo({ jobGuid, connectPersonIds }));
      }
      batch(() => {
        if (shouldSetCandidateCount) dispatch(setCandidatesCountBySource({ source, jobId, count: candidateCount }));
        dispatch(setConnectStatusApiStatus('COMPLETED'));
      });
    } catch (errorResponse) {
      const isRequestCancelled = errorResponse.message === 'Request Cancelled';
      if (!isRequestCancelled) {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: errorResponse,
            timeStamp: new Date(),
          },
        });
        const error = mapCandidateListApiErrorCodes(errorResponse);
        dispatch(setCandidateListError(error));
        dispatch(setCandidateListApiStatus('FAILED'));
      }
    }
  };
}

function fetchCandidateGlobalTags({ candidateIds }) {
  return async dispatch => {
    dispatch(setFetchCandidateGlobalTagsApiStatus('INPROGRESS'));
    try {
      const response = await candidateRepo.fetchCandidateGlobalTags({ candidateIds });
      batch(() => {
        dispatch(setCandidateTags({ tags: response.data, candidateIds }));
        dispatch(setFetchCandidateGlobalTagsApiStatus('COMPLETED'));
      });
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          message: err,
          timeStamp: new Date(),
        },
      });
      dispatch(setFetchCandidateGlobalTagsApiStatus('FAILED'));
    }
  };
}

function deleteTagForCandidate({ tag, candidateId, jobId }) {
  return async dispatch => {
    dispatch(setTagDeleteApiStatus({ [tag?.AssociationId]: 'INPROGRESS' }));
    try {
      await tagRepository.deleteTag({ tagAssociationId: tag?.AssociationId });
      batch(() => {
        dispatch(removeCandidateTag({ candidateId, tagAssociationId: tag?.AssociationId }));
        dispatch(removeCandidateJobGlobalTag({ candidateId, jobId, tagAssociationId: tag?.AssociationId }));
        dispatch(setTagDeleteApiStatus({ [tag?.AssociationId]: 'COMPLETED' }));
        dispatch(
          setNotification(
            'SUCCESS',
            {
              messageId: 'tagDeletedSuccessfully',
            },
            null,
            2
          )
        );
      });
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          message: err,
          timeStamp: new Date(),
        },
      });
      dispatch(setTagDeleteApiStatus({ [tag?.AssociationId]: 'FAILED' }));
      dispatch(
        setNotification('ERROR', {
          messageId: 'oopsSomethingJustWentWrong',
        })
      );
    }
  };
}

function createCandidateTag({ candidateId, jobId, tag }) {
  return async dispatch => {
    dispatch(setFetchCandidateJobGlobalTagsApiStatus('INPROGRESS'));
    dispatch(clearCandidateSuggestedTags());
    try {
      const response = await tagRepository.createCandidateTags({ candidateId, jobId, tags: [tag] });
      batch(() => {
        dispatch(_createCandidateTag({ candidateId, jobId, tags: response.data.Tags }));
        dispatch(createCandidateJobGlobalTag({ candidateId, jobId, tags: response.data.Tags }));
        dispatch(
          setNotification('SUCCESS', {
            messageId: 'tagForCandidateAddedSuccessfully',
          })
        );
        dispatch(setFetchCandidateJobGlobalTagsApiStatus('COMPLETED'));
      });
    } catch (err) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          message: err,
          timeStamp: new Date(),
        },
      });
      dispatch(
        setNotification('ERROR', {
          messageId: 'oopsSomethingJustWentWrong',
        })
      );
      dispatch(setFetchCandidateJobGlobalTagsApiStatus('FAILED'));
    }
  };
}

function fetchAggregations(
  candidateListFilter,
  shouldSetSourceFilterCount,
  isBulkContactFetchEnabled,
  jobId,
  showLoader = true,
  shouldAppendCandidateCount,
  isFilterApplied,
  invokationContext
) {
  return async (dispatch, getState) => {
    if (showLoader) dispatch(setCandidateAggregationsApiStatus('INPROGRESS'));
    dispatch(setApiStatus({ apiName: 'communicationStatsStatus', status: 'INPROGRESS' }));
    const filterResponse = await candidateRepo.candidateAggregationFilters(candidateListFilter);
    const connectConversationIds = filterResponse?.data?.ConnectDetails.filter(
      connectDetail => connectDetail?.ConversationId !== undefined
    ).map(connectDetail => connectDetail?.ConversationId);
    const aggregatedPersonIds = _.get(filterResponse, ['data', 'ConnectDetails'], []).map(
      connectDetail => connectDetail.ConnectPersonId
    );
    if (isBulkContactFetchEnabled) {
      await dispatch(setBulkContactStatus(filterResponse.data.CandidateIds));
    }
    const store = getState();
    const featureToggleList = getFeatureToggleList(store);
    const isMessageFiltersAllowed = featureToggleList.SMSFiltersForJob.IsEnabled;
    const defaultStatsFilter = getDefaultStatsFilter(isMessageFiltersAllowed);
    batch(() => {
      dispatch({
        type: 'SET_CANDIDATE_IDS',
        payload: {
          ids: filterResponse.data.CandidateIds,
          jobId,
        },
      });
      dispatch({
        type: 'SET_CANDIDATE_AGGS',
        payload: {
          aggs: filterResponse.data.Aggregations,
          jobId,
        },
      });
      dispatch({
        type: 'SET_CANDIDATE_CONNECT_DETAILS',
        payload: {
          connectDetails: filterResponse.data.ConnectDetails,
        },
      });
      dispatch({
        type: 'SET_REJECTED_CANDIDATE_IDS',
        payload: {
          rejectedCandidateIds: filterResponse.data.RejectedCandidateIds,
        },
      });
      const candidateCountBySource = getCandidateCountBySources(
        _.get(filterResponse, ['data', 'Aggregations', 'Source'], [])
      );
      if (shouldSetSourceFilterCount || isFilterApplied) {
        dispatch({
          type: 'SET_CANDIDATE_COUNT_BY_SOURCE',
          payload: {
            candidateCountBySource,
            jobId,
            shouldAppendCandidateCount,
          },
        });
      }
      if (invokationContext === 'defaultLoad')
        dispatch({
          type: 'SET_INITIAL_CANDIDATE_COUNT_BY_SOURCE',
          payload: {
            initialCandidateCountBySource: candidateCountBySource,
            jobId,
            shouldAppendCandidateCount,
          },
        });
      if (showLoader) dispatch(setCandidateAggregationsApiStatus('COMPLETED'));
    });
    await dispatch(fetchConversationStats(connectConversationIds, jobId, defaultStatsFilter));
    await dispatch(fetchSubscriptionStats(aggregatedPersonIds, jobId, candidateListFilter.jobGuid));
    dispatch(setApiStatus({ apiName: 'communicationStatsStatus', status: 'COMPLETED' }));
  };
}

function fetchCandidateList(
  filter = {},
  isQuickSearchCandidate = false,
  isBulkNotesFetch,
  showLoader = false,
  fetchType,
  isFilterModified = false,
  atsFetchBulkNotes,
  atsJobId,
  isBulkTagFetch = false,
  candidateContext = 'job',
  isCurrentCandidateViewedFlag,
  currentCandidateViewedFormValue
) {
  return async (dispatch, getState) => {
    const store = getState();
    const source = getSourceName(filter.candidateFilter?.Sources[0]);
    let response;
    let conversationIds;
    const candidateListFilter = filter;
    batch(() => {
      dispatch(setCandidateListApiStatus(showLoader ? 'INPROGRESS' : null));
      dispatch(setCandidateListError());
      if (candidateContext !== 'segment') dispatch(setCandidateCount(0));
    });
    const featureToggleList = getFeatureToggleList(store);
    const subscriptionStats = getSubscriptionStats(store);
    const subscriptionStatuses = getFilteredStatusesArray(filter.EmailStats, 'SubscriptionStats');
    const {
      NotesIndicator: { IsEnabled: isNotesIndicatorEnabled },
    } = featureToggleList;
    const personIds = getAggregatedPersonIds(subscriptionStats, subscriptionStatuses);
    const isAdvancedRejectReasonsEnabled = true;
    const showOnlyCurrentUserViewedStatus = isCurrentCandidateViewedFlag
      ? currentCandidateViewedFormValue
      : !!store.JobReducer.ById[filter.jobId]?.ShowCurrentUserViewedStatus;
    candidateListFilter.ConversationIds = await fetchConversationIds(filter, candidateContext);
    candidateListFilter.personIds = personIds;
    const { EmailStats: emailStats = [] } = filter || {};
    const hasUnsentEmailOrMessage = emailStats.includes('Email_NotSent') || emailStats.includes('Message_NotSent');
    candidateListFilter.HasConversation = !hasUnsentEmailOrMessage;
    if (candidateListFilter.EmailStats?.length && candidateContext === 'segment') {
      candidateListFilter.EmailStatus = candidateListFilter.EmailStats;
    }
    delete candidateListFilter.EmailStats;

    (candidateListFilter.Sources || []).map(portal => {
      const refinedPortal = portal;
      delete refinedPortal.Name;
      return refinedPortal;
    });
    try {
      let candidatesObject;
      let responseData;
      let candidateCount;
      let filterContextId;
      let candidateListType;
      let sampleCandidateCount;
      if (isQuickSearchCandidate) {
        response = await quickSearchRepository.quickSearch(filter, isAdvancedRejectReasonsEnabled);
        candidateListType = getCandidateListType(getState());
        if (candidateListType?.toLowerCase() !== 'quicksearchcandidatelist') {
          return;
        }
        responseData = _.get(response, ['data', 0], {});
        dispatch(setCandidateRecommendationSource(filter.recommendationSource));
        candidatesObject = responseData.Candidates || [];
        candidateCount = responseData.Total || 0;
        const existingCandidateAggregations = getCandidatesAggregations(store);
        const candidateAggregations = responseData.Aggregations;
        if (!_.isEmpty(existingCandidateAggregations) && _.isEmpty(candidateAggregations)) {
          dispatch(setCandidateAggregations(undefined));
        }
        if (candidateAggregations) {
          dispatch(setCandidateAggregations(candidateAggregations));
        }
      } else {
        const isShowAllClientCandidateStatus = featureToggleList.ShowAllClientCandidateStatus.IsEnabled;
        if (isShowAllClientCandidateStatus) candidateListFilter.ShowAllClientCandidateStatus = true;
        response = await candidateRepo.candidateSearch(candidateListFilter, isAdvancedRejectReasonsEnabled);
        candidateListType = getCandidateListType(getState());
        if (candidateListType?.toLowerCase() !== 'sourcedcandidatelist') {
          return;
        }
        candidatesObject = response.data.Candidates;
        candidateCount = response.data.Total;
        sampleCandidateCount = response.data?.SampleCandidateCount;
        filterContextId = response.data?.FilterContextId;
        dispatch(setSampleCandidateCount(sampleCandidateCount));
        dispatch(setFilterContextId(filterContextId));
        const dilatedCandidateStartCount = response.data.Total - response.data.DilatedTotal + 1;
        const filteredCandidateListStartCount = candidateListFilter.From || 0;
        dispatch(setCandidateRecommendationSource(undefined));
        if (
          response.data.DilatedTotal > 0 &&
          dilatedCandidateStartCount > filteredCandidateListStartCount &&
          dilatedCandidateStartCount <= filteredCandidateListStartCount + candidateListFilter.Size
        ) {
          await dispatch(fetchJobDilatedIntel(candidateListFilter.jobId, ['UserAccepted']));
        }
      }
      const Candidates = mergeCandidateData({ candidatesObject, isQuickSearchCandidate });
      const candidateIds = Candidates.map(candidate => candidate.Id).filter(candidateId => candidateId);
      dispatch(
        fetchBulkCandidateViewStatus(candidateIds, filter.jobGuid, showOnlyCurrentUserViewedStatus, false, showLoader)
      );
      dispatch(fetchJobSourcingStats([candidateListFilter.jobId]));
      const connectCandidate = Candidates.filter(candidate => candidate.PersonId !== undefined);
      const connectPersonIds = connectCandidate.map(candidate => candidate.PersonId);
      const candidateIdsByConversationId = {};
      const nonContactedCandidateIds = [];
      dispatch({
        type: 'SET_CONNECT_STATUS_API_STATUS',
        payload: {
          status: null,
        },
      });
      dispatch(setCandidates(Candidates));
      if (isNotesIndicatorEnabled && isBulkNotesFetch) {
        conversationIds = [];
        Candidates.forEach(candidate => {
          if (candidate.ConversationId) {
            candidateIdsByConversationId[candidate.ConversationId] = candidate.Id;
            conversationIds.push(candidate.ConversationId);
          } else {
            nonContactedCandidateIds.push(candidate.Id);
          }
        });
        const candidateIds = Candidates.map(candidate => candidate.Id).filter(candidateId => candidateId);
        const bulkCandidateNotesFilter = {
          jobId: filter.jobId,
          candidateIds,
          from: 0,
          size: 0,
        };
        const bulkCallNotesFilter = {
          candidateIdsByConversationId,
          conversationIds,
          from: 0,
          size: 0,
        };
        dispatch(fetchBulkCandidateAllNotes(bulkCandidateNotesFilter, bulkCallNotesFilter, nonContactedCandidateIds));
      } else if (isBulkNotesFetch === false && atsFetchBulkNotes) {
        dispatch(atsFetchBulkNotes(filter.jobId, atsJobId, Candidates));
      }
      if (isBulkTagFetch) {
        const candidateIds = Candidates.map(candidate => candidate.Id).filter(candidateId => candidateId);
        dispatch(fetchCandidateJobGlobalTags({ jobId: filter.jobId, candidateIds }));
      }
      batch(() => {
        dispatch(setCandidateCount(candidateCount));
        dispatch(setDilatedCandidateCount(response.data.DilatedTotal));
        dispatch(setCandidateListApiStatus('COMPLETED'));
        if (showLoader)
          dispatch({
            type: 'SET_CONNECT_STATUS_API_STATUS',
            payload: {
              status: 'INPROGRESS',
            },
          });
      });
      if (connectPersonIds.length) {
        const searchConversationsPayload = {
          RefId: filter.jobGuid,
          PersonIds: connectPersonIds,
          Status: 'contacted',
        };
        dispatch(searchConversations(searchConversationsPayload));
        connectRepo.fetchConnectStatuses(filter.jobGuid, connectPersonIds).then(connectStatusResponse => {
          batch(() => {
            dispatch({
              type: 'SET_CANDIDATE_CONNECT_INFO',
              payload: {
                connectInfo: {
                  ...connectStatusResponse.data.ConnectStatuses,
                },
              },
            });
            if (showLoader)
              dispatch({
                type: 'SET_CONNECT_STATUS_API_STATUS',
                payload: {
                  status: 'COMPLETED',
                },
              });
          });
        });
      } else {
        dispatch({
          type: 'SET_CONNECT_STATUS_API_STATUS',
          payload: {
            status: 'COMPLETED',
          },
        });
      }
      if (Candidates.length && filter.page) {
        dispatch({
          type: 'SET_LATEST_CANDIDATE_SEARCH_SUCCESS_PAGE',
          payload: { source, jobId: filter.jobId, page: filter?.page },
        });
      }
    } catch (errorResponse) {
      const isRequestCancelled = errorResponse.message === 'Request Cancelled';
      if (!isRequestCancelled) {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: errorResponse,
            timeStamp: new Date(),
          },
        });
        dispatch(
          setNotification('ERROR', {
            messageId: 'oopsSomethingJustWentWrong',
          })
        );
        console.log({ errorResponse });
        const error = mapCandidateListApiErrorCodes(errorResponse);
        dispatch(setCandidateListError(error));
        dispatch(setCandidateListApiStatus('FAILED'));
        dispatch(setApiStatus({ apiName: 'communicationStatsStatus', status: 'FAILED' }));
      }
    }
  };
}

function changeCandidateViewStatus(viewStatus) {
  return (dispatch, getState) => {
    candidateRepo
      .changeCandidateStatus(viewStatus, true)
      .then(() => {
        dispatch(setCandidateViewStatus(viewStatus));
      })
      .catch(error => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: error,
            timeStamp: new Date(),
          },
        });
      });
  };
}

function fetchCandidateDetails(
  filter,
  pushCandInfo,
  isQuickSearchCandidate,
  showLoader = true,
  setCandidateViewStatusFlag = true,
  allowViewedStatusUpdate = true
) {
  return async (dispatch, getState) => {
    const currentState = getState();
    const jobGuid = _.get(currentState, ['JobReducer', 'ById', filter.jobId], {}).JobGuid;
    const aryaVersion = _.get(getConfig(currentState), ['SubscriptionType'], null);
    const candidateData = {
      Message: null,
      ErrorDescription: null,
      jobId: filter.jobId,
      candidateId: filter.candidateId,
    };
    const candidatePersonId = _.get(currentState, ['CandidateReducer', 'ById', filter.candidateId], {}).PersonId;
    const candidateContactInfo = _.get(
      currentState,
      ['ConnectReducer', 'ConnectStatuses', candidatePersonId, 'Contact'],
      {}
    );
    const isCandidateContactAvailable = isValidContact(candidateContactInfo);
    const availableProviders = candidateContactInfo.AvailableProviders;
    const showLoadingIcon = !isCandidateContactAvailable && availableProviders !== 0;

    if (showLoader) {
      dispatch(setCandidateAllDetailsApiStatus('INPROGRESS'));
    }
    dispatch(setCandidateAllDetailsError(candidateData));
    if (showLoadingIcon) {
      dispatch(setFetchContactStatus(filter.candidateId, 'InProgress', filter.jobId));
    }
    let candidateSourceName;
    try {
      const response = await getMergedCandidateDetails(
        {
          ...filter,
          Country: pushCandInfo?.Country,
        },
        isQuickSearchCandidate
      );
      let candidateObject = {};
      try {
        let candidateTextResponse = response.request.responseText;
        candidateTextResponse = candidateTextResponse.replace(/"Score":(\d+\.\d+)/g, '"Score":"$1"');
        candidateObject = JSON.parse(candidateTextResponse);
      } catch {
        candidateObject = response;
      }
      const featureToggleList = getFeatureToggleList(currentState);
      const isAllTabAggregationFilterEnabled = featureToggleList.AdvancedSearchAggregationFilters.IsEnabled;
      if (isQuickSearchCandidate && isAllTabAggregationFilterEnabled) {
        const { aggregation, preferTitles, manualSearchSkills } = getAllTabCandidatesAggregation(
          getState,
          filter.jobId,
          [],
          candidateObject
        );
        dispatch(setAllTabAggregationFilters({ jobId: filter.jobId, aggregation, preferTitles, manualSearchSkills }));
      }
      dispatch({
        type: 'SET_CURRENT_CANDIDATE_ID',
        payload: filter.candidateId,
      });
      const candidateSource = getCandidateSource(candidateObject);
      candidateSourceName = getSourceName(candidateSource);
      const personId = _.get(candidateObject, ['PersonId']);
      const pullContactStatus = _.get(
        getState().ConnectReducer,
        ['ConnectStatuses', personId, 'Contact', 'RequestStatus'],
        false
      );
      if (
        aryaVersion === 'Lite' &&
        ['social', 'indeed'].includes(candidateSourceName && candidateSourceName.toLowerCase()) &&
        !pullContactStatus
      ) {
        dispatch(setFetchContactStatus(filter.candidateId, 'InProgress', filter.jobId));
      }
      dispatch(setCandidateAllDetails(candidateObject));
      const { Name: candidateName, Educations: candidateEducations, Id: candidateId } = candidateObject;
      dispatch({
        type: 'SET_CANDIDATE_NAME_AND_EDUCATION',
        payload: { candidateEducations, candidateName, candidateId },
      });
      dispatch(
        setCandidateDownloadedDetails(
          candidateObject.Id,
          candidateObject.IsDownloaded,
          candidateObject.IsUnlocked,
          filter.jobId
        )
      );
      if (
        (!candidateObject.PersonId || !candidateObject.ConversationId) &&
        pushCandInfo &&
        pushCandInfo.Country &&
        pushCandInfo.refId
      ) {
        dispatch(
          pushCandidateToConnect(
            filter.jobId,
            {
              ...candidateObject,
              Country: pushCandInfo.Country,
            },
            pushCandInfo.refId
          )
        );
      }
      if (candidateObject.PersonId)
        dispatch(addCandidateContactInfo(candidateObject, pushCandInfo, null, filter.jobId));
      if (filter.isFetchBulkUnreadConversationsCount && candidateObject.ConversationId) {
        const fetchBulkConversationStatsPayload = {
          ConversationIds: [candidateObject.ConversationId],
        };
        dispatch(fetchBulkUnReadConversationsCount(fetchBulkConversationStatsPayload));
      }

      if (showLoader) {
        dispatch(setCandidateAllDetailsApiStatus('COMPLETED'));
      }
      if (showLoadingIcon) {
        dispatch(setFetchContactStatus(filter.candidateId, 'Completed', filter.jobId));
      }
      const store = getState();
      const candidateViewPayload = {
        jobGuid,
        candidateId: filter.candidateId,
        jobId: filter.jobId,
        recommendationSource: isQuickSearchCandidate ? 'AdvanceSearch' : 'AryaIntel',
      };
      if (candidateViewPayload.recommendationSource === 'AdvanceSearch') {
        candidateViewPayload.recordId = store.ManualSearchReducer?.ByJobId?.[filter.jobId]?.ManualSearchCurrentRecordId;
        candidateViewPayload.searchCriteriaType =
          store.ManualSearchReducer?.ByJobId?.[filter.jobId]?.ManualSearchCurrentCriteriaType;
      }
      if (allowViewedStatusUpdate) {
        dispatch(updateCandidateViewStatus(candidateViewPayload));
      }
      if (allowViewedStatusUpdate && setCandidateViewStatusFlag && !isQuickSearchCandidate) {
        dispatch(
          changeCandidateViewStatus({
            isViewed: true,
            jobId: filter.jobId,
            source: candidateSource,
            candidateId: filter.candidateId,
          })
        );
      }
      const isJobUsageBudgetFeatureEnabled = featureToggleList.JobUsageBudget.IsEnabled;
      if (isJobUsageBudgetFeatureEnabled && filter.jobId) dispatch(fetchJobUsageAvailableBudget(filter.jobId));
      dispatch(setCandidateAllDetailsError(candidateData));
      return response;
    } catch (error) {
      let errorData = mapErrorCodes(error);
      errorData = {
        ...errorData,
        jobId: filter.jobId,
        candidateId: filter.candidateId,
      };
      if (errorData) dispatch(setCandidateAllDetailsError(errorData));
      dispatch({
        type: 'SET_ERROR',
        payload: {
          message: error,
          timeStamp: new Date(),
        },
      });
      if (showLoader) {
        dispatch(setCandidateAllDetailsApiStatus('FAILED'));
      }
      if (showLoadingIcon) {
        dispatch(setFetchContactStatus(filter.candidateId, 'Failed', filter.jobId));
      }
      if (
        aryaVersion === 'Lite' &&
        ['social', 'indeed'].includes(candidateSourceName && candidateSourceName.toLowerCase())
      ) {
        dispatch(setFetchContactStatus(filter.candidateId, 'Failed', filter.jobId));
      }
      return error;
    }
  };
}

function changeCandidateStatus(
  candidate,
  callback,
  isQuickSearchCandidate,
  candidateContext = 'job',
  activeTab = 'rejected',
  shouldUnmaskCandidate = true
) {
  const { version } = candidate;
  let actionMessage;
  switch (candidateContext) {
    case 'job':
      switch (candidate?.status) {
        case 'Shortlisted':
          actionMessage = 'shortlisted';
          break;
        case 'Rejected':
          actionMessage = 'rejected';
          break;
        default:
      }
      break;
    case 'segment':
      switch (candidate?.status || activeTab) {
        case 'Shortlisted':
        case 'rejected':
          actionMessage = 'added';
          break;
        case 'Rejected':
        case 'shortlisted':
          actionMessage = 'removed';
          break;
        default:
      }
      break;
    default:
  }
  return async (dispatch, getState) => {
    const store = getState();
    const featureToggleList = getFeatureToggleList(store);
    const maskingConfig = getMaskingConfig(store);
    const recommendationSource = getCandidateRecommendationSource(store);
    const currentJobDetails = getJobDetails(store, candidate.jobId);
    const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);

    if (candidate.includeSimilar) {
      const message = candidate.status === 'Rejected' ? `rejectingSimilarCandidates` : `unRejectingSimilarCandidates`;
      dispatch(
        setNotification('LOADING', {
          messageId: message,
          mergeTags: { jobTitle: candidate.jobTitle },
        })
      );
    }
    dispatch({
      type: 'UPDATE_CANDIDATE_STATUS_API_STATUS',
      payload: {
        status: 'INPROGRESS',
        candidateId: candidate.candidateId,
        candidateStatus: candidate.status,
        connectionStatus: candidate.connectionStatus,
        jobId: candidate.jobId,
      },
    });
    try {
      let candidateStatus;
      candidateStatus = candidate.status || 'Sourced';
      if (candidate.addToApplied) candidateStatus = candidate.status;
      if (isQuickSearchCandidate) {
        const changeCandidateStatusPayload = {
          CandidateId: candidate.candidateId,
          RecommendedInfo: {
            Status: candidateStatus,
            SourceConfigId: candidate.sourceConfigId,
            SourceGroupId: candidate.sourceGroupId,
            MustHaves: candidate.mustHaves,
            StatusReasons: candidate.reasons,
            RecommendationSource: recommendationSource,
            RecordId: store.ManualSearchReducer?.ByJobId?.[candidate.jobId]?.ManualSearchCurrentRecordId,
            SearchCriteriaType: store.ManualSearchReducer?.ByJobId?.[candidate.jobId]?.ManualSearchCurrentCriteriaType,
          },
        };
        if (candidate.selectedSubsegmentId) {
          changeCandidateStatusPayload.RecommendedInfo.SubsegmentIds = [parseInt(candidate.selectedSubsegmentId, 10)];
        }
        if (candidate.subsegmentId) {
          changeCandidateStatusPayload.RecommendedInfo.SubsegmentIds = [candidate.subsegmentId];
        }
        changeCandidateStatusPayload.Metadata = candidate.metadata;
        if (maskingConfig?.IsNameMasked && changeCandidateStatusPayload.Metadata) {
          delete changeCandidateStatusPayload.Metadata.Name;
        }
        if (maskingConfig?.IsUniversityMasked && changeCandidateStatusPayload.Metadata) {
          delete changeCandidateStatusPayload.Metadata.Educations;
        }
        if (candidate.connectionStatus) {
          changeCandidateStatusPayload.RecommendedInfo.ConnectionStatus = candidate.connectionStatus;
        }
        if (candidate.candidateOrigin) {
          changeCandidateStatusPayload.RecommendedInfo.CandidateOrigin = candidate.candidateOrigin;
        }
        await quickSearchRepository.changeCandidateRecommendedStatus(candidate.jobId, changeCandidateStatusPayload);
        const isJobUsageBudgetFeatureEnabled = featureToggleList.JobUsageBudget.IsEnabled;
        if (isJobUsageBudgetFeatureEnabled) dispatch(fetchJobUsageAvailableBudget(candidate.jobId));

        if (maskingConfig?.IsActive && candidate?.status === 'Shortlisted') {
          dispatch(
            fetchCandidateDetails(
              { jobId: candidate.jobId, candidateId: candidate.candidateId },
              { refId: currentJobDetails.JobGuid, Country: currentJobDetails.CountryCode },
              false,
              false,
              true,
              false
            )
          );
        }
      } else if (candidate.isPublished && isPaidJobServiceEnabled) {
        await candidateRepo.updatePublishedCandidateStatus({
          jobId: candidate.jobId,
          candidateId: candidate.candidateId,
          status: candidateStatus,
          statusReasons: candidate.reasons,
          mustHaves: candidate.mustHaves,
        });
      } else {
        await candidateRepo.changeCandidateStatus(candidate);
        if (maskingConfig?.IsActive && shouldUnmaskCandidate) {
          dispatch(
            fetchCandidateDetails(
              { jobId: candidate.jobId, candidateId: candidate.candidateId },
              { refId: currentJobDetails.JobGuid, Country: currentJobDetails.CountryCode }
            )
          );
        }
      }
      const currentJobId = getState()?.CandidateReducer?.ById?.[candidate.candidateId]?.jobId ?? candidate.jobId;
      if (currentJobId === candidate.jobId) {
        dispatch(
          setCandidateStatus(
            candidate.candidateId,
            candidateStatus,
            isQuickSearchCandidate,
            version,
            candidate.isPublished,
            candidate.jobId
          )
        );
      }
      dispatch({
        type: 'UPDATE_CANDIDATE_STATUS_API_STATUS',
        payload: {
          status: 'COMPLETED',
          candidateId: candidate.candidateId,
          candidateStatus: candidate.status,
          connectionStatus: candidate.connectionStatus,
          jobId: candidate.jobId,
        },
      });
      if (candidate.status === 'Rejected') {
        const mustHaves = candidate.reasons?.find(statusReason => statusReason.Name === 'MISSING_MANDATORY_SKILLS')
          ?.AdditionalDetails?.MissingSkills;

        if (mustHaves) {
          dispatch(addMustHavesToJob(candidate.jobId, mustHaves));
        }
      }

      const experienceMismatchReason = candidate.reasons?.find(
        statusReason => statusReason.Name === 'EXPERIENCE_MISMATCH'
      );
      const experienceRange = experienceMismatchReason?.AdditionalDetails?.ExperienceRange;
      const minExperience = experienceRange?.MinExp || 0;
      const maxExperience = experienceRange?.MaxExp || 0;
      if (minExperience || maxExperience) {
        dispatch(
          setJobDetails({
            JobId: candidate.jobId,
            MinExperience: minExperience,
            MaxExperience: maxExperience,
          })
        );
      }

      if (actionMessage)
        dispatch(
          setNotification('SUCCESS', {
            messageId: 'candidateHasBeenActionMessageSuccessfully',
            mergeTags: { actionMessage },
            mergeTagId: 'true',
          })
        );
      if (callback) callback();
    } catch (error) {
      const notification = {
        Type: 'ERROR',
        MessageData: {
          messageId: 'oopsSomethingJustWentWrong',
        },
      };

      const errorData = mapErrorCodes(error, true);
      if (errorData) notification.MessageData.messageId = errorData.MessageId;
      if (errorData && errorData.MergeTags) notification.MessageData.mergeTags = errorData.MergeTags;
      dispatch({
        type: 'SET_NOTIFICATION',
        payload: notification,
      });
      dispatch({
        type: 'UPDATE_CANDIDATE_STATUS_API_STATUS',
        payload: {
          status: 'FAILED',
          candidateId: candidate.candidateId,
          candidateStatus: candidate.status,
          connectionStatus: candidate.connectionStatus,
          jobId: candidate.jobId,
        },
      });
    }
  };
}

function recommendCandidate(candidate, candidateContext = 'job') {
  return async (dispatch, getState) => {
    const config = _.get(getState(), ['ConfigReducer', 'UserConfig'], {});
    const candidateSource = getCandidateSource(candidate);
    const candidateSourceName = getCandidateSourceName(candidateSource, true);
    const sourceConfigId = _.get(config, ['SourceConfigsByName', candidateSourceName.toLowerCase(), 'Id'], null);
    const sourceGroupId = _.get(
      config,
      ['SourceConfigsByName', candidateSourceName.toLowerCase(), 'SourceGroupId'],
      null
    );
    const candidatePayload = {
      ...candidate,
      sourceConfigId,
      sourceGroupId,
    };
    await dispatch(changeCandidateStatus(candidatePayload, null, true, candidateContext));
  };
}

function getBulkActivities({ bulkActivitySelection, jobId }) {
  const bulkActivitySelectionValues = Object.values(bulkActivitySelection ?? {});
  const sources = bulkActivitySelectionValues[0]?.bulkActivitySearchCriteria?.Sources;

  if (sources?.[0]?.Portal === 'All') {
    const state = _store.getState();
    const sourcesSearched = state.ManualSearchReducer.ByJobId[jobId].ManualSearchPayload.Sources.filter(
      x => x.Portal !== 'All'
    );
    const bySource = state.ManualSearchCandidateReducer.ByJobId[jobId].BySource;
    const sourceVsTotalCount = {};
    sourcesSearched.forEach(x => {
      const sourceName = getSourceName(x);
      sourceVsTotalCount[sourceName] = bySource[sourceName]?.TotalCount ?? 0;
    });
    const { bulkActivitySearchCriteria, excludedCandidates } = bulkActivitySelection.All;

    const _bulkActivitySelection = {};

    _bulkActivitySelection.All = {
      bulkActivitySearchCriteria: {
        ...bulkActivitySearchCriteria,
        Sources: sourcesSearched,
        Size: bulkActivitySearchCriteria.Size,
        FilterDuplicates: true,
      },
      excludedCandidates,
    };

    return Object.values(_bulkActivitySelection);
  }

  const sourceName = Object.keys(bulkActivitySelection || {})?.[0];
  if (sourceName === 'Active') bulkActivitySelectionValues[0].bulkActivitySearchCriteria.FilterDuplicates = true;

  return bulkActivitySelectionValues;
}

const createCandidateNote = ({ jobId, candidateId, vaultName, note, candidate, popupNote }) => {
  return async dispatch => {
    if (popupNote) {
      dispatch(setPopupNoteCreateApiStatus('INPROGRESS'));
    } else {
      dispatch(setCandidateNoteCreateApiStatus('INPROGRESS'));
    }
    try {
      const filter = {
        candidateId,
        vaultName,
        jobId,
      };
      const newCandidateFilter = {
        ...filter,
        ...candidate,
      };
      const isRecommendedCandidate = Boolean(candidate.Status);
      if (!isRecommendedCandidate) {
        await dispatch(recommendCandidate(newCandidateFilter));
      }
      const response = await noteRepository.createNote({ jobId, candidateId, vaultName, payload: note });
      dispatch(addCandidateNote({ candidateId, note: response.data }));
      if (popupNote) {
        dispatch(setPopupNoteCreateApiStatus('COMPLETED'));
      } else {
        dispatch(setCandidateNoteCreateApiStatus('COMPLETED'));
      }
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'noteCreatedSuccessfully',
        })
      );
      return { isSuccess: true };
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: {
          message: error,
          timeStamp: new Date(),
        },
      });
      dispatch(
        setNotification('ERROR', {
          messageId: 'oopsSomethingJustWentWrong',
        })
      );
      if (popupNote) {
        dispatch(setPopupNoteCreateApiStatus('FAILED'));
      } else {
        dispatch(setCandidateNoteCreateApiStatus('FAILED'));
      }
      return { isSuccess: false };
    }
  };
};

function getBulkRecommendRequests({
  jobId,
  recommendedInfoUpdateCommand,
  candidateListType,
  bulkActivitySelection,
  recommendationSource,
  actionName,
  subsegmentId,
  recordId,
  searchCriteriaType,
}) {
  const requests = [];
  const bulkActivities = getBulkActivities({ bulkActivitySelection, jobId });

  bulkActivities.forEach(element => {
    const { bulkActivitySearchCriteria, excludedCandidates, bulkActivitySourceConfiguration } = element;
    if (bulkActivitySearchCriteria) {
      const payload = {
        jobId,
        searchCriteria: {
          excludedCandidates: excludedCandidates?.map(candidate => candidate.CandidateId),
          sourceConfiguration: bulkActivitySourceConfiguration,
        },
        recommendationSource,
        actionName,
        recordId,
        searchCriteriaType,
      };
      if (candidateListType === 'quicksearch') payload.searchCriteria.userGivenCriteria = bulkActivitySearchCriteria;
      else if (candidateListType === 'manualsearch')
        payload.searchCriteria.candidateSearchCriteria = {
          From: bulkActivitySearchCriteria.From,
          Size: bulkActivitySearchCriteria.Size,
          Criteria: bulkActivitySearchCriteria,
          FilterDuplicates: bulkActivitySearchCriteria.FilterDuplicates,
        };
      if (subsegmentId) payload.subsegmentId = subsegmentId;
      requests.push(candidateRepo.bulkCandidateRecommend(payload));
    }
  });
  if (recommendedInfoUpdateCommand?.length > 0) {
    requests.push(
      candidateRepo.bulkCandidateRecommend({
        jobId,
        recommendedInfoUpdateCommand,
        recommendationSource,
        subsegmentId,
        recordId,
        searchCriteriaType,
      })
    );
  }
  return requests;
}

function bulkCandidateRecommend({
  jobId,
  recommendedInfoUpdateCommand,
  bulkActivitySelection,
  candidateListType,
  showNotification = true,
  actionName,
  subsegmentId,
  candidateContext = 'job',
}) {
  return async (dispatch, getState) => {
    let actionMessage = '';
    let apiName = '';
    switch (candidateContext) {
      case 'job':
        switch (actionName) {
          case 'Shortlisted':
            actionMessage = 'shortlisting';
            apiName = 'bulkShortlistApiStatus';
            break;
          case 'Rejected':
            actionMessage = 'rejection';
            apiName = 'bulkRejectionApiStatus';
            break;
          default:
        }
        break;
      case 'segment':
        switch (actionName) {
          case 'Shortlisted':
            actionMessage = 'add';
            apiName = 'bulkShortlistApiStatus';
            break;
          case 'Rejected':
            actionMessage = 'remove';
            apiName = 'bulkRejectionApiStatus';
            break;
          default:
        }
        break;
      default:
    }
    dispatch(setBulkShortlistApiStatus(apiName, 'INPROGRESS'));
    const recommendationSource = getCandidateRecommendationSource(getState());
    const store = getState();
    const recordId = store.ManualSearchReducer.ByJobId?.[jobId]?.ManualSearchCurrentRecordId;
    const searchCriteriaType = store.ManualSearchReducer.ByJobId?.[jobId]?.ManualSearchCurrentCriteriaType;
    try {
      await Promise.all(
        getBulkRecommendRequests({
          jobId,
          recommendedInfoUpdateCommand,
          candidateListType,
          bulkActivitySelection,
          recommendationSource,
          actionName,
          subsegmentId,
          recordId,
          searchCriteriaType,
        })
      );
      if (showNotification) {
        dispatch(
          setNotification('SUCCESS', {
            messageId: 'bulkActionMessageIsInProgress',
            mergeTags: { actionMessage },
            mergeTagId: 'true',
          })
        );
      }
      dispatch(setBulkShortlistApiStatus(apiName, 'COMPLETED'));
    } catch {
      if (showNotification) {
        dispatch(
          setNotification('ERROR', {
            messageId: 'oopsSomethingJustWentWrong',
          })
        );
      }
      dispatch(setBulkShortlistApiStatus(apiName, 'FAILED'));
    }
  };
}

function getBulkSegmentRecommendRequests({
  jobId,
  bulkCustomActivitySelection,
  actionName,
  includeCandidatesWithStatus,
  isPartialRecommend,
  subsegmentId,
}) {
  const bulkActivities = getBulkActivities({ bulkActivitySelection: bulkCustomActivitySelection, jobId });
  const requests = [];
  bulkActivities.forEach(element => {
    const { bulkActivitySearchCriteria, excludedCandidates, bulkActivitySourceConfiguration } = element;
    if (bulkActivitySearchCriteria) {
      const payload = {
        jobId,
        searchCriteria: {
          excludedCandidates: excludedCandidates?.map(candidate => candidate.CandidateId),
          sourceConfiguration: bulkActivitySourceConfiguration,
        },
        count: bulkActivitySearchCriteria.Size,
        actionName,
        includeCandidatesWithStatus,
        isPartialRecommend,
      };
      if (subsegmentId) {
        payload.SubsegmentIds = [subsegmentId];
      }
      payload.searchCriteria.candidateSearchCriteria = { ...bulkActivitySearchCriteria };
      requests.push(candidateRepo.bulkSegmentCandidateRecommend(payload));
    }
  });
  return requests;
}

function bulkSegmentCandidateRecommend({
  jobId,
  bulkCustomActivitySelection,
  showNotification = true,
  actionName,
  includeCandidatesWithStatus,
  isPartialRecommend = false,
  subsegmentId,
}) {
  return async (dispatch, getState) => {
    const apiName = 'bulkShortlistApiStatus';
    dispatch(setBulkShortlistApiStatus(apiName, 'INPROGRESS'));
    const recommendationSource = getCandidateRecommendationSource(getState());

    try {
      await Promise.all(
        getBulkSegmentRecommendRequests({
          jobId,
          bulkCustomActivitySelection,
          recommendationSource,
          actionName,
          includeCandidatesWithStatus,
          isPartialRecommend,
          subsegmentId,
        })
      );
      if (showNotification) {
        dispatch(
          setNotification('SUCCESS', {
            messageId: 'bulkAddIsInProgress',
          })
        );
      }
      dispatch(setBulkShortlistApiStatus(apiName, 'COMPLETED'));
      dispatch(setSegmentBulkRecommendErrorMessage({ jobId, errorMessage: undefined }));
    } catch (error) {
      if (showNotification) {
        const notification = {
          Type: 'ERROR',
          MessageData: {
            messageId: 'oopsSomethingJustWentWrong',
          },
          Message: 'Oops, something just went wrong',
        };
        const statusCode = _.get(error, ['response', 'status']);
        const errorData = mapErrorCodes(error, true);
        let isMessageContainInteger = false;

        if (errorData) {
          notification.Message = errorData.Message;
          isMessageContainInteger = extractNumberFromText(errorData.Message);
        }

        if (statusCode === 422 && isMessageContainInteger) {
          dispatch(setSegmentBulkRecommendErrorMessage({ jobId, errorMessage: notification.Message }));
        } else {
          if (errorData) notification.MessageData.messageId = errorData.MessageId;
          if (errorData && errorData.MergeTags) notification.MessageData.mergeTags = errorData.MergeTags;
          dispatch({
            type: 'SET_NOTIFICATION',
            payload: notification,
          });
          dispatch(setNotification(notification));
        }
      }
      dispatch(setBulkShortlistApiStatus(apiName, 'FAILED'));
    }
  };
}

function updateBulkConnectStatus(jobId, candidates, status) {
  return dispatch => {
    dispatch({
      type: 'BULK_CANDIDATE_CONNECT_API_STATUS',
      payload: {
        status: 'INPROGRESS',
      },
    });
    return candidateRepo
      .updateBulkConnectStatus(jobId, candidates, status)
      .then(() => {
        dispatch(
          setNotification('SUCCESS', {
            messageId: 'bulkStatusProgress',
          })
        );
        dispatch({
          type: 'BULK_CANDIDATE_CONNECT_API_STATUS',
          payload: {
            status: 'COMPLETED',
          },
        });
      })
      .catch(() => {
        dispatch(
          setNotification('ERROR', {
            messageId: 'oopsSomethingJustWentWrongTryAgain',
          })
        );
        dispatch({
          type: 'BULK_CANDIDATE_CONNECT_API_STATUS',
          payload: {
            status: 'FAILED',
          },
        });
      });
  };
}

function postCandidateNotes(candidateFilter, notes, candidateData) {
  return async dispatch => {
    const newCandidateFilter = {
      ...candidateFilter,
      ...candidateData,
    };
    const eventData = {
      CandidateId: newCandidateFilter.candidateId,
      JobId: newCandidateFilter.jobId,
    };
    const broadcastNotification = {
      eventType: 'NotesUpdated',
      eventData,
    };
    const isRecommendedCandidate = Boolean(candidateData.Status);
    if (!isRecommendedCandidate) {
      await dispatch(recommendCandidate(newCandidateFilter));
    }
    await noteRepository.createNote({
      jobId: newCandidateFilter.jobId,
      candidateId: newCandidateFilter.candidateId,
      vaultName: newCandidateFilter.vaultName,
      payload: { Description: notes.Content },
    });
    PubSub.publish(BROADCAST_CHANNEL_POST_MESSAGE, {
      broadcastNotification,
    });
    dispatch(fetchCandidateNotes(newCandidateFilter));
  };
}

function setCandidateRejectStatus(candidateId, size) {
  return dispatch => {
    dispatch(setCandidateRejectFlag(candidateId, size));
  };
}

export const getUserConfig = getState => {
  const store = getState();
  return store?.ConfigReducer?.UserConfig ?? {};
};

function unlockCandidateResume(filter, pushCandInfo) {
  return (dispatch, getState) => {
    const store = getState();
    const userConfig = getUserConfig(getState);
    const displayName = getSourceDisplayName(filter.source, userConfig);
    const isPassiveGroup = displayName === 'Passive';
    const featureToggleList = getFeatureToggleList(store);

    dispatch({
      type: 'SET_CANDIDATE_UNLOCK_RESUME_API_STATUS',
      payload: 'INPROGRESS',
    });
    dispatch(setFetchContactStatus(filter.candidateId, 'InProgress', filter.jobId));
    candidateRepo
      .unlockCandidateResume({
        ...filter,
        countryCode: pushCandInfo.Country,
      })
      .then(response => {
        dispatch(updateUnlockCandidate(response.data));
        dispatch(
          setCandidateDownloadedDetails(
            response.data.Id,
            response.data.IsDownloaded,
            response.data.IsUnlocked,
            filter.jobId
          )
        );
        const isJobUsageBudgetFeatureEnabled = featureToggleList.JobUsageBudget.IsEnabled;
        if (isJobUsageBudgetFeatureEnabled) dispatch(fetchJobUsageAvailableBudget(filter.jobId));
        const eventData = {
          Id: filter.candidateId,
          JobId: filter.jobId,
          CountryCode: pushCandInfo.Country,
        };
        const broadcastNotification = {
          eventType: 'ResumeUnlocked',
          eventData,
        };
        PubSub.publish(BROADCAST_CHANNEL_POST_MESSAGE, {
          broadcastNotification,
        });
        const candidate = response.data;
        if (
          (!candidate.PersonId || !candidate.ConversationId) &&
          pushCandInfo &&
          pushCandInfo.Country &&
          pushCandInfo.refId
        ) {
          dispatch(
            pushCandidateToConnect(
              filter.jobId,
              {
                ...candidate,
                Country: pushCandInfo.Country,
              },
              pushCandInfo.refId,
              null,
              isPassiveGroup
            )
          );
        }
        if (candidate.PersonId) {
          dispatch(addCandidateContactInfo(candidate, pushCandInfo, isPassiveGroup, filter.jobId));
        }
        dispatch({
          type: 'SET_CANDIDATE_UNLOCK_RESUME_API_STATUS',
          payload: 'COMPLETED',
        });
        if (!isPassiveGroup) dispatch(setFetchContactStatus(filter.candidateId, 'Completed', filter.jobId));
      })
      .catch(error => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: error,
            timeStamp: new Date(),
          },
        });
        let errorData = {
          jobId: filter.jobId,
          candidateId: filter.candidateId,
        };
        const errorStatusCode = _.get(error, ['response', 'status']);
        const appCode = _.get(error, ['response', 'data', 'Error', 'Code'], '');
        switch (errorStatusCode) {
          case 402:
            dispatch({
              type: 'SET_ALERT_ERROR',
              payload: {
                Type: 'PAYMENT_REQUIRED_ALERT',
                Action: 'UNLOCK_RESUME',
              },
            });
            break;
          case 404:
            if (appCode.includes('CANDIDATE_NOT_FOUND')) errorData = getErrorMessageForCandidateNotFound(errorData);
            else if (appCode.includes(`CREDENTIALS_NOT_FOUND`))
              errorData = getErrorMessageForCredentialsNotFound(errorData, displayName);
            dispatch(setCandidateAllDetailsError(errorData));
            break;
          default:
            break;
        }
        dispatch({
          type: 'SET_CANDIDATE_UNLOCK_RESUME_API_STATUS',
          payload: 'FAILED',
        });
        dispatch(setFetchContactStatus(filter.candidateId, 'Failed', filter.jobId));
      });
  };
}

function downloadResume(candidateData, fileName, resumeName, portal, version) {
  let candidateId = candidateData.Id;
  if (version === 'v2') candidateId = candidateData.CandidateId; // for v2 version we use integer candidateId

  return dispatch => {
    dispatch(setCandidateDownloadResumeApiSatus('INPROGRESS'));

    (version === 'v2' ? candidateV2Repo : candidateRepo)
      .downloadResume(candidateId, fileName, portal)
      .then(response => {
        dispatch(setCandidateDownloadResumeApiSatus('COMPLETED'));
        const contentType = response.headers['content-type'];
        const extension = mime.getExtension(contentType);
        const blob = new Blob([response.data], { type: contentType });
        const downloadedFileName = resumeName
          ? `${resumeName.trim().replace(/\./g, ' ')}.${extension || 'file'}`
          : `Resume.${extension || 'file'}`;

        FileSaver.saveAs(blob, downloadedFileName);
      })
      .catch(() => {
        dispatch(setCandidateDownloadResumeApiSatus('FAILED'));
        dispatch(
          setNotification('ERROR', {
            messageId: 'downloadResumeAndRequestFailed',
          })
        );
      });
  };
}

function getBulkActivityRequests({
  jobId,
  candidates,
  bulkActivitySelection,
  candidateListType,
  additionalParameters,
  repositoryMethod,
  recommendationSource,
  recordId,
  searchCriteriaType,
}) {
  const requests = [];
  const bulkActivities = getBulkActivities({ bulkActivitySelection, jobId });
  bulkActivities.forEach(element => {
    const { bulkActivitySearchCriteria, excludedCandidates } = element;
    if (bulkActivitySearchCriteria) {
      const payload = {
        jobId,
        searchCriteria: {
          excludedCandidates: excludedCandidates?.map(candidate => candidate.CandidateId),
        },
        recommendationSource,
        ...additionalParameters,
      };
      if (recordId && searchCriteriaType) {
        payload.recordId = recordId;
        payload.searchCriteriaType = searchCriteriaType;
      }
      if (candidateListType === 'quicksearch') payload.searchCriteria.userGivenCriteria = bulkActivitySearchCriteria;
      else if (candidateListType === 'manualsearch')
        payload.searchCriteria.candidateSearchCriteria = {
          From: bulkActivitySearchCriteria.From,
          Size: bulkActivitySearchCriteria.Size,
          Criteria: bulkActivitySearchCriteria,
          FilterDuplicates: bulkActivitySearchCriteria.FilterDuplicates,
        };
      requests.push(repositoryMethod(payload));
    }
  });
  if (candidates?.length > 0) {
    requests.push(
      repositoryMethod({
        jobId,
        candidates,
        recommendationSource,
        ...additionalParameters,
        recordId,
        searchCriteriaType,
      })
    );
  }
  return requests;
}

function getBulkEmailRequests({
  jobId,
  candidates,
  email,
  candidateListType,
  bulkActivitySelection,
  recommendationSource,
  recordId,
  searchCriteriaType,
}) {
  const additionalParameters = { email };
  return getBulkActivityRequests({
    jobId,
    candidates,
    additionalParameters,
    candidateListType,
    bulkActivitySelection,
    recommendationSource,
    repositoryMethod: candidateRepo.sendQuickSearchBulkMails,
    recordId,
    searchCriteriaType,
  });
}

function getBulkDripEmailRequests({
  jobId,
  candidates,
  email,
  candidateListType,
  bulkActivitySelection,
  recommendationSource,
}) {
  const additionalParameters = { email };
  return getBulkActivityRequests({
    jobId,
    candidates,
    additionalParameters,
    candidateListType,
    bulkActivitySelection,
    recommendationSource,
    repositoryMethod: candidateRepo.sendQuickSearchBulkDripMails,
  });
}

function getBulkMessageRequests({
  jobId,
  candidates,
  message,
  candidateListType,
  bulkActivitySelection,
  recommendationSource,
}) {
  const additionalParameters = { message };
  return getBulkActivityRequests({
    jobId,
    candidates,
    additionalParameters,
    candidateListType,
    bulkActivitySelection,
    recommendationSource,
    repositoryMethod: candidateRepo.sendQuickSearchBulkMessage,
  });
}

function sendBulkMails(
  jobId,
  candidates,
  email,
  conversationId,
  isDripTemplate,
  candidateListType,
  bulkActivitySelection
) {
  return async (dispatch, getState) => {
    PubSub.publish(SETUP_SOCKET_CONNECTIONS, {
      dispatch,
    });
    dispatch({
      type: 'SET_SEND_BULK_MAIL_API_STATUS',
      payload: {
        conversationId,
        mail: email,
        status: 'IN_PROGRESS',
      },
    });
    const recommendationSource = getCandidateRecommendationSource(getState());
    const recordId = getState().ManualSearchReducer?.ByJobId?.[jobId]?.ManualSearchCurrentRecordId;
    const searchCriteriaType = getState().ManualSearchReducer?.ByJobId?.[jobId]?.ManualSearchCurrentCriteriaType;
    try {
      if (isDripTemplate) {
        if (['quicksearch', 'manualsearch'].includes(candidateListType)) {
          await Promise.all(
            getBulkDripEmailRequests({
              jobId,
              candidates,
              email,
              candidateListType,
              bulkActivitySelection,
              recommendationSource,
            })
          );
        } else {
          await candidateRepo.sendBulkDripMails({ jobId, candidates, email });
        }
      } else if (['quicksearch', 'manualsearch'].includes(candidateListType)) {
        await Promise.all(
          getBulkEmailRequests({
            jobId,
            candidates,
            email,
            candidateListType,
            bulkActivitySelection,
            recommendationSource,
            recordId,
            searchCriteriaType,
          })
        );
      } else {
        await candidateRepo.sendBulkMails({
          jobId,
          candidates,
          email,
          recommendationSource,
        });
      }
      dispatch({
        type: 'SET_SEND_BULK_MAIL_API_STATUS',
        payload: {
          conversationId,
          mail: email,
          status: 'COMPLETED',
        },
      });
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'bulkEmailInitiated',
        })
      );
    } catch (error) {
      dispatch({
        type: 'SET_SEND_BULK_MAIL_API_STATUS',
        payload: {
          conversationId,
          mail: email,
          status: 'FAILED',
        },
      });
      dispatch({
        type: 'SET_ERROR',
        payload: {
          message: error,
          timeStamp: new Date(),
        },
      });
    }
  };
}

function sendDripToJobAssociatedCandidate(jobId, candidates, drip, conversationId) {
  return dispatch => {
    dispatch({
      type: 'SET_SEND_DRIP_API_STATUS',
      payload: {
        conversationId,
        drip,
        status: 'IN_PROGRESS',
      },
    });
    candidateRepo
      .sendDrip(jobId, candidates, drip)
      .then(() => {
        dispatch({
          type: 'SET_SEND_DRIP_API_STATUS',
          payload: {
            conversationId,
            drip,
            status: 'COMPLETED',
          },
        });
      })
      .catch(error => {
        dispatch({
          type: 'SET_SEND_DRIP_API_STATUS',
          payload: {
            conversationId,
            drip,
            status: 'FAILED',
          },
        });
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: error,
            timeStamp: new Date(),
          },
        });
      });
  };
}

function sendMailToJobAssociatedCandidate(jobId, candidateId, portal, email, conversationId, personId) {
  return (dispatch, getState) => {
    PubSub.publish(SETUP_SOCKET_CONNECTIONS, {
      dispatch,
    });
    const id = uuid();
    const identifiedEmail = {
      Id: id,
      ...email,
    };
    dispatch({
      type: 'SET_SEND_MAIL_API_STATUS',
      payload: {
        jobId,
        candidateId,
        portal,
        conversationId,
        email: identifiedEmail,
        status: 'IN_PROGRESS',
      },
    });
    const currentState = getState();
    const featureToggleList = _.get(currentState, ['FeatureToggleReducer'], {});
    const {
      AllConversations: { IsEnabled: isAllConversationsEnabled },
    } = featureToggleList;
    dispatch({
      type: 'SET_CONVERSATION_PENDING_MAILS',
      payload: {
        conversationId,
        personId,
        email: {
          ...identifiedEmail,
          To: identifiedEmail.To[0],
        },
        isAllConversationsEnabled,
      },
    });
    candidateRepo
      .sendMail(jobId, candidateId, identifiedEmail)
      .then(() => {
        dispatch({
          type: 'SET_SEND_MAIL_API_STATUS',
          payload: {
            jobId,
            candidateId,
            portal,
            conversationId,
            email: identifiedEmail,
            status: 'COMPLETED',
          },
        });
      })
      .catch(error => {
        dispatch({
          type: 'SET_SEND_MAIL_API_STATUS',
          payload: {
            jobId,
            candidateId,
            portal,
            conversationId,
            email: identifiedEmail,
            status: 'FAILED',
          },
        });
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: error,
            timeStamp: new Date(),
          },
        });
      });
  };
}

function sendMail(jobId, email, conversationId, personId, candidate) {
  return async dispatch => {
    const isRecommendedCandidate = Boolean(candidate.Status);
    const candidateId = candidate.Id;
    if (!isRecommendedCandidate) {
      await dispatch(recommendCandidate(candidate));
    }
    dispatch(sendMailToJobAssociatedCandidate(jobId, candidateId, candidate.Portal, email, conversationId, personId));
  };
}

function addNewDripToConversation(jobId, candidates, drip, conversationId, candidateData) {
  return async dispatch => {
    const isRecommendedCandidate = Boolean(candidateData.Status);
    if (!isRecommendedCandidate) {
      await dispatch(recommendCandidate(candidateData));
    }
    dispatch(sendDripToJobAssociatedCandidate(jobId, candidates, drip, conversationId));
  };
}

function sendBulkMessages(jobId, candidates, message, type, conversationId, candidateListType, bulkActivitySelection) {
  return async (dispatch, getState) => {
    PubSub.publish(SETUP_SOCKET_CONNECTIONS, {
      dispatch,
    });
    dispatch({
      type: 'SET_SEND_BULK_MESSAGE_API_STATUS',
      payload: {
        conversationId,
        message: {
          ...message,
        },
        status: 'IN_PROGRESS',
      },
    });
    const recommendationSource = getCandidateRecommendationSource(getState());
    try {
      if (['quicksearch', 'manualsearch'].includes(candidateListType?.toLowerCase())) {
        await Promise.all(
          getBulkMessageRequests({
            jobId,
            candidates,
            message,
            candidateListType,
            bulkActivitySelection,
            recommendationSource,
          })
        );
      } else {
        candidateRepo.sendBulkMessage(jobId, candidates, message, recommendationSource);
      }
      dispatch({
        type: 'SET_SEND_BULK_MESSAGE_API_STATUS',
        payload: {
          conversationId,
          message: {
            ...message,
          },
          status: 'COMPLETED',
        },
      });
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'bulkMessageInitiated',
        })
      );
    } catch (error) {
      dispatch({
        type: 'SET_SEND_BULK_MESSAGE_API_STATUS',
        payload: {
          conversationId,
          message: {
            ...message,
          },
          status: 'FAILED',
        },
      });
      dispatch({
        type: 'SET_ERROR',
        payload: {
          message: error,
          timeStamp: new Date(),
        },
      });
    }
  };
}

function sendMessage(jobId, message, conversationId, candidate) {
  const { Id: candidateId } = candidate;
  const messagePayload = {
    ...message,
    Id: uuid(),
  };
  return async dispatch => {
    try {
      PubSub.publish(SETUP_SOCKET_CONNECTIONS, {
        dispatch,
      });
      const identifiedMessage = {
        ...messagePayload,
      };
      if (messagePayload.IsConsentMessage) {
        const msgBody = (messagePayload.Body || '').concat(messagePayload.ConsentOption || '');
        identifiedMessage.Body = msgBody;
      }
      const isRecommendedCandidate = Boolean(candidate.Status);
      if (!isRecommendedCandidate) {
        await dispatch(recommendCandidate(candidate));
      }
      dispatch(setConversationPendingMessages(conversationId, identifiedMessage));

      dispatch(setFetchSendMessageApiStatus('INPROGRESS'));
      await candidateRepo.sendMessage(jobId, candidateId, messagePayload);
      dispatch(setFetchSendMessageApiStatus('COMPLETED'));
    } catch {
      dispatch(setFetchSendMessageApiStatus('FAILED'));
    } finally {
      if (conversationId) await connectRepo.readPhoneNumberSMS(conversationId, message.To[0]);
    }
  };
}

function sendCandidateResponse(conversationId, payload) {
  const candidateResponsePayload = {
    ...payload,
    message_id: uuid(),
  };

  return async dispatch => {
    try {
      await candidateRepo.sendCandidateResponse(conversationId, candidateResponsePayload);
    } catch {
      dispatch(setFetchSendMessageApiStatus('ERROR'));
    }
  };
}

function fetchBulkContacts(jobId, candidateIds, excludeCandidatesWithContacts) {
  return (dispatch, getState) => {
    const currentState = getState();
    dispatch(setFetchBulkContactsApiStatus(jobId, 'INPROGRESS'));
    dispatch(
      setNotification('LOADING', {
        messageId: 'pullingContactOfCandidate',
        mergeTags: { candidateIdsLength: candidateIds.length },
      })
    );
    const candidateIdsToSetInProgress = [];
    candidateIds.forEach(candidateId => {
      const candidatePersonId = _.get(currentState, ['CandidateReducer', 'ById', candidateId], {}).PersonId;
      const candidateContactInfo = _.get(
        currentState,
        ['ConnectReducer', 'ConnectStatuses', candidatePersonId, 'Contact'],
        {}
      );
      const availableProviders = candidateContactInfo.AvailableProviders;
      const isCandidateContactAvailable = isValidContact(candidateContactInfo);
      if (availableProviders !== 0 && (!excludeCandidatesWithContacts || !isCandidateContactAvailable)) {
        candidateIdsToSetInProgress.push(candidateId);
      }
    });
    candidateRepo
      .fetchBulkContacts(jobId, candidateIds, excludeCandidatesWithContacts)
      .then(() => {
        dispatch(setFetchBulkContactsApiStatus(jobId, 'COMPLETED'));
        dispatch(setBulkFetchContactStatus(candidateIdsToSetInProgress, 'InProgress'));
        dispatch(
          setNotification('SUCCESS', {
            messageId: 'requestSentSuccessfully',
          })
        );
      })
      .catch(() => {
        dispatch(setFetchBulkContactsApiStatus(jobId, 'FAILED'));
        dispatch(
          setNotification('ERROR', {
            messageId: 'contactRequestFailed',
          })
        );
      });
  };
}

// this function will fetch candidate details and connect status for candidate
function fetchCandidateDetailsforV2Version(filter, pushCandidateInfo) {
  let candidateObject;
  return (dispatch, getState) => {
    const currentState = getState();
    const jobGuid = _.get(currentState, ['JobReducer', 'ById', filter.jobId], {}).JobGuid;
    const candidateViewPayload = {
      jobGuid,
      candidateId: filter.candidateId,
    };
    dispatch({
      type: 'SET_CANDIDATE_ALL_DETAILS_API_STATUS',
      payload: 'INPROGRESS',
    });
    candidateV2Repo
      .fetchCandidateDetails(filter)
      .then(response => {
        if (filter.jobId) {
          const { Candidate, Recommended, ApplicationInfo } = response.data;
          candidateObject = {
            ...Candidate,
            ...Recommended,
            ...getCandidateApplicationInfo(ApplicationInfo),
            jobId: filter.jobId,
            Portal: filter.portal,
            CandidateId: filter.candidateId,
          };
        } else candidateObject = response.data;
        dispatch({
          type: 'SET_CURRENT_CANDIDATE_ID',
          payload: candidateObject.Id,
        });
        dispatch(setCandidateAllDetails(candidateObject));
        dispatch(
          setCandidateDownloadedDetails(
            candidateObject.Id,
            candidateObject.IsDownloaded,
            candidateObject.IsUnlocked,
            filter.jobId
          )
        );
        dispatch({
          type: 'SET_CANDIDATE_ALL_DETAILS_API_STATUS',
          payload: 'COMPLETED',
        });
        if (candidateObject && candidateObject.PersonId && candidateObject.ConversationId) {
          dispatch(fetchConnectStatus(candidateObject.ConversationId, candidateObject.PersonId));
        } else if (candidateObject.PersonId) {
          dispatch(getContact(candidateObject.PersonId, candidateObject.Id, filter.jobId));
        } else {
          dispatch(
            pushCandidateToConnect(
              filter.jobId,
              {
                ...candidateObject,
                Country: pushCandidateInfo.Country,
              },
              pushCandidateInfo.refId
            )
          );
        }
        dispatch(updateCandidateViewStatus(candidateViewPayload));
      })
      .catch(error => {
        dispatch({
          type: 'SET_ERROR',
          payload: {
            message: error,
            timeStamp: new Date(),
          },
        });
        dispatch({
          type: 'SET_CANDIDATE_ALL_DETAILS_API_STATUS',
          payload: 'FAILED',
        });
        if (filter.portal.toLowerCase() !== 'social' && filter.portal.toLowerCase() !== 'indeed') {
          dispatch(setFetchContactStatus(filter.candidateId, 'Failed', filter.jobId));
        }
        const errorData = mapErrorCodes(error);
        if (errorData) dispatch(setCandidateAllDetailsError(errorData));
      });
  };
}

function bulkPublishCandidates(jobId, candidatesPublishPayload) {
  return dispatch => {
    dispatch({
      type: 'BULK_PUBLISH_CANDIDATE_API_STATUS',
      payload: {
        status: 'INPROGRESS',
      },
    });
    return candidateRepo
      .bulkPublishCandidates(jobId, candidatesPublishPayload)
      .then(() => {
        dispatch(
          setNotification('SUCCESS', {
            messageId: 'bulkPublishCandidateProgress',
          })
        );
        dispatch({
          type: 'SET_FINAL_COMMIT_STATUS',
          payload: {
            jobId,
            IsFinalCommit: candidatesPublishPayload.isFinalCommit,
          },
        });
        dispatch({
          type: 'BULK_PUBLISH_CANDIDATE_API_STATUS',
          payload: {
            status: 'COMPLETED',
          },
        });
      })
      .catch(error => {
        const errorMessage = mapBulkPublishCandidatesApiErrorCode(error);
        dispatch({
          type: 'SET_NOTIFICATION',
          payload: {
            Type: 'ERROR',
            Message: errorMessage,
          },
        });
        dispatch({
          type: 'BULK_PUBLISH_CANDIDATE_API_STATUS',
          payload: {
            status: 'FAILED',
          },
        });
      });
  };
}

function fetchCandidateIntel(jobId, candidateId) {
  return async dispatch => {
    dispatch(setCandidateIntelApiStatus('INPROGRESS'));
    try {
      const candidateIntelResponse = await candidateRepo.fetchCandidateIntel(jobId, candidateId);
      const intel = candidateIntelResponse.status === 200 ? candidateIntelResponse.data : null;
      dispatch(setCandidateIntel(candidateId, intel));
      dispatch(setCandidateIntelApiStatus('COMPLETED'));
      return intel;
    } catch (error) {
      dispatch(setCandidateIntelApiStatus('FAILED'));
      throw error;
    }
  };
}

function fetchTryNowCandidates(filter) {
  return dispatch => {
    dispatch(setFetchTryNowCanidateApiStatus('INPROGRESS'));
    return candidateRepo
      .fetchTryNowCandidates(filter)
      .then(response => {
        const responseData = _.get(response, ['data', 0], {});
        const candidatesObject = responseData.Candidates || [];
        const candidates = mergeCandidateData({ candidatesObject });
        const candidateCount = responseData.Total || 0;
        dispatch(setCandidates(candidates));
        dispatch(setCandidateCount(candidateCount));
        dispatch(setFetchTryNowCanidateApiStatus('COMPLETED'));
        return candidateCount;
      })
      .catch(error => {
        let statusType = 'FAILED';

        if (_.get(error, ['response', 'status'], null) === 403) {
          statusType = 'TRY_NOW_LIMIT_EXCEEDED';
        } else {
          dispatch(
            setNotification('ERROR', {
              messageId: 'oopsSomethingJustWentWrong',
            })
          );
        }
        dispatch(setCandidateListData([]));
        dispatch(setFetchTryNowCanidateApiStatus(statusType));
        throw error;
      });
  };
}

function fetchTryNowCandidateDetails(candidateId, countryCode) {
  return async dispatch => {
    dispatch(setFetchTryNowCanidateDetailsApiStatus('INPROGRESS'));
    try {
      const response = await candidateRepo.fetchTryNowCandidateDetails(candidateId, countryCode);
      dispatch(setCandidateAllDetails(response?.data));
      dispatch(setFetchTryNowCanidateDetailsApiStatus('COMPLETED'));
    } catch {
      dispatch(setFetchTryNowCanidateDetailsApiStatus('FAILED'));
    }
  };
}

function fetchCandidateRank({ jobId, candidateRankQuery }) {
  return async (dispatch, getState) => {
    try {
      const store = getState();
      const featureToggleList = getFeatureToggleList(store);
      const {
        ConnectedCandidatesV2: { IsEnabled: isConnectedV2Enabled },
      } = featureToggleList;
      const response = await candidateRepo.fetchCandidateRank({
        jobId,
        candidateRankQuery,
        IsConnectedV2: isConnectedV2Enabled,
      });
      return response.data;
    } catch (error) {
      const errorResponse = error?.response;
      const appCode = errorResponse?.data?.Error?.Code;
      const statusCode = errorResponse?.status;
      if (statusCode === 404 && appCode === 'CANDIDATE_NOT_FOUND') {
        dispatch(
          setNotification('ERROR', {
            messageId: 'candidatenoLongerAvailable',
          })
        );
      }
      return {
        Rank: -1,
      };
    }
  };
}

function fetchCandidateSpecifications(candidateSpecificationList) {
  return async dispatch => {
    dispatch(setCandidateSpecificationsApiStatus('INPROGRESS'));
    try {
      const response = await candidateRepo.getCandidateSpecifications(candidateSpecificationList);
      const candidateSpecifications = response.data?.MasterDataGroup;
      dispatch(fetchCandidateSpecificationsData(candidateSpecifications));
      dispatch(setCandidateSpecificationsApiStatus('COMPLETED'));
    } catch {
      dispatch(setCandidateSpecificationsApiStatus('FAILED'));
    }
  };
}

function clearCandidatesExtraInfo({ jobId }) {
  return _clearCandidatesExtraInfo({ jobId });
}

function bulkCandidateRemove(filter) {
  return async dispatch => {
    try {
      await candidateRepo.bulkCandidateRemove(filter);
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'bulkRemoveInProgress',
        })
      );
    } catch {
      dispatch(
        setNotification('ERROR', {
          messageId: 'oopsSomethingJustWentWrongTryAgain',
        })
      );
    }
  };
}

function bulkCandidateShortlist(filter) {
  return async dispatch => {
    try {
      await candidateRepo.bulkCandidateShortList(filter);
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'bulkAddIsInProgress',
        })
      );
    } catch {
      dispatch(
        setNotification('ERROR', {
          messageId: 'oopsSomethingJustWentWrongTryAgain',
        })
      );
    }
  };
}

function bulkCandidateTransfer(filter, transferType) {
  return async dispatch => {
    let actionMessage = '';
    if (filter.actionName === 'Move') actionMessage = 'move';
    if (filter.actionName === 'Copy') actionMessage = 'copy';
    const actionName = filter.actionName === 'Move' ? 'moved' : 'copied';
    const singleTransferMessageId = 'candidateHasBeenSuccessfull';
    const bulkTransferMessageId = 'bulkIsInProgress';
    const transferMessage = transferType === 'bulk' ? bulkTransferMessageId : singleTransferMessageId;
    try {
      await candidateRepo.bulkCandidateTransfer(filter);
      dispatch(
        setNotification('SUCCESS', {
          messageId: transferMessage,
          mergeTags: { actionName, actionMessage },
          mergeTagId: 'true',
        })
      );
    } catch {
      dispatch(
        setNotification('ERROR', {
          messageId: 'oopsSomethingJustWentWrongTryAgain',
        })
      );
    }
  };
}
const fetchCandidateJobs = (filter = {}) => {
  const newFilter = { ...filter, entityType: 'Job' };
  return async dispatch => {
    dispatch({
      type: 'FETCH_CANDIDATE_JOBS_API_STATUS',
      payload: 'INPROGRESS',
    });
    try {
      const response = await candidateRepo.fetchCandidateJobs(newFilter);
      dispatch({
        type: 'SET_CANDIDATE_JOBS',
        payload: response.data,
      });
      dispatch({
        type: 'FETCH_CANDIDATE_JOBS_API_STATUS',
        payload: 'COMPLETED',
      });
    } catch {
      dispatch({
        type: 'FETCH_CANDIDATE_JOBS_API_STATUS',
        payload: 'FAILED',
      });
    }
  };
};
const appendCandidateJobs = (filter = {}) => {
  const newFilter = { ...filter, entityType: 'Job' };
  return async dispatch => {
    dispatch({
      type: 'APPEND_CANDIDATE_JOBS_API_STATUS',
      payload: 'INPROGRESS',
    });
    try {
      const response = await candidateRepo.fetchCandidateJobs(newFilter);
      dispatch({
        type: 'APPEND_CANDIDATE_JOBS',
        payload: response.data,
      });
      dispatch({
        type: 'APPEND_CANDIDATE_JOBS_API_STATUS',
        payload: 'COMPLETED',
      });
    } catch {
      dispatch({
        type: 'APPEND_CANDIDATE_JOBS_API_STATUS',
        payload: 'FAILED',
      });
    }
  };
};
const fetchCandidateSegmentsAndCampaigns = (filter = {}) => {
  const newFilter = { ...filter, entityType: 'Segment' };
  return async dispatch => {
    dispatch({
      type: 'FETCH_CANDIDATE_LISTS_AND_CAMPAIGNS_API_STATUS',
      payload: 'INPROGRESS',
    });
    try {
      const response = await candidateRepo.fetchCandidateJobs(newFilter);
      dispatch({
        type: 'SET_CANDIDATE_LISTS_AND_CAMPAIGNS',
        payload: response.data,
      });
      dispatch({
        type: 'FETCH_CANDIDATE_LISTS_AND_CAMPAIGNS_API_STATUS',
        payload: 'COMPLETED',
      });
    } catch {
      dispatch({
        type: 'FETCH_CANDIDATE_LISTS_AND_CAMPAIGNS_API_STATUS',
        payload: 'FAILED',
      });
    }
  };
};
const appendCandidateSegmentsAndCampaigns = (filter = {}) => {
  const newFilter = { ...filter, entityType: 'Segment' };
  return async dispatch => {
    dispatch({
      type: 'APPEND_CANDIDATE_LISTS_AND_CAMPAIGNS_API_STATUS',
      payload: 'INPROGRESS',
    });
    try {
      const response = await candidateRepo.fetchCandidateJobs(newFilter);
      dispatch({
        type: 'APPEND_CANDIDATE_LISTS_AND_CAMPAIGNS',
        payload: response.data,
      });
      dispatch({
        type: 'APPEND_CANDIDATE_LISTS_AND_CAMPAIGNS_API_STATUS',
        payload: 'COMPLETED',
      });
    } catch {
      dispatch({
        type: 'APPEND_CANDIDATE_LISTS_AND_CAMPAIGNS_API_STATUS',
        payload: 'FAILED',
      });
    }
  };
};
const fetchCandidateMatchingJobs = (filter = {}, shouldShowLoader = true) => {
  const newFilter = { ...filter };
  return async dispatch => {
    if (shouldShowLoader) dispatch(setFetchCandidateMatchingJobsApiStatus('INPROGRESS'));
    try {
      const response = await candidateRepo.fetchCandidateMatchingJobs(newFilter);
      dispatch(setCandidateMatchingJobs(response.data));
      if (shouldShowLoader) dispatch(setFetchCandidateMatchingJobsApiStatus('COMPLETED'));
      return response.data;
    } catch (error) {
      const notification = {
        Type: 'ERROR',
        MessageData: {
          messageId: 'oopsSomethingJustWentWrong',
        },
      };

      const errorData = mapErrorCodes(error);
      if (errorData) notification.MessageData.messageId = errorData.MessageId;
      if (errorData && errorData.MergeTags) notification.MessageData.mergeTags = errorData.MergeTags;

      dispatch(setNotification(notification));
      if (shouldShowLoader) dispatch(setFetchCandidateMatchingJobsApiStatus('FAILED'));
      return {};
    }
  };
};

function setCandidateJobMatchingListSearchTerm(searchTerm) {
  return setCandidateMatchingJobsSearchTerm(searchTerm);
}
function setCandidateMatchingJobFilters(key, value) {
  return setCandidateJobMatchingJobFilters(key, value);
}

const updateCandidateViewStatus = viewDetails => {
  const {
    candidateId,
    jobGuid: JobGuid,
    jobId: JobId,
    recommendationSource: RecommendationSource,
    recordId: RecordId,
    searchCriteriaType: SearchCriteriaType,
  } = viewDetails;
  return async dispatch => {
    try {
      const response = await candidateRepo.updateCandidateViewStatus(candidateId, {
        JobGuid,
        JobId,
        RecommendationSource,
        RecordId,
        SearchCriteriaType,
      });
      dispatch(fetchBulkCandidateViewStatus([candidateId], JobGuid, false, true));
      return response.data;
    } catch (e) {
      return e;
    }
  };
};

function fetchCandidateResume({ candidateData, fileName, version, portal }) {
  let candidateId = candidateData.Id;
  if (version === 'v2') candidateId = candidateData.CandidateId; // for v2 version we use integer candidateId
  return async () => {
    try {
      const response = await (version === 'v2' ? candidateV2Repo : candidateRepo).downloadResume(
        candidateId,
        fileName,
        portal
      );

      return response.data;
    } catch (error) {
      return {};
    }
  };
}

function fetchAppliedBucketScoringStatus({ jobId }) {
  return async dispatch => {
    try {
      const response = await candidateRepo.fetchAppliedBucketScoringStatus(jobId);
      dispatch(setScoringStatus(jobId, response.data));
      return response.data;
    } catch (error) {
      dispatch(
        setNotification('ERROR', {
          messageId: 'oopsSomethingJustWentWrong',
        })
      );
      return {};
    }
  };
}

function recommendFavouriteCandidate(filter) {
  return async dispatch => {
    try {
      const { candidateId } = filter;

      dispatch(setFavouriteCandidateApiStatus({ [candidateId]: 'INPROGRESS' }));
      const changeCandidateStatusPayload = {
        CandidateId: filter.candidateId,
        RecommendedInfo: {
          Status: 'Favourite',
          SourceConfigId: filter.sourceConfigId,
          SourceGroupId: filter.sourceGroupId,
          RecommendationSource: 'AssistedSourcing',
        },
      };

      changeCandidateStatusPayload.Metadata = filter.metadata;
      await quickSearchRepository.changeCandidateRecommendedStatus(filter.jobId, changeCandidateStatusPayload);

      dispatch({
        type: 'SET_CANDIDATE_STATUS',
        payload: { candidateId, status: 'favourite' },
      });
      dispatch(fetchJobSourcingStats([filter.jobId]));
      dispatch(setFavouriteCandidateApiStatus({ [candidateId]: 'COMPLETED' }));
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'candidateHasBeenAddedToFavouriteBucket',
        })
      );
    } catch (error) {
      const { candidateId } = filter;
      const notification = {
        Type: 'ERROR',
        MessageData: {
          messageId: 'oopsSomethingJustWentWrong',
        },
      };
      dispatch(setFavouriteCandidateApiStatus({ [candidateId]: 'FAILED' }));
      const errorData = mapErrorCodes(error, true);
      if (errorData) notification.MessageData.messageId = errorData.MessageId;
      if (errorData && errorData.MergeTags) notification.MessageData.mergeTags = errorData.MergeTags;

      dispatch({
        type: 'SET_NOTIFICATION',
        payload: notification,
      });
    }
  };
}

function publishedCandidates({ selectedCandidates, jobId }) {
  return async dispatch => {
    try {
      dispatch(setCandidatePublishApiStatus('INPROGRESS'));
      await candidateRepo.publishedCandidates({ candidates: selectedCandidates, jobId });
      dispatch({
        type: 'REMOVE_CANDIDATE_STATUS',
        payload: { aryaCandidateIds: selectedCandidates },
      });
      dispatch(setCandidatePublishApiStatus('COMPLETED'));
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'bulkCandidatePublishIsInProgress',
        })
      );
    } catch (error) {
      const notification = {
        Type: 'ERROR',
        MessageData: {
          messageId: 'oopsSomethingJustWentWrong',
        },
      };

      const errorData = mapErrorCodes(error, true);
      if (errorData) notification.MessageData.messageId = errorData.MessageId;
      if (errorData && errorData.MergeTags) notification.MessageData.mergeTags = errorData.MergeTags;

      dispatch({
        type: 'SET_NOTIFICATION',
        payload: notification,
      });
    }
  };
}

function removeFavouriteCandidate({ jobId, aryaCandidateIds }) {
  return async dispatch => {
    try {
      dispatch(setRemoveFavouriteCandidateApiStatus({ [aryaCandidateIds[0]]: 'INPROGRESS' }));
      await candidateRepo.removeFavouriteCandidate({ jobId, aryaCandidateIds });
      dispatch({
        type: 'REMOVE_CANDIDATE_STATUS',
        payload: { aryaCandidateIds },
      });
      dispatch(fetchJobSourcingStats([jobId]));
      dispatch(setRemoveFavouriteCandidateApiStatus({ [aryaCandidateIds[0]]: 'COMPLETED' }));
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'candidateRemovedFromFavouriteBucket',
        })
      );
    } catch (error) {
      dispatch(setRemoveFavouriteCandidateApiStatus({ [aryaCandidateIds[0]]: 'FAILED' }));
      const notification = {
        Type: 'ERROR',
        MessageData: {
          messageId: 'oopsSomethingJustWentWrong',
        },
      };

      const errorData = mapErrorCodes(error, true);
      if (errorData) notification.MessageData.messageId = errorData.MessageId;
      if (errorData && errorData.MergeTags) notification.MessageData.mergeTags = errorData.MergeTags;

      dispatch({
        type: 'SET_NOTIFICATION',
        payload: notification,
      });
    }
  };
}
export function setRecommendationSource(source) {
  return setCandidateRecommendationSource(source);
}

function setMspIcons(payload) {
  return async dispatch => {
    dispatch({
      type: 'SET_MSP_ICONS',
      payload,
    });
  };
}

export function bulkCandidateRecommendToJobFromSegment(payload) {
  return async dispatch => {
    dispatch(setBulkShortlistApiStatus('bulkShortlistApiStatus', 'INPROGRESS'));
    try {
      await candidateRepo.bulkCandidateRecommendToJobFromSegment(payload);
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'bulkShortlistingIsInProgressLabel',
        })
      );
      dispatch(setBulkShortlistApiStatus('bulkShortlistApiStatus', 'COMPLETED'));
    } catch {
      dispatch(
        setNotification('ERROR', {
          messageId: 'oopsSomethingJustWentWrong',
        })
      );
      dispatch(setBulkShortlistApiStatus('bulkShortlistApiStatus', 'FAILED'));
    }
  };
}

export const fetchSmartAgentCandidates = ({ from, size, jobId }) => {
  return async dispatch => {
    batch(() => {
      dispatch(setCandidateListError());
      dispatch(setCandidateListApiStatus('INPROGRESS'));
      dispatch(setConnectStatusApiStatus('INPROGRESS'));
      dispatch(setCandidates([]));
      dispatch(setCandidateCount(0));
      dispatch(setCandidateRecommendationSource('AdvanceSearch'));
    });
    try {
      const response = await candidateRepo.fetchSmartAgentCandidates({
        From: from,
        Size: size,
        jobId,
      });
      dispatch(fetchBulkSmartAgentStats({ jobIds: [jobId] }));
      const responseData = _.get(response, ['data', 0], {});
      const sourcedCandidates = responseData.Candidates || [];
      const candidateCount = responseData.Total || 0;
      const candidateData = mergeCandidateData({ candidatesObject: sourcedCandidates, isQuickSearchCandidate: true });
      dispatch(setCandidates(candidateData));
      dispatch(setCandidateCount(candidateCount));
      dispatch(setSmartAgentsCandidateCount({ [jobId]: { candidatesCount: candidateCount } }));
      dispatch(setCandidateListApiStatus('COMPLETED'));
    } catch (error) {
      dispatch(setCandidateListApiStatus('COMPLETED'));
      dispatch({
        type: 'SET_ERROR',
        payload: {
          message: error,
          timeStamp: new Date(),
        },
      });
      dispatch(
        setNotification('ERROR', {
          messageId: 'oopsSomethingJustWentWrong',
        })
      );
    }
  };
};
export function bulkRecommendSmartAgentCandidate(payload) {
  return async dispatch => {
    dispatch(setBulkShortlistApiStatus('bulkRecommendSmartAgentCandidateApiStatus', 'INPROGRESS'));
    try {
      await candidateRepo.bulkRecommendSmartAgentCandidate(payload);
      dispatch(
        setNotification('SUCCESS', {
          messageId: 'bulkShortlistingIsInProgressLabel',
        })
      );
      dispatch(setBulkShortlistApiStatus('bulkRecommendSmartAgentCandidateApiStatus', 'COMPLETED'));
    } catch {
      dispatch(
        setNotification('ERROR', {
          messageId: 'oopsSomethingJustWentWrong',
        })
      );
      dispatch(setBulkShortlistApiStatus('bulkRecommendSmartAgentCandidateApiStatus', 'FAILED'));
    }
  };
}
function setCandidatesMoveFlag(flag) {
  return {
    type: 'SET_CANDIDATES_MOVE_FLAG',
    payload: flag,
  };
}

export {
  fetchCandidateList,
  fetchCandidateActivityLogs,
  fetchManualSearchCandidateList,
  fetchContactedCandidates,
  fetchCandidateDetails,
  changeCandidateStatus,
  changeCandidateViewStatus,
  postCandidateNotes,
  setCandidateRejectStatus,
  unlockCandidateResume,
  downloadResume,
  sendMail,
  sendBulkMails,
  addNewDripToConversation,
  sendMessage,
  fetchBulkContacts,
  sendBulkMessages,
  fetchCandidateDetailsforV2Version,
  recommendCandidate,
  bulkCandidateRecommend,
  fetchCandidateSourceCount,
  fetchBulkCandidateNotes,
  fetchBulkCandidateAllNotes,
  resetCandidates,
  setFetchCandidatesFlag,
  bulkPublishCandidates,
  updateBulkConnectStatus,
  fetchConversationIds,
  fetchCandidateIntel,
  fetchTryNowCandidates,
  fetchTryNowCandidateDetails,
  setCandidateListData,
  setBulkContactStatus,
  fetchCandidateRank,
  fetchCandidateSpecifications,
  clearCandidatesExtraInfo,
  fetchTagsForCandidate,
  createCandidateNote,
  deleteCandidateNote,
  updateCandidateNote,
  getAllCandidateNotes,
  addTagForCandidateNote,
  updateCandidateBookmarkStatus,
  fetchCandidateGlobalTags,
  createCandidateTag,
  bulkCandidateTransfer,
  bulkCandidateRemove,
  bulkCandidateShortlist,
  fetchCandidateSegmentsAndCampaigns,
  fetchCandidateJobs,
  appendCandidateJobs,
  appendCandidateSegmentsAndCampaigns,
  deleteTagFromCandidateNote,
  deleteTagForCandidate,
  tryFetchManualSearchCandidatesForSource,
  fetchCandidateMatchingJobs,
  updateCandidateViewStatus,
  fetchCandidateResume,
  resetCandidateJobs,
  fetchAggregations,
  setCandidateJobMatchingListSearchTerm,
  setCandidateMatchingJobFilters,
  fetchAppliedBucketScoringStatus,
  bulkSegmentCandidateRecommend,
  recommendFavouriteCandidate,
  publishedCandidates,
  removeFavouriteCandidate,
  setMspIcons,
  sendCandidateResponse,
  setCandidatesMoveFlag,
};
