import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Form, Row, Col, Divider, Button, Dropdown, Affix, Alert } from 'antd';
import debounce from 'lodash/debounce';
import GlobalInsightList from './InsightList/GlobalInsightList';
import GlobalInsightLocationFilter from './Filter/GlobalInsightLocationFilter';
import GlobalInsightSupplyMenuList from './Filter/GlobalInsightSupplyMenuList';
import GlobalInsightSupplyFilter from './Filter/GlobalInsightSupplyFilter';
import { menuList } from '../../Utils/GlobalInsightSupplyUtil';
import { FilterIcon } from '../../Icons/AryaIcons';
import { getJobUtilities } from '../../Reducers/JobReducer';
import * as JobActions from '../../Actions/JobActions';
import RoleInsightView from './InsightList/RoleInsightView';
import CareerAssessmentFilter from './Filter/CareerAssessmentFilter';
import RoleComparisionView from './InsightList/RoleComparisionView';
import { getMapingStatsList } from '../../Utils/DashBoardUtils';
import { generatePdf } from '../../Utils/PdfGeneratorUtils';
import './GlobalInsightSupplyComponent.scss';
import jobMessage from '../JobForm/JobMessages';
import styles from './InsightList/Insight.module.scss';

const initialValues = {
  filterFormValues: {
    Companies: [],
    Industries: [],
    Skills: [],
    Educations: [],
    Experiences: [],
  },
  filterActiveSwitchKeys: ['Skills', 'Industries', 'Companies', 'Experiences', 'Educations'],
};

const defaultProps = {
  globalInsightFetchPayload: {
    Title: '',
    TitleSynonyms: [],
    CountryCode: '',
    State: '',
    City: '',
    Distance: '',
    Companies: [],
    Industries: [],
    Skills: [],
    Educations: [],
    Experiences: [],
    TitlesCount: 50,
    CompaniesCount: 50,
    IndustriesCount: 50,
    SkillsCount: 50,
    CertificationsStats: [],
    CertificationsCount: 50,
  },
};

const mapStateToProps = state => ({
  utilities: getJobUtilities(state),
});
const mapDispatchToProps = {
  fetchIndustries: JobActions.fetchIndustries,
};

function GlobalInsightSupplyComponent(props = defaultProps) {
  const [filterFormValues, setFilterFormValues] = useState(initialValues.filterFormValues);
  const [filterActiveSwitchKeys, setFilterActiveSwitchKeys] = useState(initialValues.filterActiveSwitchKeys);
  const [locationInput, setLocationInput] = useState('');
  const [stateInput, setStateInput] = useState('');
  const [dropDownVisibility, setDropDownVisibility] = useState(false);
  const [alertVisibility, setAlertVisibility] = useState(false);

  const {
    form,
    listCountries,
    listStates,
    locations,
    fetchCitySuggestions,
    fetchStateSuggestions,
    fetchGlobalInsightSupply,
    globalInsightFetchPayload,
    clearLocations,
    resetPage,
    supplyPayload,
    globalInsightSupplyList,
    citiesApiStatus,
    statesApiStatus,
    globalSupplyApiStatus,
    isGlobalInsightLandingPage,
    prepopulatedJobDetails,
    fetchProjection,
    fetchAssessment,
    projectionPayload,
    assessmentPayload,
    projectionList,
    assessmentList,
    projectionApiStatus,
    assessmentApiStatus,
    isCandidateSupply,
    isCareerProjection,
    utilities,
    fetchIndustries,
  } = props;

  const filterCities = (value, states) => {
    setLocationInput(value);
    const country = form.getFieldValue('CountryCode');
    const state = _.find(states, { state: { name: form.getFieldValue('State') } });
    if (country && !_.isEmpty(state)) {
      fetchCitySuggestions({
        country,
        state,
        searchTerm: value,
      });
    }
  };

  const filterStates = value => {
    setStateInput(value);
    const country = form.getFieldValue('CountryCode');
    if (country) {
      fetchStateSuggestions({
        country,
        searchTerm: value,
      });
    }
  };

  const debouncedFilterStates = useCallback(
    debounce(nextValue => filterStates(nextValue), 600),
    []
  );

  const debouncedFilterCities = useCallback(
    debounce((nextValue, states) => filterCities(nextValue, states), 600),
    []
  );

  const fetchGlobalInsightSupplyList = (currentGlobalInsightFetchPayload, advFilterFormValues) => {
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const formValues = _.cloneDeep(values);
        if (
          formValues.Role?.trim() ||
          formValues.Skills?.length > 0 ||
          formValues.Industries?.length > 0 ||
          formValues.Companies?.length > 0
        ) {
          setAlertVisibility(false);
          let combinedFetchPayload = { ...currentGlobalInsightFetchPayload, ...advFilterFormValues };
          combinedFetchPayload = {
            ...combinedFetchPayload,
            Title: formValues.Role,
            CountryCode: formValues.CountryCode,
            State: formValues.State,
            City: formValues.Location,
            Distance: formValues.Location ? formValues.Distance : undefined,
            searchSkills: formValues.Skills,
            searchIndustries: formValues.Industries,
            searchCompanies: formValues.Companies,
          };
          fetchGlobalInsightSupply(combinedFetchPayload);
        } else {
          setAlertVisibility(true);
        }
      }
    });
  };

  const supplyFilterHandle = (formValues, activeSwitchKeys, currentGlobalInsightFetchPayload) => {
    setFilterFormValues(formValues);
    setFilterActiveSwitchKeys(activeSwitchKeys);
    setDropDownVisibility(false);
    fetchGlobalInsightSupplyList(currentGlobalInsightFetchPayload, formValues);
  };

  const supplySearchHandle = currentGlobalInsightFetchPayload => {
    form.setFieldsValue({
      filterSkills: [],
      filterIndustries: [],
      filterCompanies: [],
      filterExperience: [],
      filterEducations: [],
    });
    setFilterFormValues(initialValues.filterFormValues);
    setFilterActiveSwitchKeys(initialValues.filterActiveSwitchKeys);
    fetchGlobalInsightSupplyList(currentGlobalInsightFetchPayload, initialValues.filterFormValues);
  };

  const projectionSearchHandle = () => {
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const formValues = _.cloneDeep(values);
        if (formValues.Role) {
          const fetchPayload = {
            Title: formValues.Role,
            CountryCode: formValues.CountryCode,
            State: formValues.State,
            City: formValues.Location,
          };
          fetchProjection(fetchPayload);
        }
      }
    });
  };

  const assessmentSearchHandle = (skills, prescribedRole) => {
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const formValues = _.cloneDeep(values);
        if (formValues.Role && prescribedRole && prescribedRole.toUpperCase() !== formValues.Role.toUpperCase()) {
          const fetchPayload = {
            ContenderTitle: formValues.Role,
            Skills: skills,
            PrescribedTitle: prescribedRole,
          };
          fetchAssessment(fetchPayload);
        }
      }
    });
  };

  const handleDropdownVisibleChange = flag => {
    setDropDownVisibility(flag);
  };

  const handleReset = () => {
    form.resetFields();
    clearLocations({ city: true, state: true });
    setFilterFormValues(initialValues.filterFormValues);
    setFilterActiveSwitchKeys(initialValues.filterActiveSwitchKeys);
    form.setFieldsValue({
      Role: undefined,
      Skills: undefined,
      Industries: undefined,
      Companies: undefined,
      CountryCode: 'US',
      State: undefined,
      City: undefined,
      Distance: 50,
    });
    resetPage();
  };

  const handleCountryChange = () => {
    form.setFieldsValue({
      Location: undefined,
      State: undefined,
      Distance: 50,
    });
    setLocationInput('');
    setStateInput('');
    clearLocations({
      city: true,
      state: true,
    });
  };

  const stateChange = () => {
    form.setFieldsValue({
      Location: undefined,
      Distance: 50,
    });
    setLocationInput('');
    clearLocations({
      city: true,
    });
  };

  const cityChange = () => {
    form.setFieldsValue({
      Distance: 50,
    });
  };

  let locationDropDownStatus = 'Type to search';
  if (locationInput.length) {
    locationDropDownStatus =
      citiesApiStatus === 'INPROGRESS' ? (
        <FormattedMessage {...jobMessage.loadingLabelD} />
      ) : (
        <FormattedMessage {...jobMessage.noLocationFoundLabel} />
      );
  }
  let statesDropDownStatus = 'Type to search';
  if (stateInput.length) {
    statesDropDownStatus =
      statesApiStatus === 'INPROGRESS' ? (
        <FormattedMessage {...jobMessage.loadingLabelD} />
      ) : (
        <FormattedMessage {...jobMessage.noLocationFoundLabel} />
      );
  }
  const globalInsightLoading = globalSupplyApiStatus === 'INPROGRESS';

  const emptyInsightDescription = sectionName => {
    return <FormattedMessage {...jobMessage.emptyInsightLabel} values={{ sectionName }} />;
  };

  const getSubTitleOfPdf = () => {
    const companies = form.getFieldValue('Companies');
    const city = form.getFieldValue('Location');
    const state = form.getFieldValue('State');
    const countrycode = form.getFieldValue('CountryCode');

    let subTitle = '';
    if (companies) if (companies.length > 0) subTitle += `${companies}, `;
    if (city) subTitle += `${city}, `;
    if (state) subTitle += `${state}, `;
    if (countrycode) subTitle += `${countrycode}`;
    else subTitle = ' ';
    return subTitle;
  };

  const [isLoading, setIsloading] = React.useState(false);

  const handleTalentLandScapeDownloadFail = loading => {
    setIsloading(loading);
  };

  const handleTalentLandScapeDownloadClick = async (elementIds, exclusionClassNames) => {
    setIsloading(true);
    let jobTitle = form.getFieldValue('Role') ? form.getFieldValue('Role') : ' ';
    if (!jobTitle) jobTitle = ' ';
    await generatePdf(jobTitle, getSubTitleOfPdf(), elementIds, handleTalentLandScapeDownloadFail, exclusionClassNames);
    setIsloading(false);
  };

  const insights = [
    'Supply-Industries',
    'Supply-Roles',
    'Supply-Companies',
    'Supply-Skills',
    'Supply-Experience',
    'Supply-Location',
    'Supply-Education',
    'Supply-Certifications',
  ];

  const exclusionClassNames = [styles.headerButton];

  const advanceFilterDropdown = (
    <GlobalInsightSupplyFilter
      form={form}
      globalInsightSupplyList={globalInsightSupplyList}
      supplyFilterHandle={(formValues, activeSwitchKeys) =>
        supplyFilterHandle(formValues, activeSwitchKeys, globalInsightFetchPayload)
      }
      supplyPayload={filterFormValues}
      filterActiveSwitchKeys={filterActiveSwitchKeys}
      handleDropdownVisibleChange={handleDropdownVisibleChange}
    />
  );

  if (isCandidateSupply) {
    return (
      <Form hideRequiredMark autoComplete="off" className="supplySearch-form">
        {alertVisibility ? (
          <Alert message={<FormattedMessage {...jobMessage.selectFieldAlertLabel} />} type="error" showIcon span={10} />
        ) : null}
        <GlobalInsightLocationFilter
          form={form}
          supplyPayload={supplyPayload}
          handleReset={handleReset}
          fetchGlobalInsightSupplyList={() => supplySearchHandle(globalInsightFetchPayload)}
          handleCountryChange={handleCountryChange}
          stateChange={stateChange}
          cityChange={cityChange}
          filterCities={value => debouncedFilterCities(value, listStates)}
          filterStates={debouncedFilterStates}
          statesDropDownStatus={statesDropDownStatus}
          locationDropDownStatus={locationDropDownStatus}
          listCountries={listCountries}
          listStates={listStates}
          locations={locations}
          utilities={utilities}
          fetchIndustries={fetchIndustries}
          isGlobalInsightLandingPage={isGlobalInsightLandingPage}
          prepopulatedJobDetails={prepopulatedJobDetails}
          isCandidateSupply
        />
        <Divider className="supplySearch-divider" />
        {isGlobalInsightLandingPage ? null : (
          <div>
            <Row gutter={16}>
              <Col span={4}>
                <GlobalInsightSupplyMenuList menuItems={menuList} />
                <Affix offsetTop={370}>
                  <Dropdown
                    disabled={globalInsightLoading || isGlobalInsightLandingPage}
                    overlay={advanceFilterDropdown}
                    overlayClassName="advance-filter-menu"
                    trigger={['click']}
                    arrow
                    visible={dropDownVisibility}
                    onVisibleChange={handleDropdownVisibleChange}
                  >
                    <Button
                      style={{
                        width: '100%',
                        maxWidth: '147px',
                        float: 'right',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                      shape="round"
                    >
                      <FilterIcon style={{ fontSize: '16px' }} />
                      <FormattedMessage {...jobMessage.advanceFilterLabel} />{' '}
                    </Button>
                  </Dropdown>
                </Affix>
              </Col>
              <Col span={18}>
                <div>
                  <Button
                    loading={isLoading}
                    disabled={globalInsightLoading}
                    className="Global-insight-supply-button"
                    type="primary"
                    onClick={() => handleTalentLandScapeDownloadClick(insights, exclusionClassNames)}
                  >
                    <FormattedMessage {...jobMessage.downloadLandscapeLabel} />
                  </Button>
                </div>
                <GlobalInsightList
                  globalInsightSupplyList={globalInsightSupplyList}
                  supplyPayload={supplyPayload}
                  emptyInsightDescription={emptyInsightDescription}
                  loading={globalInsightLoading}
                />
              </Col>
            </Row>
          </div>
        )}
      </Form>
    );
  }

  if (isCareerProjection) {
    const projectionLoading = projectionApiStatus === 'INPROGRESS';
    const projectionCompleted = projectionApiStatus === 'COMPLETED';
    const { SimilarTitlesStats, Total } = projectionList?.CareerDistributionStats;
    const { FutureTitles } = projectionList?.Predictions?.Title;
    const { Skills } = projectionList;
    const listOfSimilarTitlesStats = getMapingStatsList(SimilarTitlesStats, 'SimilarTitle', Total);
    const alertMessage = `Leoforce doesn't have enough information about the given job title. Please provide a more recognizable title. For example: "Customer Service Representative",  "Software Developer", etc..${
      Total ? ` or choose a title from the below similar roles.` : ''
    }`;
    return (
      <Form hideRequiredMark autoComplete="off" className="supplySearch-form">
        <GlobalInsightLocationFilter
          form={form}
          supplyPayload={projectionPayload}
          handleReset={handleReset}
          fetchCareerProjectionList={projectionSearchHandle}
          handleCountryChange={handleCountryChange}
          stateChange={stateChange}
          cityChange={cityChange}
          filterCities={value => debouncedFilterCities(value, listStates)}
          filterStates={debouncedFilterStates}
          statesDropDownStatus={statesDropDownStatus}
          locationDropDownStatus={locationDropDownStatus}
          listCountries={listCountries}
          listStates={listStates}
          locations={locations}
          utilities={utilities}
          fetchIndustries={fetchIndustries}
          isGlobalInsightLandingPage={isGlobalInsightLandingPage}
          isCareerProjection
        />
        {projectionCompleted && Skills.length === 0 && !isGlobalInsightLandingPage ? (
          <Alert message={alertMessage} type="warning" showIcon />
        ) : null}
        <Divider className="supplySearch-divider" />
        {isGlobalInsightLandingPage ? null : (
          <div>
            <Row gutter={16}>
              <Col span={24}>
                <RoleInsightView
                  listOfSimilarTitlesStats={listOfSimilarTitlesStats}
                  loading={projectionLoading}
                  displayCount={20}
                />
              </Col>
            </Row>
            <Row gutter={16} className="assessment-content">
              <Col>
                <CareerAssessmentFilter
                  form={form}
                  futureTitles={FutureTitles}
                  isValidTitle={Skills.length > 0}
                  fetchCareerAssessment={prescribedRole => assessmentSearchHandle(Skills, prescribedRole)}
                  loading={projectionLoading}
                />
              </Col>
              <Col className="role-comparision">
                <RoleComparisionView
                  assessmentPayload={assessmentPayload}
                  assessmentList={assessmentList}
                  assessmentApiStatus={assessmentApiStatus}
                />
              </Col>
            </Row>
          </div>
        )}
      </Form>
    );
  }

  return null;
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(GlobalInsightSupplyComponent));
export { GlobalInsightSupplyComponent as GlobalInsightSupplyComponentWithoutForm };
