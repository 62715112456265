import { Skeleton } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import qs from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import * as candidateActions from '../../Actions/CandidateActions';
import * as manualSearchActions from '../../Actions/ManualSearchActions';
import * as sourcingStatsAction from '../../Actions/SourcingStatsActions';
import {
  createSubSegment,
  fetchSubSegments,
  setSubsegmentCreateModalStatus,
  setSubsegmentPaginationData,
} from '../../Actions/SubSegmentActions';
import CandidateRejectCardContainer from '../../Containers/CandidateRejectCard/CandidateRejectCardContainer';
import SubsegmentModal from '../../Containers/SubSegment/SubsegmentModal';
import {
  getApiStatus,
  getFavouriteCandidateApiStatus,
  getRemoveFavouriteCandidateApiAtatus,
} from '../../Reducers/ApiStatusReducer';
import { getManualSearchCriteria } from '../../Reducers/ManualSearchReducer';
import {
  getAddModalVisbilityStatus,
  getCreateModalVisbilityStatus,
  getSelectedSubsegmentId,
  getSubsegmentPaginationData,
} from '../../Reducers/SubsegmentReducer';
import { getAdminFeatures } from '../../Reducers/UserReducer';
import { getCurrentUser } from '../../Reducers/UserSessionReducer';
import { getCandidateStatus } from '../../Utils/CandidateDrawerUtils';
import {
  getCandidatePrimarySourceNameToDisplay,
  getShouldNavigateToSubsegmentRedirectionTab,
} from '../../Utils/CandidateListUtils';
import { getCandidatePublishStatus } from '../../Utils/CandidatePublishUtils';
import {
  getCandidateOriginalSourceName,
  getCandidateSourceName,
  isInternalIconVisible,
} from '../../Utils/DownloadedCandidatesSourceUtils';
import FavouriteCandidateStarIcon from '../../Utils/FavouriteCandidateStarIcon';
import { getSourceDisplayName } from '../../Utils/SourceUtils';
import AlertMessage from '../AlertMessage/AlertMessage';
import NewCandidateShortlistReject from '../CandidateCard/NewCandidateShortlistReject/NewCandidateShortlistReject';
import SubSegmentCandidateSourcedShortlistReject from '../CandidateCard/NewCandidateShortlistReject/SubSegmentCandidateSourcedShortlistReject';
import CandidateRejectCard from '../CandidateRejectCard/CandidateRejectCard';
import CandidateViewTabs from '../CandidateView/CandidateViewTabs';
import EnhancedCandidate360SecondaryInfoSection from '../EnhancedCandidate360SecondaryInfo/EnhancedCandidate360SecondaryInfoSection';
import styles from './Candidate360ViewWrapper.module.scss';
import EnhancedCandidateInformationCard from './EnhancedCandidateInformationCard';
import messages from '../CandidateCard/CandidateCardMessages';
import { JobUsageBudgetDataByJobId } from '../../Reducers/JobUsageBudgetReducer';

function Candidate360ViewWrapper(props) {
  const {
    candidate = {},
    openCandidateView,
    currentJobDetails,
    connectInfo,
    candidateType,
    onCandidateStatusChange,
    onCandidateReject,
    candidateContext,
    featureToggleList,
    jobId,
    keywordsToHighlight,
    mustHavesKeywords,
    isTryNow,
    downloadResume,
    allowResumeDownload,
    version,
    jobGuid,
    candidateBookmarkApiStatus,
    updateCandidateBookmarkStatus,
    candidateDetailsStatus,
    jobCountryCode,
    toggleBotConfigDrawer,
    tabName,
    onCandidate360TabChange,
    appName,
    isDiversityAttributesVisible,
    activeSubTab,
    onSubTabClick,
    isCandidateViewHeaderVisible,
    setCandidateViewHeaderVisibility,
    candidateListStatus,
    maskingConfig,
    fetchCandidateMatchingJobs,
    ignoreSimilar,
    userConfig,
    isJobActionsAllowed,
    unlockCandidateResume,
    resumeUnlockStatus,
    selectPlan,
    creditsRefunded,
    history,
    candidateJobsTotalCount,
    fetchCandidateJobsApiStatus,
    whiteLabelInfo,
    revealActiveChannelSourceName,
    pushCandToAtsStatus,
    atsConfig,
    showPushError,
    showContactInfoButton,
    onClickAtsPush,
    showPushCandidateButton,
    openInNewTab,
    isCandidateMatchingJobModalVisible,
    setCandidateMatchingJobModalVisiblity,
    isCandidateDownloaded,
    onPushCandToAts,
    notesContainer,
    onNotesChange,
    currentNotes,
    candidateId,
    postCandidateNotes,
    usersById,
    connectUsersById,
    showCandidateDetailTabs,
    openSipCallWindowsApp,
    activeTab,
    activeSourceName,
    candidateStatusUpdate,
    candidateSegmentsTotalCount,
    bulkNotesFetchApiStatus,
    fetchBulkCandidateAllNotes,
    candidateIntel,
    fetchCandidateIntel,
    productVariantsById,
    jobsById,
    composeEmailType,
    setComposeEmailType,
    openSegmentAtsView,
    openJobViewInNewTabCallBack,
    onClickAddJobCallBack,
    fetchManualSearchDraftCriteria,
    isConnectTab,
    recommendFavouriteCandidate,
    adminFeatures,
    removeFavouriteCandidate,
    favouriteCandidateApiStatus,
    removeFavouriteCandidateApiAtatus,
    fetchCandidates,
    defaultFilter,
    fetchJobSourcingStats,
    setAddSubsegmentModalStatus,
    addSubsegmentModalStatus,
    setSubsegmentModalStatus,
    currentUser,
    createSubsegmentApiStatus,
    subsegmentPaginationData,
    createSubSegmentAction,
    setSubsegmentPagination,
    fetchSubSegmentsAction,
    subsegmentCreateModalStatus,
    mspIcons,
    setMspIcons,
    selectedSubSegmentId,
    jobLevelCreditsData,
    createCandidateNote,
    currentPageCandidates,
    candidatesNotesList,
    setCandidateNotePopupAction,
    candidateNoteCreateApiStatus,
  } = props;

  const [candidateJobMatchingInitialAggregation, setCandidateJobMatchingInitialAggregation] = React.useState({});
  const [isFindMathingJobClicked, setIsFindMathingJobClicked] = React.useState(false);
  const isAdvancedSearchV2Enabled = featureToggleList.AdvanceSearchV2.IsEnabled;
  const candidateHeaderRef = React.useRef(null);
  const candidateHeaderHeight = candidateHeaderRef?.current?.clientHeight;
  const connectStatuses = connectInfo?.ConnectStatuses ?? {};
  const connectStatus = connectStatuses?.[candidate?.PersonId] ?? {};
  const isPaidJobServiceEnabled = _.get(featureToggleList, ['PaidJobService', 'IsEnabled'], false);
  const isCandidatePublishEnabled = getCandidatePublishStatus(adminFeatures, featureToggleList, candidateContext);
  const isJobUsageBudgetFeatureEnable = _.get(featureToggleList, ['JobUsageBudget', 'IsEnabled'], false);

  const {
    showSettingsLink: isSettingsLink,
    Message: alertMessage,
    ErrorDescription,
    showMessageAsWarning,
  } = candidate || {};
  const isAlertMessage = !!alertMessage;
  const showSettingsLink = isSettingsLink && version !== 'ats';
  const description =
    isJobUsageBudgetFeatureEnable && jobLevelCreditsData?.AvailableBudget === 0 ? (
      <FormattedMessage {...messages.jobLevelErrorMessage} />
    ) : (
      ErrorDescription
    );
  const alertType = showMessageAsWarning === true ? 'warning' : 'error';

  const { showContactTab, connectionStatus, redirectTo } = qs.parse(window.location.search);
  const isContactTabRedirection =
    (showContactTab?.trim().toLowerCase() === 'true' ||
      connectionStatus?.trim().toLowerCase() === 'connected' ||
      redirectTo?.trim().toLowerCase()) &&
    !isCandidatePublishEnabled;
  React.useEffect(() => {
    if (isContactTabRedirection) {
      onCandidate360TabChange('contact');
    }
  }, []);

  React.useEffect(() => {
    if (candidateDetailsStatus === 'COMPLETED') fetchCandidateIntel(jobId, candidateId);
  }, [candidateId, candidateDetailsStatus]);

  React.useEffect(() => {
    if (openInNewTab) fetchManualSearchDraftCriteria({ jobId });
  }, [openInNewTab]);

  const onClick360TabChange = activeTabName => {
    if (activeTabName !== 'jobs') {
      setCandidateMatchingJobModalVisiblity(false);
    }
    onCandidate360TabChange(activeTabName);
  };
  const status = candidate?.Status;
  const isAddVisible =
    candidateContext === 'job' ||
    (candidateContext === 'segment' &&
      (status ? status.toLowerCase() === 'sourced' || status.toLowerCase() === 'rejected' : true));
  const isDeleteVisible =
    candidateContext === 'job' ||
    (candidateContext === 'segment' &&
      status &&
      (status.toLowerCase() === 'sourced' || status.toLowerCase() === 'shortlisted'));
  const isCandidateIntelVisible = candidateContext === 'job' && featureToggleList.CandidateIntel?.IsEnabled;
  const {
    CandidateCRM: { IsEnabled: isSegmentAndCampaignsTabVisible },
  } = featureToggleList;
  const onClickFindMatchingJob = value => {
    onCandidate360TabChange(value);
  };
  const isCandidateMatchingJobsEnabled = featureToggleList.CandidateMatchingJobs.IsEnabled;
  const isSRPulseUser = featureToggleList?.WhiteGloveServiceProgress?.IsEnabled;
  const isInternalIcon = isInternalIconVisible(candidate, isSRPulseUser, revealActiveChannelSourceName);
  const internalOriginalSource =
    getCandidateSourceName(candidate, revealActiveChannelSourceName) === 'internal'
      ? getCandidateOriginalSourceName(candidate.Sources)
      : false;

  const candidateHiddenHeaderStyle = isCandidateViewHeaderVisible ? {} : { marginTop: -candidateHeaderHeight };

  const candidatePrimaryInfoStyle = { minWidth: '35%' };
  const candidateSecondaryInfoStyle = { width: '100%' };
  const { ShowVaultName: shouldShowVaultName } = userConfig;
  const showVaultName = shouldShowVaultName ?? false;
  const { candidateSourceName } = getCandidatePrimarySourceNameToDisplay(
    candidate,
    version,
    showVaultName,
    whiteLabelInfo,
    revealActiveChannelSourceName,
    userConfig
  );
  const candidateOriginalSourceName = internalOriginalSource
    ? getSourceDisplayName(
        internalOriginalSource,
        userConfig,
        showVaultName,
        {},
        whiteLabelInfo,
        revealActiveChannelSourceName
      )
    : null;
  const { candidateStatus } = getCandidateStatus(
    candidate,
    featureToggleList,
    jobGuid,
    atsConfig,
    currentUser,
    userConfig
  );
  const ConditionalCandidateRejectCard =
    isAdvancedSearchV2Enabled && !isConnectTab ? (
      <CandidateRejectCardContainer
        key={_.get(candidate, 'Id')}
        candidate={candidate}
        onCandidateReject={onCandidateReject}
        onCandidateStatusChange={onCandidateStatusChange}
        ignoreSimilar={ignoreSimilar}
        size="small"
        isPaidJobServiceEnabled={isPaidJobServiceEnabled}
        isCandidateListRejectCard
        jobId={jobId}
        candidateContext={candidateContext}
        openInNewTab={openInNewTab}
        featureToggleList={featureToggleList}
      />
    ) : (
      <CandidateRejectCard
        key={_.get(candidate, 'Id')}
        candidate={candidate}
        onCandidateReject={onCandidateReject}
        onCandidateStatusChange={onCandidateStatusChange}
        ignoreSimilar={ignoreSimilar}
        size="small"
        isPaidJobServiceEnabled={isPaidJobServiceEnabled}
        isCandidateListRejectCard
        jobId={jobId}
        candidateContext={candidateContext}
        openInNewTab={openInNewTab}
        featureToggleList={featureToggleList}
        isConnectTab={isConnectTab}
      />
    );
  const msp = history?.location?.pathname.includes('msp');

  const { shouldNavigateToSubsegment, targetJobId } = getShouldNavigateToSubsegmentRedirectionTab(
    history?.location,
    featureToggleList
  );

  const favouriteCandidateStarIcon = isCandidatePublishEnabled ? (
    <FavouriteCandidateStarIcon
      candidate={candidate}
      recommendFavouriteCandidate={recommendFavouriteCandidate}
      userConfig={userConfig}
      activeTab={activeTab}
      jobId={jobId}
      favouriteCandidateApiStatus={favouriteCandidateApiStatus}
      removeFavouriteCandidate={removeFavouriteCandidate}
      removeFavouriteCandidateApiAtatus={removeFavouriteCandidateApiAtatus}
      defaultFilter={defaultFilter}
      fetchCandidates={fetchCandidates}
      fetchJobSourcingStats={fetchJobSourcingStats}
    />
  ) : null;

  const candidateShortListComponent = shouldNavigateToSubsegment ? (
    <SubSegmentCandidateSourcedShortlistReject
      candidate={candidate}
      jobId={targetJobId}
      openInNewTab={openInNewTab}
      zIndex={11111}
    />
  ) : (
    <NewCandidateShortlistReject
      isPaidJobServiceEnabled={isPaidJobServiceEnabled}
      activeTab={activeTab}
      featureToggleList={featureToggleList}
      onCandidateStatusChange={onCandidateStatusChange}
      candidateContext={candidateContext}
      isAddVisible={isAddVisible}
      isDeleteVisible={isDeleteVisible}
      candidateStatusUpdate={candidateStatusUpdate}
      candidate={candidate}
      size="medium"
      onCandidateReject={onCandidateReject}
      jobId={jobId}
      candidateId={candidateId}
      className={styles.enhancedShortlistContainer}
      version={version}
      msp={msp}
      mspIcons={mspIcons}
      setMspIcons={setMspIcons}
      setAddSubsegmentModalStatus={setAddSubsegmentModalStatus}
      selectedSubSegmentId={selectedSubSegmentId}
      activeSourceName={activeSourceName}
    />
  );

  return (
    <div className={styles.candidateView}>
      {isAlertMessage ? (
        <AlertMessage
          message={alertMessage}
          description={description}
          showSettingsLink={showSettingsLink}
          type={alertType}
          history={history}
          isClosable
        />
      ) : null}

      <div
        className={classNames(styles.candidateHeader, {
          [styles.hideCandidateHeader]: !isCandidateViewHeaderVisible,
        })}
        style={candidateHiddenHeaderStyle}
        ref={candidateHeaderRef}
      >
        <Skeleton
          active
          paragraph={{ rows: 3 }}
          loading={candidateDetailsStatus === 'INPROGRESS' || candidateListStatus === 'INPROGRESS'}
        >
          {candidateContext === 'segment' || !candidate.drawerRejectFlag ? (
            <>
              <div className={styles.candidatePrimaryInfo} style={candidatePrimaryInfoStyle}>
                {candidateStatus}
                <EnhancedCandidateInformationCard
                  candidate={candidate}
                  userConfig={userConfig}
                  version={version}
                  whiteLabelInfo={whiteLabelInfo}
                  featureToggleList={featureToggleList}
                  openCandidateView={openCandidateView}
                  candidateContext={candidateContext}
                  jobId={jobId}
                  updateCandidateBookmarkStatus={updateCandidateBookmarkStatus}
                  candidateType={candidateType}
                  candidateBookmarkApiStatus={candidateBookmarkApiStatus}
                  status={status}
                  isCandidateMatchingJobsEnabled={isCandidateMatchingJobsEnabled}
                  isCandidateMatchingJobModalVisible={isCandidateMatchingJobModalVisible}
                  fetchCandidateMatchingJobs={fetchCandidateMatchingJobs}
                  onClickFindMatchingJob={onClickFindMatchingJob}
                  setCandidateMatchingJobModalVisiblity={setCandidateMatchingJobModalVisiblity}
                  setCandidateJobMatchingInitialAggregation={setCandidateJobMatchingInitialAggregation}
                  maskingConfig={maskingConfig}
                  showCandidateDetailTabs={showCandidateDetailTabs}
                  availableProviders={connectStatus?.Contact?.AvailableProviders}
                  contact={connectStatus?.Contact ?? {}}
                  onCandidate360TabChange={onCandidate360TabChange}
                  currentJobDetails={currentJobDetails}
                  onPushCandToAts={onPushCandToAts}
                  pushCandToAtsStatus={pushCandToAtsStatus}
                  atsConfig={atsConfig}
                  onClickAtsPush={onClickAtsPush}
                  isCandidateDownloaded={isCandidateDownloaded}
                  showContactInfoButton={showContactInfoButton}
                  showPushCandidateButton={showPushCandidateButton}
                  showPushError={showPushError}
                  openInNewTab={openInNewTab}
                  isTryNow={isTryNow}
                  isInternalIcon={isInternalIcon}
                  internalOriginalSource={internalOriginalSource}
                  candidateSourceName={candidateSourceName}
                  candidateOriginalSourceName={candidateOriginalSourceName}
                  isAlertMessage={isAlertMessage}
                  isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
                  candidateListStatus={candidateListStatus}
                  candidateDetailsStatus={candidateDetailsStatus}
                  keywordsToHighlight={keywordsToHighlight}
                  mustHavesKeywords={mustHavesKeywords}
                  activeSourceName={activeSourceName}
                  activeTab={activeTab}
                  setIsFindMathingJobClicked={setIsFindMathingJobClicked}
                  adminFeatures={adminFeatures}
                  currentUser={currentUser}
                />
              </div>
              <div className={styles.candidateSecondaryInfo} style={candidateSecondaryInfoStyle}>
                <EnhancedCandidate360SecondaryInfoSection
                  candidate={candidate}
                  keywordsToHighlight={keywordsToHighlight}
                  mustHavesKeywords={mustHavesKeywords}
                  activeTab={activeTab}
                  activeSourceName={activeSourceName}
                  isDiversityAttributesVisible={isDiversityAttributesVisible}
                  candidateContext={candidateContext}
                  jobId={jobId}
                  candidateDetailsStatus={candidateDetailsStatus}
                />
              </div>
              <div className={styles.candidateActions}>
                {!isCandidatePublishEnabled ? candidateShortListComponent : favouriteCandidateStarIcon}
              </div>
            </>
          ) : (
            ConditionalCandidateRejectCard
          )}
        </Skeleton>
      </div>
      <div className={styles.candidateBody}>
        <CandidateViewTabs
          candidate={candidate}
          jobId={jobId}
          keywordsToHighlight={keywordsToHighlight}
          mustHavesKeywords={mustHavesKeywords}
          isTryNow={isTryNow}
          downloadResume={downloadResume}
          allowResumeDownload={allowResumeDownload}
          version={version}
          jobGuid={jobGuid}
          currentJobDetails={currentJobDetails}
          candidateDetailsStatus={candidateDetailsStatus}
          jobCountryCode={jobCountryCode}
          toggleBotConfigDrawer={toggleBotConfigDrawer}
          onCandidate360TabChange={onClick360TabChange}
          tabName={tabName}
          candidateType={candidateType}
          isCandidateIntelVisible={isCandidateIntelVisible}
          candidateContext={candidateContext}
          appName={appName}
          activeSubTab={activeSubTab}
          onSubTabClick={onSubTabClick}
          isCandidateViewHeaderVisible={isCandidateViewHeaderVisible}
          setCandidateViewHeaderVisibility={setCandidateViewHeaderVisibility}
          candidateListStatus={candidateListStatus}
          isSegmentAndCampaignsTabVisible={isSegmentAndCampaignsTabVisible}
          isCandidateMatchingJobModalVisible={isCandidateMatchingJobModalVisible}
          setCandidateMatchingJobModalVisiblity={setCandidateMatchingJobModalVisiblity}
          isJobActionsAllowed={isJobActionsAllowed}
          unlockCandidateResume={unlockCandidateResume}
          resumeUnlockStatus={resumeUnlockStatus}
          featureToggleList={featureToggleList}
          connectInfo={connectStatus}
          selectPlan={selectPlan}
          creditsRefunded={creditsRefunded}
          candidateJobsTotalCount={candidateJobsTotalCount}
          fetchCandidateJobsApiStatus={fetchCandidateJobsApiStatus}
          notesContainer={notesContainer}
          onNotesChange={onNotesChange}
          currentNotes={currentNotes}
          candidateId={candidateId}
          postCandidateNotes={postCandidateNotes}
          usersById={usersById}
          connectUsersById={connectUsersById}
          showCandidateDetailTabs={showCandidateDetailTabs}
          openSipCallWindowsApp={openSipCallWindowsApp}
          isContactTabRedirection={isContactTabRedirection}
          activeTab={activeTab}
          activeSourceName={activeSourceName}
          candidateJobMatchingInitialAggregation={candidateJobMatchingInitialAggregation}
          candidateSegmentsTotalCount={candidateSegmentsTotalCount}
          bulkNotesFetchApiStatus={bulkNotesFetchApiStatus}
          fetchBulkCandidateAllNotes={fetchBulkCandidateAllNotes}
          candidateIntel={candidateIntel}
          isAlertMessage={isAlertMessage}
          productVariantsById={productVariantsById}
          jobsById={jobsById}
          composeEmailType={composeEmailType}
          setComposeEmailType={setComposeEmailType}
          openSegmentAtsView={openSegmentAtsView}
          openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
          onClickAddJobCallBack={onClickAddJobCallBack}
          setCandidateJobMatchingInitialAggregation={setCandidateJobMatchingInitialAggregation}
          setIsFindMathingJobClicked={setIsFindMathingJobClicked}
          isFindMathingJobClicked={isFindMathingJobClicked}
          isCandidatePublishEnabled={isCandidatePublishEnabled}
          openInNewTab={openInNewTab}
          openCandidateView={openCandidateView}
          createCandidateNote={createCandidateNote}
          currentPageCandidates={currentPageCandidates}
          candidatesNotesList={candidatesNotesList}
          setCandidateNotePopupAction={setCandidateNotePopupAction}
          candidateNoteCreateApiStatus={candidateNoteCreateApiStatus}
        />
      </div>
      {openInNewTab && (
        <SubsegmentModal
          activeTab="newTab"
          isModalVisible={subsegmentCreateModalStatus}
          setIsModalVisible={setSubsegmentModalStatus}
          createSubSegmentAction={createSubSegmentAction}
          addSubsegmentModalStatus={addSubsegmentModalStatus}
          segmentId={jobId}
          currentUser={currentUser}
          fetchSubSegments={fetchSubSegmentsAction}
          setSubsegmentPaginationData={setSubsegmentPagination}
          subsegmentPaginationData={subsegmentPaginationData}
          setAddSubsegmentModalStatus={setAddSubsegmentModalStatus}
          createSubsegmentApiStatus={createSubsegmentApiStatus}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    unsavedCriteria: getManualSearchCriteria(state, { jobId: props.jobId, criteriaType: 'Unsaved' }),
    adminFeatures: getAdminFeatures(state),
    favouriteCandidateApiStatus: getFavouriteCandidateApiStatus(state),
    removeFavouriteCandidateApiAtatus: getRemoveFavouriteCandidateApiAtatus(state),
    addSubsegmentModalStatus: getAddModalVisbilityStatus(state),
    currentUser: getCurrentUser(state),
    createSubsegmentApiStatus: getApiStatus(state, 'createSubsegmentApiStatus'),
    subsegmentPaginationData: getSubsegmentPaginationData(state),
    subsegmentCreateModalStatus: getCreateModalVisbilityStatus(state),
    selectedSubSegmentId: getSelectedSubsegmentId(state),
    jobLevelCreditsData: JobUsageBudgetDataByJobId(state, props.jobId),
  };
};

const mapDispatchToProps = {
  fetchManualSearchDraftCriteria: manualSearchActions.fetchManualSearchDraftCriteria,
  recommendFavouriteCandidate: candidateActions.recommendFavouriteCandidate,
  removeFavouriteCandidate: candidateActions.removeFavouriteCandidate,
  fetchJobSourcingStats: sourcingStatsAction.fetchJobSourcingStats,
  setSubsegmentModalStatus: setSubsegmentCreateModalStatus,
  createSubSegmentAction: createSubSegment,
  setSubsegmentPagination: setSubsegmentPaginationData,
  fetchSubSegmentsAction: fetchSubSegments,
};
export default connect(mapStateToProps, mapDispatchToProps)(Candidate360ViewWrapper);
export { Candidate360ViewWrapper as Candidate360ViewWrapperWithoutStore };
