import moment from 'moment';
import React from 'react';
import Candidate360MessageCard from '../../Candidate360MessageCard/Candidate360MessageCard';

const getMessageDeliveryStatus = ({
  DeliveryStatus: deliveryStatus = '',
  IsByPerson: isByPerson,
  RequestStatus: requestStatus,
}) => {
  if (isByPerson) return 'Received';

  if (!requestStatus) {
    return 'Pending';
  }
  if (requestStatus !== 'Success') {
    return requestStatus;
  }

  let displayDeliveryStatus = deliveryStatus;
  if (deliveryStatus.toLowerCase() === 'success') {
    if (isByPerson) displayDeliveryStatus = 'Received';
    else displayDeliveryStatus = 'Delivered';
  }
  if (deliveryStatus.toLowerCase() === 'failed') {
    displayDeliveryStatus = 'Failed';
  }
  return displayDeliveryStatus;
};

const Candidate360MessageStack = props => {
  const {
    messages,
    openComposeTextModal,
    setPhoneNumber,
    setFieldsValue,
    sendMessageApiStatus,
    personId,
    openCandidateView,
    candidate,
    version,
    openJobViewInNewTabCallBack,
  } = props;

  return (
    messages &&
    messages.map(message => {
      const sentTime = moment.utc(message.CreatedTime).local().format('hh:mm A ');
      const messageText = message.Body;
      const status = getMessageDeliveryStatus(message);
      const {
        To,
        unreadSmsCount,
        IsByPerson,
        ConversationId: conversationId,
        CreatedByName: createdByName,
        From,
        pendingState,
        MessageDeliveryStatusInformation,
        RefId,
        RequestStatus: requestStatus,
        RequestStatusReason: requestStatusReason,
      } = message;
      const data = {
        to: To,
        sentTime,
        message: messageText,
        status,
        unreadSmsCount,
        IsByPerson,
        conversationId,
        createdByName,
        from: From,
        sendMessageApiStatus,
        pendingState,
        messageDeliveryStatusInformation: MessageDeliveryStatusInformation,
        RefId,
        requestStatus,
        conversationRequestStatusReason: requestStatusReason,
      };
      return (
        <Candidate360MessageCard
          data={data}
          setFieldsValue={setFieldsValue}
          openComposeTextModal={openComposeTextModal}
          setPhoneNumber={setPhoneNumber}
          data-testid="message-card"
          key={To}
          personId={personId}
          openCandidateView={openCandidateView}
          candidate={candidate}
          version={version}
          openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
        />
      );
    })
  );
};
export default Candidate360MessageStack;
