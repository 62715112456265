import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Affix, Switch, Input, Checkbox, Row, Col, Modal, Divider, Button } from 'antd';
import { injectIntl } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroller';
import { getConnectConfig, getWhiteLabelInformation, getOrgMaskingConfig } from '../../../Reducers/ConfigReducer';
import { getFeatureToggleList } from '../../../Reducers/FeatureToggleReducer.ts';
import CallSettings from '../../../Components/CallSettings/CallSettings';
import ContactSettings from '../../../Components/ContactSettings/ContactSettings';
import AdminSMTPSettingsContainer from '../../AdminSMTPSettingsContainer/AdminSMTPSettingsContainer';
import './AdminSettings.scss';
import styles from './AdminSettings.module.scss';
import messages from './messages';
import { getApiStatus } from '../../../Reducers/ApiStatusReducer';
import * as appNotificationActions from '../../../Actions/AppNotificationActions';
import MultiLocationSettingsContainer from '../../MultiLocationSettingsContainer/MultiLocationSettingsContainer';
import { fetchUsersForDropdownLists } from '../../../Actions/UserActions';
import { getMaxCount, getUserListForShareJob, getUsersById } from '../../../Reducers/UserReducer';
import {
  fetchOrgMaskingConfig as _fetchOrgMaskingConfig,
  updateOrgMaskingConfig as _updateOrgMaskingConfig,
} from '../../../Actions/ConfigActions';
import { getCurrentUserDetails } from '../../../Reducers/UserSessionReducer';
import { clearUserList as _clearUserList } from '../../../Actions/ActionCreators/UserActionCreator';
import ScoutingAgent from './ScoutingAgent/ScoutingAgent';
import {
  getScoutingAgentEnabledUsersAction as _getScoutingAgentEnabledUsers,
  saveSourcingAgentUserList as _saveSourcingAgentUserList,
} from '../../../Actions/ScoutingAgentActions';
import { getScoutingAgentAdminViewStatus } from '../../../Reducers/ScoutingAgentReducer';
import { getVariantId } from '../../../Utils/ProductVariantDetails';
import { setNotification } from '../../../Actions/ActionCreators/ConnectActions';
import CreditInformation from './BudgetInformation/BudgetInformation';

// const { Option } = Select;
const { Search } = Input;

const mapStateToProps = state => ({
  userConnectConfig: getConnectConfig(state),
  updateConsentApiStatus: getApiStatus(state, 'updateConsentApiStatus'),
  whiteLabelInfo: getWhiteLabelInformation(state),
  featureToggleList: getFeatureToggleList(state),
  maxUsers: getMaxCount(state),
  userList: getUserListForShareJob(state),
  fetchUserDetailsApiStatus: getApiStatus(state, 'fetchUserDetailsApiStatus'),
  userById: getUsersById(state),
  currentUser: getCurrentUserDetails(state),
  fetchOrgMaskingApiStatus: getApiStatus(state, 'fetchOrgMaskingApiStatus'),
  orgMaskingConfig: getOrgMaskingConfig(state),
  fetchAvailableCreditsApiStatus: getApiStatus(state, 'fetchAvailableCreditsApiStatus'),
  scoutingAgentAdminVisibilityStatus: getScoutingAgentAdminViewStatus(state),
});

const mapDispatchToProps = {
  setFeatureLockedNotificationAlert: appNotificationActions.setFeatureLockedNotificationAlert,
  fetchUsers: fetchUsersForDropdownLists,
  fetchOrgMaskingConfig: _fetchOrgMaskingConfig,
  updateOrgMaskingConfig: _updateOrgMaskingConfig,
  clearUserList: _clearUserList,
  getScoutingAgentEnabledUsers: _getScoutingAgentEnabledUsers,
  saveSourcingAgentUserList: _saveSourcingAgentUserList,
  SetNotification: setNotification,
};

class AdminSettings extends React.Component {
  constructor(props) {
    super(props);

    this.isMaskingConfigLoaded = false;
    this.isMaskingConfigChanged = true;
    this.state = {
      isMaskingModalVisible: false,
      isOrgMaskingLevel: false,
      isOrgLevelUniversityMaskingEnabled: false,
      isOrgLevelNameMaskingEnabled: false,
      userMaskingConfig: {},
      // hoursDropDown: [0, 1],
      // minutesDropDown: [10, 20, 30, 40, 50],
    };
    this.phoneNumbersFetched = false;
    this.refsArray = [];
    this.handleScroll = this.handleScroll.bind(this);
    this.creditsRef = React.createRef();
  }

  componentDidMount() {
    const {
      fetchCallerIds,
      fetchCountries,
      fetchEmailConfig,
      fetchContactType,
      fetchContactProviders,
      fetchAvailableCredits,
      fetchUsers,
      fetchOrgMaskingConfig,
      currentUser,
    } = this.props;

    fetchCallerIds();
    fetchCountries();
    fetchAvailableCredits(getVariantId('Arya'), 'Arya');
    fetchAvailableCredits(getVariantId('SMS'), 'SMS');
    fetchEmailConfig({ isAdmin: true });
    fetchContactType();
    fetchContactProviders();
    fetchUsers({ page: 1, isActive: true, listFor: 'ShareJob' }, currentUser?.OrgId);
    fetchOrgMaskingConfig();

    this.handleScrollForNotificationIconClick();
  }

  componentDidUpdate(prevProps) {
    const { fetchOrgMaskingApiStatus, location } = this.props;
    if (prevProps.location?.key !== location?.key) {
      this.handleScrollForNotificationIconClick();
    }
    if (!this.isMaskingConfigLoaded && ['COMPLETED'].includes(fetchOrgMaskingApiStatus)) {
      this.isMaskingConfigLoaded = true;
      this.setMaskingDetails();
    }
  }

  setMaskingDetails = () => {
    const { orgMaskingConfig } = this.props;
    const { OrgMaskingOptions } = orgMaskingConfig;
    this.setState({
      isOrgMaskingLevel: OrgMaskingOptions?.IsActive,
      isOrgLevelUniversityMaskingEnabled: OrgMaskingOptions?.IsUniversityMasked,
      isOrgLevelNameMaskingEnabled: OrgMaskingOptions?.IsNameMasked,
      userMaskingConfig: orgMaskingConfig?.UserMaskingOptions,
    });
  };

  handleScrollForNotificationIconClick() {
    const { location } = this.props;
    setTimeout(() => {
      if (location.hash) {
        const element = this.creditsRef.current.id;
        const hashString = location.hash;
        const elementId = hashString.substring(1);
        if (element === elementId) {
          this.handleScroll(6);
        }
      }
    }, 150);
  }

  clearMaskingDetails = () => {
    this.isMaskingConfigChanged = true;
    this.setState({
      isOrgMaskingLevel: false,
      isOrgLevelUniversityMaskingEnabled: false,
      isOrgLevelNameMaskingEnabled: false,
      userMaskingConfig: false,
      isMaskingModalVisible: false,
    });

    this.isMaskingConfigLoaded = false;
  };

  loadUsers = page => {
    const { fetchUsers, currentUser } = this.props;
    fetchUsers({ page, isActive: true, listFor: 'ShareJob' }, currentUser.OrgId);
  };

  handeleSearchUsers = searchTerm => {
    const { fetchUsers, currentUser, clearUserList } = this.props;
    clearUserList();
    if (searchTerm) fetchUsers({ searchTerm, isActive: true, listFor: 'ShareJob' }, currentUser.OrgId);
    else fetchUsers({ page: 1, isActive: true, listFor: 'ShareJob' }, currentUser.OrgId);
  };

  handleSearchClear = event => {
    if (!event?.target?.value) {
      this.handeleSearchUsers(event?.target?.value);
    }
  };

  handleScroll(index) {
    const { refsArray } = this;
    if (window) {
      window.scrollTo({
        top: refsArray[index].offsetTop,
        behavior: 'smooth',
      });
    }
  }

  handleMaskingSubmit = () => {
    this.isMaskingConfigChanged = true;
    const { updateOrgMaskingConfig } = this.props;
    const { isOrgMaskingLevel, isOrgLevelUniversityMaskingEnabled, isOrgLevelNameMaskingEnabled, userMaskingConfig } =
      this.state;

    updateOrgMaskingConfig({
      OrgMaskingOptions: {
        IsActive: isOrgMaskingLevel,
        IsUniversityMasked: isOrgLevelUniversityMaskingEnabled,
        IsNameMasked: isOrgLevelNameMaskingEnabled,
      },
      UserMaskingOptions: userMaskingConfig,
    });
    this.setState({ isMaskingModalVisible: false });
  };

  updateUserMaskingConfig = (userId, key, event) => {
    this.isMaskingConfigChanged = false;
    const { userMaskingConfig, isOrgMaskingLevel } = this.state;

    if (isOrgMaskingLevel) return;

    const newUserMaskingConfig = _.cloneDeep(userMaskingConfig);

    let updatingUser = _.cloneDeep(userMaskingConfig[userId]);
    if (updatingUser) {
      updatingUser[key] = event?.target?.checked;
    } else {
      updatingUser = {
        [key]: event?.target?.checked,
      };
    }
    newUserMaskingConfig[userId] = updatingUser;
    this.setState({ userMaskingConfig: newUserMaskingConfig });
  };

  updateOrgMaskingConfig = (key, event) => {
    this.isMaskingConfigChanged = false;
    this.setState({
      [key]: event?.target?.checked,
    });
  };

  onConsentManagerSwitchChange = () => {
    const { setFeatureLockedNotificationAlert } = this.props;
    setFeatureLockedNotificationAlert({ type: 'ACCESS_RESTRICTED', autoClose: true });
  };

  hasMoreUSers = (userList, maxUsers) => {
    const { fetchUserDetailsApiStatus } = this.props;
    return userList && maxUsers && !['PENDING'].includes(fetchUserDetailsApiStatus) && userList.length < maxUsers;
  };

  render() {
    const {
      intl,
      utilities,
      contactSettings,
      availableCredits,
      updateCallerIds,
      updateContactType,
      bulkSubscribeContactProviders,
      requestForCredits,
      requestForCreditsApiStatus,
      updateConsentApiStatus,
      userConnectConfig,
      aryaVersion,
      whiteLabelInfo,
      featureToggleList,
      callerIds = [],
      maxUsers,
      userList,
      userById,
      currentUser,
      fetchAvailableCreditsApiStatus,
      getScoutingAgentEnabledUsers,
      saveSourcingAgentUserList,
      fetchUsers,
      scoutingAgentAdminVisibilityStatus,
      fetchUserDetailsApiStatus,
      SetNotification,
    } = this.props;

    const {
      isMaskingModalVisible,
      isOrgMaskingLevel,
      isOrgLevelUniversityMaskingEnabled,
      isOrgLevelNameMaskingEnabled,
      userMaskingConfig,
    } = this.state;

    // const { hoursDropDown, minutesDropDown } = this.state;
    const {
      AudioWrite: { IsEnabled: isAudioWriteEnabled },
      EmailWrite: { IsEnabled: isEmailWriteEnabled },
      JobUsageBudget: { IsEnabled: isJobUsageBudgetEnabled },
    } = featureToggleList;
    const { refsArray } = this;
    const countries = _.get(utilities, 'countries', false) || [];

    // const hourValue = form.getFieldValue('AutoLogoutHrs');
    /*  if (aryaVersion === 'Lite') {
      return (
        <div className="settings-container">
          <div className="settings-navigator">
            <Affix offsetTop={80}>
              <div className="navigator-group">
                <div
                  className="setting-navigation"
                  role="button"
                  tabIndex={0}
                  onClick={() => this.handleScroll(1)}
                  onKeyPress={() => this.handleScroll(1)}
                >
                  {intl.formatMessage({ ...messages.contactInfoProvider })}
                </div>
              </div>
              <div className="vertical-line" />
            </Affix>
          </div>

          <div className="current-settings-wrapper">
            <div
              className="settings-section"
              ref={input => {
                refsArray[1] = input;
              }}
            >
              <ContactSettings
                contactSettings={contactSettings}
                availableCredits={availableCredits}
                updateContactType={updateContactType}
                bulkSubscribeContactProviders={bulkSubscribeContactProviders}
                requestForCredits={requestForCredits}
                requestForCreditsApiStatus={requestForCreditsApiStatus}
                aryaVersion={aryaVersion}
              />
            </div>
          </div>
        </div>
      );
    } */
    // if (aryaVersion) {
    return (
      <div className="settings-container">
        <div className="settings-navigator">
          <Affix offsetTop={80} className="navigator-group-wrapper">
            <div className="navigator-group">
              {isAudioWriteEnabled ? (
                <div
                  className="setting-navigation"
                  role="button"
                  tabIndex={0}
                  onClick={() => this.handleScroll(0)}
                  onKeyPress={() => this.handleScroll(0)}
                >
                  {intl.formatMessage({ ...messages.callConfiguration })}
                </div>
              ) : null}

              <div
                className="setting-navigation"
                role="button"
                tabIndex={0}
                onClick={() => this.handleScroll(1)}
                onKeyPress={() => this.handleScroll(1)}
              >
                {intl.formatMessage({ ...messages.contactInfoProvider })}
              </div>
              {isEmailWriteEnabled ? (
                <div
                  className="setting-navigation"
                  role="button"
                  tabIndex={0}
                  onClick={() => this.handleScroll(2)}
                  onKeyPress={() => this.handleScroll(2)}
                >
                  {intl.formatMessage({ ...messages.emailConfiguration })}
                </div>
              ) : null}
              {isEmailWriteEnabled ? (
                <div
                  className="setting-navigation"
                  role="button"
                  tabIndex={0}
                  onClick={() => this.handleScroll(3)}
                  onKeyPress={() => this.handleScroll(3)}
                >
                  {intl.formatMessage({ ...messages.consentManager })}
                </div>
              ) : null}
              {featureToggleList.MultiLocationSourcing.IsEnabled ? (
                <div
                  className="setting-navigation"
                  role="button"
                  tabIndex={0}
                  onClick={() => this.handleScroll(4)}
                  onKeyPress={() => this.handleScroll(4)}
                >
                  {intl.formatMessage({ ...messages.multilocation })}
                </div>
              ) : null}
              {featureToggleList.Diversity.IsEnabled && currentUser?.RoleName === 'Admin' ? (
                <div
                  className="setting-navigation"
                  role="button"
                  tabIndex={0}
                  onClick={() => this.handleScroll(4)}
                  onKeyPress={() => this.handleScroll(4)}
                >
                  {intl.formatMessage({ ...messages.diversityAndInclusion })}
                </div>
              ) : null}
              {featureToggleList.ScoutingAgent.IsEnabled &&
              currentUser?.RoleName === 'Admin' &&
              scoutingAgentAdminVisibilityStatus ? (
                <div
                  className="setting-navigation"
                  role="button"
                  tabIndex={0}
                  onClick={() => this.handleScroll(5)}
                  onKeyPress={() => this.handleScroll(5)}
                >
                  {intl.formatMessage({ ...messages.scoutingAgent })}
                </div>
              ) : null}
              {/* <div
                className="setting-navigation"
                role="button"
                tabIndex={0}
                onClick={() => this.handleScroll(3)}
                onKeyPress={() => this.handleScroll(3)}
              >
                {intl.formatMessage({ ...messages.sharing })}
              </div> */}
              {/* <div
                className="setting-navigation"
                role="button"
                tabIndex={0}
                onClick={() => this.handleScroll(4)}
                onKeyPress={() => this.handleScroll(4)}
              >
                {intl.formatMessage({ ...messages.autoLogout })}
              </div> */}

              <div
                className="setting-navigation"
                role="button"
                tabIndex={0}
                onClick={() => this.handleScroll(6)}
                onKeyPress={() => this.handleScroll(6)}
              >
                {intl.formatMessage({ ...messages.creditInfo })}
              </div>
              {isJobUsageBudgetEnabled && currentUser?.RoleName === 'Admin' ? (
                <div
                  className="setting-navigation"
                  role="button"
                  tabIndex={0}
                  onClick={() => this.handleScroll(7)}
                  onKeyPress={() => this.handleScroll(7)}
                >
                  {intl.formatMessage({ ...messages.creditInformation })}
                </div>
              ) : null}
            </div>
            <div className="vertical-line" />
          </Affix>
        </div>

        <div className="current-settings-wrapper">
          {isAudioWriteEnabled ? (
            <div
              className="settings-section"
              ref={input => {
                refsArray[0] = input;
              }}
            >
              <CallSettings countries={countries} phoneNumbers={callerIds} updateCallerIds={updateCallerIds} />
            </div>
          ) : null}
          <div
            className="settings-section"
            ref={input => {
              refsArray[1] = input;
            }}
          >
            <ContactSettings
              contactSettings={contactSettings}
              availableCredits={availableCredits}
              updateContactType={updateContactType}
              bulkSubscribeContactProviders={bulkSubscribeContactProviders}
              requestForCredits={requestForCredits}
              requestForCreditsApiStatus={requestForCreditsApiStatus}
              aryaVersion={aryaVersion}
              AppName={whiteLabelInfo?.AppName ?? 'Leoforce'}
              fetchAvailableCreditsApiStatus={fetchAvailableCreditsApiStatus}
              refsArray={this.refsArray}
              creditsRef={this.creditsRef}
            />
          </div>
          {isEmailWriteEnabled ? (
            <div
              className="settings-section"
              ref={input => {
                refsArray[2] = input;
              }}
            >
              <AdminSMTPSettingsContainer />
            </div>
          ) : null}
          {isEmailWriteEnabled ? (
            <div
              className="settings-section"
              ref={input => {
                refsArray[3] = input;
              }}
            >
              <div className="consent-header">
                <div className="consent-title">{intl.formatMessage({ ...messages.consentManager })}</div>
                <div className={styles.consentManagerSettings}>
                  <div className="consent-description-text">
                    <span>{intl.formatMessage({ ...messages.defaultConsentManager })}</span>
                    <Switch
                      checked={userConnectConfig.IsEnabled}
                      onChange={this.onConsentManagerSwitchChange}
                      checkedChildren={intl.formatMessage({ ...messages.on })}
                      unCheckedChildren={intl.formatMessage({ ...messages.off })}
                    />
                  </div>
                  <div className="consent-body">
                    <span>{intl.formatMessage({ ...messages.showContactInfo })}</span>
                    <Switch
                      loading={updateConsentApiStatus === 'InProgress'}
                      checked={userConnectConfig.IsEnabled && !userConnectConfig.IsContactViewAllowed}
                      onChange={this.onConsentManagerSwitchChange}
                      checkedChildren={intl.formatMessage({ ...messages.on })}
                      unCheckedChildren={intl.formatMessage({ ...messages.off })}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {featureToggleList.MultiLocationSourcing.IsEnabled ? (
            <div
              className="settings-section"
              ref={input => {
                refsArray[4] = input;
              }}
            >
              <MultiLocationSettingsContainer />
            </div>
          ) : null}

          {/* <div
            className="sharing-settings"
            ref={input => {
              refsArray[3] = input;
            }}
          >
            <div className="sharing-header">
              <div className="sharing-title">Sharing</div>
              <div className="sharing-description-text">Allow internal recruiter job sharing</div>
            </div>
            <div className="enable-sharing">
              <div className="enable-sharing-title">Enable Job Sharing</div>
              {form.getFieldDecorator('IsJobSharingEnabled', {
                initialValue: userConfig ? userConfig.IsJobSharingEnabled : undefined,
                valuePropName: 'checked',
              })(<Switch />)}
            </div>
          </div> */}
          {/* <div
            className="auto-logout"
            ref={input => {
              refsArray[4] = input;
            }}
          >
            <div className="auto-logout-header">
              <div className="auto-logout-title">Auto Logout</div>
              <div className="auto-logout-description-text">Enable and specify session timeout duration for users</div>
            </div>
            <div className="enable-auto-logout">
              <div className="enable-auto-logout-title">Enable Auto Logout</div>
              {form.getFieldDecorator('IsAutoLogoutEnabled', {
                initialValue: _.get(userConfig, 'Logout.IsAutoLogoutEnabled', false),
                valuePropName: 'checked',
              })(<Switch />)}
            </div>
            <div className="auto-logout-time">
              Logout users after{' '}
              {form.getFieldDecorator('AutoLogoutHrs', {
                initialValue: _.get(userConfig, 'Logout.InactiveMinutes', false)
                  ? Math.floor(userConfig.Logout.InactiveMinutes / 60)
                  : undefined,
              })(
                <Select>
                  {hoursDropDown.map(hour => (
                    <Option value={hour} key={hour}>
                      {hour}
                    </Option>
                  ))}
                </Select>
              )}{' '}
              hrs{' '}
              {form.getFieldDecorator('AutoLogoutMins', {
                initialValue: _.get(userConfig, 'Logout.InactiveMinutes', false)
                  ? userConfig.Logout.InactiveMinutes % 60
                  : undefined,
              })(
                <Select onSelect={value => this.handleHourSelect(value)}>
                  {hourValue ? (
                    <Option value={0} key={0}>
                      {0}
                    </Option>
                  ) : null}
                  {minutesDropDown.map(minutes => (
                    <Option value={minutes} key={minutes}>
                      {minutes}
                    </Option>
                  ))}
                </Select>
              )}{' '}
              mins of inactivity.
            </div>
          </div> */}
          {featureToggleList.Diversity.IsEnabled && currentUser?.RoleName === 'Admin' ? (
            <div
              className="settings-section"
              ref={input => {
                refsArray[4] = input;
              }}
            >
              <div className={styles.adminDiversitySettingHeader}>
                <div className={styles.diversityAdminSettingsHeaderLabel}>
                  {intl.formatMessage({ ...messages.diversityOptions })}
                </div>
              </div>
              <div className={styles.diversityAdminSettingsHeaderText}>
                {intl.formatMessage({ ...messages.adminCanEnable })}
              </div>
              <Button
                type="primary"
                shape="round"
                onClick={() => this.setState({ isMaskingModalVisible: !isMaskingModalVisible })}
              >
                {intl.formatMessage({ ...messages.addUser })}
              </Button>
              <Modal
                title={intl.formatMessage({ ...messages.addAllOrAnyUserTitle })}
                visible={isMaskingModalVisible}
                onOk={this.handleMaskingSubmit}
                okText="Save"
                onCancel={this.clearMaskingDetails}
                width={600}
                okButtonProps={{ disabled: this.isMaskingConfigChanged }}
              >
                <div>
                  <div className="share-search-box">
                    <Search
                      placeholder="search users"
                      shape="round"
                      onSearch={this.handeleSearchUsers}
                      onChange={this.handleSearchClear}
                    />
                  </div>
                  <div style={{ maxHeight: '40vh', overflowY: 'scroll' }}>
                    <InfiniteScroll
                      loadMore={this.loadUsers}
                      pageStart={1}
                      threshold={50}
                      useWindow={false}
                      initialLoad
                      hasMore={this.hasMoreUSers(userList, maxUsers)}
                      loader={
                        <div className="loader" key={0}>
                          {intl.formatMessage({ ...messages.loading })}
                        </div>
                      }
                    >
                      <div className={styles.userList}>
                        <Row gutter={20}>
                          <div className={styles.maskingUserListItem}>
                            <Col span={10}>
                              <Checkbox
                                checked={isOrgMaskingLevel}
                                onChange={event => this.updateOrgMaskingConfig('isOrgMaskingLevel', event)}
                              >
                                {intl.formatMessage({ ...messages.forAllUsers })}
                              </Checkbox>
                            </Col>
                            <Col span={8}>
                              <Checkbox
                                checked={isOrgMaskingLevel && isOrgLevelUniversityMaskingEnabled}
                                onChange={event =>
                                  this.updateOrgMaskingConfig('isOrgLevelUniversityMaskingEnabled', event)
                                }
                              >
                                {intl.formatMessage({ ...messages.universityName })}
                              </Checkbox>
                            </Col>
                            <Col span={5}>
                              <Checkbox
                                checked={isOrgMaskingLevel && isOrgLevelNameMaskingEnabled}
                                onChange={event => this.updateOrgMaskingConfig('isOrgLevelNameMaskingEnabled', event)}
                              >
                                {intl.formatMessage({ ...messages.name })}
                              </Checkbox>
                            </Col>
                          </div>
                        </Row>
                        <Divider />
                        {_.uniq(userList)?.map(val => (
                          <Row gutter={20} key={val}>
                            <div className={styles.maskingUserListItem}>
                              <Col span={10}>
                                <div>
                                  <Checkbox
                                    checked={isOrgMaskingLevel || userMaskingConfig[val]?.IsActive}
                                    onChange={event => this.updateUserMaskingConfig(val, 'IsActive', event)}
                                  />{' '}
                                  {userById[val]?.FullName}
                                </div>
                              </Col>
                              <Col span={8}>
                                <Checkbox
                                  checked={
                                    (isOrgMaskingLevel && isOrgLevelUniversityMaskingEnabled) ||
                                    (userMaskingConfig[val]?.IsActive && userMaskingConfig[val]?.IsUniversityMasked)
                                  }
                                  onChange={event => this.updateUserMaskingConfig(val, 'IsUniversityMasked', event)}
                                >
                                  {intl.formatMessage({ ...messages.universityName })}
                                </Checkbox>
                              </Col>
                              <Col span={5}>
                                <Checkbox
                                  checked={
                                    (isOrgMaskingLevel && isOrgLevelNameMaskingEnabled) ||
                                    (userMaskingConfig[val]?.IsActive && userMaskingConfig[val]?.IsNameMasked)
                                  }
                                  onChange={event => this.updateUserMaskingConfig(val, 'IsNameMasked', event)}
                                >
                                  {intl.formatMessage({ ...messages.name })}
                                </Checkbox>
                              </Col>
                            </div>
                          </Row>
                        ))}
                      </div>
                    </InfiniteScroll>
                  </div>
                </div>
              </Modal>
            </div>
          ) : null}
          {featureToggleList.ScoutingAgent.IsEnabled &&
          currentUser?.RoleName === 'Admin' &&
          scoutingAgentAdminVisibilityStatus ? (
            <div
              className="settings-section"
              ref={input => {
                refsArray[5] = input;
              }}
            >
              <ScoutingAgent
                userList={userList}
                currentUser={currentUser}
                userById={userById}
                getScoutingAgentEnabledUsers={getScoutingAgentEnabledUsers}
                saveSourcingAgentUserList={saveSourcingAgentUserList}
                fetchUsers={fetchUsers}
                maxUsers={maxUsers}
                fetchUserDetailsApiStatus={fetchUserDetailsApiStatus}
                SetNotification={SetNotification}
              />
            </div>
          ) : null}
          {isJobUsageBudgetEnabled && currentUser?.RoleName === 'Admin' ? (
            <div
              className="settings-section"
              ref={input => {
                refsArray[7] = input;
              }}
            >
              <CreditInformation />
            </div>
          ) : null}
        </div>
      </div>
    );
    // }
    //  return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AdminSettings));

export { AdminSettings as AdminSettingsWithoutStore };
